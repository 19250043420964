import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import DashboardCard from "../../../Components/Card/DashboardCard";
import ExceptionReportingChart from "../../../Components/Charts/ExceptionReportingChart";
import ExceptionReportingChartPerSource from "../../../Components/Charts/ExceptionReportingChartPerSource";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import PageHeader from "../../../Components/Text/PageHeader";
import VerifiedIcon from "@mui/icons-material/Verified";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GridOnIcon from "@mui/icons-material/GridOn";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import ExceptionPerCountry from "./ExceptionPerCountry/ExceptionPerCountry";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import Utils from "../../../Common/Utils";
import { ExceptionBySourceResponse } from "../../../Models/DashboardModel";
import DashboardService from "../../../Services/DashboardService";
import MissingTransactionService from "../../../Services/MissingTransactionServices";
import NoRecords from "../../../Components/NoRecords/NoRecords";

const MissingTransactionServices = new MissingTransactionService();
const DashboardServices = new DashboardService();
const chartCardColor = ["#45BA7F", "#8B83FB", "#4791FF"];
const dashboardCardIcon = [
  <VerifiedIcon />,
  <CurrencyExchangeIcon />,
  <SignalCellularAltOutlinedIcon />,
  <GridOnIcon />,
  <GridOnIcon />,
];

interface Props {}

interface State {
  showExceptioReportingDateDialog: boolean;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isLoading: boolean;
  isLoadingCard: boolean;
  isSearchButtonLoading: boolean;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  startDatePicker: string | null;
  endDatePicker: string | null;
  chartData: any;
  xAxisCategories: string[];
  resultSourceArr: any;
  maxExceptionNumber: number;
  exceptionBySource: ExceptionBySourceResponse[];
  percentSource: {
    source: string;
    endDate: string | null | undefined;
    percent: number;
    icon: JSX.Element;
    color: string;
  }[];
}

class ExceptiponChart extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showExceptioReportingDateDialog: false,
      selectedStartDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isLoading: false,
      isLoadingCard: false,
      isSearchButtonLoading: false,
      selectedSourceValues: [],
      sourceMappingData: [],
      startDatePicker: null,
      endDatePicker: null,
      chartData: [],
      xAxisCategories: [],
      resultSourceArr: [],
      exceptionBySource: [],
      maxExceptionNumber: 0,
      percentSource: [],
    };
  }
  async componentDidMount() {
    const response = await MissingTransactionServices.getSourceMappingData();
    const dataArr = response.data.data.map((el) => {
      return {
        ...el,
        searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
      };
    });

    let sourceData = dataArr
      .map((el) => {
        return {
          text: el.sourceName.toUpperCase(),
          value: el.sourceName.toUpperCase(),
        };
      })
      .filter((val) => val.text !== "Other");
    this.setState(
      {
        isLoadingCard: true,
        sourceMappingData: sourceData.sort((a, b) =>
          a.text.localeCompare(b.text)
        ),
        selectedSourceValues: this.state.showExceptioReportingDateDialog
          ? [
              { text: "CONCUR", value: "CONCUR" },
              { text: "CPS", value: "CPS" },
              { text: "SAP", value: "SAP" },
            ]
          : [],
      },
      () => {
        this.getLastLoadData();
      }
    );
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (
      prevState.showExceptioReportingDateDialog !==
      this.state.showExceptioReportingDateDialog
    ) {
      if (this.state.showExceptioReportingDateDialog === true) {
        this.getDefaultData();
      }
    }
  }
  getDefaultData = () => {
    this.setState(
      {
        selectedSourceValues: this.state.showExceptioReportingDateDialog
          ? [
              { text: "CONCUR", value: "CONCUR" },
              { text: "CPS", value: "CPS" },
              { text: "SAP", value: "SAP" },
            ]
          : [],
        selectedStartDate: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
        startDatePicker: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        endDatePicker: moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.getLastLoadData();
      }
    );
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 180 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 180",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 3) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected }, () => {});
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 3 sources",
        alertType: "error",
      });
    }
  };
  getLastLoadData = async () => {
    const {
      selectedSourceValues,
      selectedEndDate,
      selectedStartDate,
      showExceptioReportingDateDialog,
    } = this.state;

    try {
      let sourceVal = selectedSourceValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let payloadObj: any = {};
      if (showExceptioReportingDateDialog) {
        const payloadObjA = {
          par_group_source: 0,
          par_start_date: selectedStartDate,
          par_end_date: selectedEndDate,
          par_whereclause: `source in (${sourceVal}) and CAST(exception_datetime as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}'' `,
          par_whereclause1: null,
        };
        const payloadObjB = {
          par_group_source: 0,
          par_start_date: moment(selectedEndDate)
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          par_end_date: moment(selectedEndDate)
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          par_whereclause: `source in (${sourceVal}) and CAST(exception_datetime as DATE) BETWEEN ''(${moment(
            selectedEndDate
          )
            .subtract(1, "month")
            .format("YYYY-MM-DD")})'' and ''${moment(selectedEndDate)
            .subtract(1, "month")
            .add(1, "day")
            .format("YYYY-MM-DD")}'' `,
          par_whereclause1: null,
        };
        this.setState({ isLoading: true });
        const [responseSource, percentReponse] = await Promise.all([
          DashboardServices.getExceptionData(payloadObjA),
          DashboardServices.getExceptionData(payloadObjB),
        ]);
        const responseData = responseSource?.result;

        const percentData = selectedSourceValues.map((el, ind) => {
          const previousSourceData = percentReponse.result.find((val) => {
            return val.source === el.value;
          });
          const filterData = responseData.filter((val) => {
            return (
              val.source === el.value && val.exception_date === selectedEndDate
            );
          });
          const currentSourceCount = filterData.length
            ? filterData.reduce(
                (prev, curr) => prev + Number(curr.totalcount || 0),
                0
              )
            : 0;
          let percent = 0;

          if (
            Number(previousSourceData?.totalcount || 0) === 0 &&
            currentSourceCount === 0
          ) {
            percent = 0;
          } else if (Number(previousSourceData?.totalcount || 0) === 0) {
            percent = 100;
          } else {
            percent =
              ((currentSourceCount - Number(previousSourceData?.totalcount)) /
                Number(previousSourceData?.totalcount)) *
              100;
          }
          return {
            source: el.value,
            endDate: selectedEndDate,
            percent,
            icon: dashboardCardIcon[ind],
            color: chartCardColor[ind],
          };
        });
        percentData.sort((a, b) => a.source.localeCompare(b.source));
        this.setState({
          isLoading: false,
          isLoadingCard: false,
          isSearchButtonLoading: false,
          exceptionBySource: responseData,
          percentSource: percentData,
        });
      } else {
        payloadObj = {
          par_group_source: 1,
          par_start_date: "2023-12-01",
          par_end_date: "2023-12-30",
          par_whereclause:
            "Country in (''CO'',''MN'') and CAST(exception_datetime as DATE) BETWEEN ''2023-12-01'' and ''2023-12-30'' ",
        };
        this.setState({ isLoading: true, isLoadingCard: false });
        const responseExceptionDashboard =
          await DashboardServices.getCountryExceptionData(payloadObj);

        const sliceResult = responseExceptionDashboard.result.slice(0, 5);
        const maxObject = sliceResult.reduce((maxObj: any, currentObj: any) => {
          return Number(currentObj.totalcount) > Number(maxObj.totalcount)
            ? currentObj
            : maxObj;
        }, sliceResult[0]);

        const dataArr = Object.values(sliceResult).map(
          (el: any, index: number) => {
            return {
              name: el.country,
              y: parseInt(el.totalcount),
            };
          }
        );
        this.setState({
          chartData: dataArr,
          xAxisCategories: Object.values(responseExceptionDashboard.result).map(
            (elem: any) => elem.country
          ),
          resultSourceArr: Object.values(responseExceptionDashboard.result),
          maxExceptionNumber: Number(maxObject.totalcount),
          isLoading: false,
          isLoadingCard: false,
        });
      }
    } catch (error) {
      console.log("unexpected error during fecthing:", error);
      this.setState({
        isLoading: false,
        isLoadingCard: false,
        isSearchButtonLoading: false,
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 179);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };

  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      startDatePicker: null,
      endDatePicker: null,
    });
  };
  isEmptyCheck = () => {
    const { selectedSourceValues, startDatePicker, endDatePicker } = this.state;
    if (startDatePicker && endDatePicker && selectedSourceValues.length > 0) {
      return false;
    }
    return true;
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
        selectedStartDate: this.state.startDatePicker,
        selectedEndDate: this.state.endDatePicker,
      },
      () => {
        this.getLastLoadData();
      }
    );
  };
  render() {
    const {
      showExceptioReportingDateDialog,
      selectedStartDate,
      selectedEndDate,
      alertMsg,
      alertType,
      openAlert,
      isLoading,
      isLoadingCard,
      sourceMappingData,
      selectedSourceValues,
      startDatePicker,
      endDatePicker,
      isSearchButtonLoading,
      chartData,
      percentSource,
      xAxisCategories,
      maxExceptionNumber,
      exceptionBySource,
    } = this.state;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PageHeader
            label="Exception Reporting"
            style={{
              fontSize: "16px",
              lineHeight: "1",
            }}
          />
          <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
            <img
              alt="ExpandOpen"
              src={ExpandOpen}
              width={"14px"}
              height={"14px"}
              style={{
                cursor: "pointer",
                zIndex: "9999",
              }}
              onClick={() => {
                this.setState({ showExceptioReportingDateDialog: true });
              }}
            />
          </Box>
        </Box>
        <Typography
          style={{
            color: "#5A607F",
            fontSize: "12px",
            paddingTop: "15px",
          }}
        >
          Top Common Errors
        </Typography>

        {isLoadingCard ? (
          <Grid
            item
            style={{
              height: "8rem",
              marginLeft: "auto",
              marginRight: "auto",
              margin: "10vh",
            }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress
              disableShrink
              sx={{ color: "#d52b1e", marginTop: 4 }}
            />
          </Grid>
        ) : (
          <>
            {chartData.length > 0 ? (
              <>
                <ExceptionReportingChart
                  chartDataArr={chartData}
                  maxExceptionNumber={maxExceptionNumber}
                  xAxisCategories={xAxisCategories}
                />
                <Link
                  style={{
                    color: "#0075A2",
                    textDecoration: "none",
                    fontWeight: 600,
                    float: "right",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ showExceptioReportingDateDialog: true });
                  }}
                >
                  View Details{" "}
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: "20px",

                      verticalAlign: "middle",
                    }}
                  />
                </Link>
              </>
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8em",
                }}
              >
                <NoRecords msg="No Data" />
              </Box>
            )}
          </>
        )}

        <ModalDialog
          isOpen={showExceptioReportingDateDialog}
          blackTitleColor
          title="Exception Reporting"
          onClose={() =>
            this.setState({ showExceptioReportingDateDialog: false })
          }
          dialogWidth="xl"
          scrollBar={showExceptioReportingDateDialog}
        >
          <div className="AlertException">
            <SnackbarAlert
              alertType={alertType}
              open={openAlert}
              message={alertMsg}
              onClose={() => {
                this.setState({ openAlert: false });
              }}
            />
          </div>

          <Grid container>
            <Grid item xs={3}>
              <Grid
                container
                flexDirection={"column"}
                display="flex"
                alignItems="center"
                style={{
                  position: "relative",
                  maxHeight: "515px",
                  overflowY: "auto",
                }}
              >
                {isLoading ? (
                  ""
                ) : (
                  <Grid item mb={1.5}>
                    {percentSource.map((el) => {
                      return (
                        <Grid item mb={1} key={el.source}>
                          <DashboardCard
                            iconChart={el.icon}
                            headingText={el.source}
                            graphNumbers={
                              el.percent === 0 ? "No Change" : el.endDate
                            }
                            pricePercentText={el.percent}
                            icon={
                              el.percent === 0 ? (
                                <></>
                              ) : el.percent > 0 ? (
                                <NorthIcon
                                  style={{
                                    top: "2px",
                                    position: "relative",
                                    fontSize: "15px",
                                    color: "green",
                                  }}
                                />
                              ) : (
                                <SouthIcon
                                  style={{
                                    top: "2px",
                                    position: "relative",
                                    fontSize: "15px",
                                    color: "red",
                                  }}
                                />
                              )
                            }
                            color={el.color}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0 5px 25px 5px",
                }}
              >
                {" "}
                <Grid container columnGap={1}>
                  <Grid item xs={5}>
                    <InputLabel>Select Sources</InputLabel>
                    <MultiAutoComplete
                      id="sourceName"
                      label="Select Sources"
                      selected={selectedSourceValues}
                      values={sourceMappingData}
                      onChange={this.handleMultiSelectChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} ml={0.8}>
                    <InputLabel> Select Start Date</InputLabel>
                    <DatePicker
                      name="startDate"
                      placeHolder="Select Date"
                      maxDate={
                        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                      }
                      value={startDatePicker}
                      onChange={this.handleStartDateChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} className="EndDate" ml={0.8}>
                    <InputLabel> Select End Date</InputLabel>
                    <DatePicker
                      disabled={!startDatePicker}
                      name="endDate"
                      placeHolder="Select Date"
                      minDate={new Date(startDatePicker!)}
                      maxDate={this.calculateMaxDate(startDatePicker)}
                      value={endDatePicker}
                      onChange={this.handleEndDateChange}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={0.8}>
                    <GradientButton
                      disabled={this.isEmptyCheck()}
                      isButtonLoad={isSearchButtonLoading}
                      label="Search"
                      onClick={this.handleSearchClick}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={1}>
                    <RedButton label="Reset" onClick={this.onResetClick} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                {isLoading ? (
                  <Grid
                    item
                    style={{ height: "20em", width: "50em" }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress
                      disableShrink
                      sx={{ color: "#d52b1e", marginTop: 8 }}
                    />
                  </Grid>
                ) : (
                  <>
                    {exceptionBySource.length > 0 &&
                    showExceptioReportingDateDialog ? (
                      <ExceptionReportingChartPerSource
                        chartDataSourceArr={exceptionBySource}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                      />
                    ) : (
                      <Box
                        style={{
                          position: "sticky",
                          left: "50%",
                          marginTop: "25vh",
                          marginBottom: "25vh",
                        }}
                        width={"82px"}
                      >
                        <NoRecords msg="No Data" />
                      </Box>
                    )}
                  </>
                )}
              </Grid>

              <Grid>
                <ExceptionPerCountry
                  showExceptioReportingDateDialog={
                    showExceptioReportingDateDialog
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </ModalDialog>
      </>
    );
  }
}
export default ExceptiponChart;
