import AddIcon from "@mui/icons-material/Add";
import { AlertColor, Box, Grid, InputLabel } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../App.css";
import Utils from "../../Common/Utils";
import GradientButton from "../../Components/Button/GradientButton";
import RedButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import DatePicker from "../../Components/DatePicker/DatePicker";
import SearchFilter from "../../Components/Search/SearchFilter";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import AssignmentDataLoader from "../../Components/Table/AssignmentDataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import { CreateTicketModel } from "../../Models/CreateTicketModel";
import {
  NotificationAssignmentPageModel,
  NotificationWidgetCountModel,
  NotificationWidgetModifyModel,
} from "../../Models/NotificationWidgetModel";
import AuthProviderService from "../../Services/AuthProviderService";
import NotificationService from "../../Services/NotificationWidgetService";
import CreateNewTicket from "./CreateNewTicket";
const notificationServices = new NotificationService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  newTicket: CreateTicketModel;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isTableLoading: boolean;
  tblData: any[];
  totalRecordsCount: number | undefined;
  searchFilterData: any;
  filterData: any;
  rows: number;
  page: number;
  isNewTicket: boolean;
  userName: string;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isButtonLoading: boolean;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  isSearchButtonLoading: boolean;
  selectedUser: string;
  selectedFilterStatus: string;
  sortChange: string;
  searchText: string;
  isEmptyReset: boolean;
  usersData: SelectModel[];
  selectedValues: SelectModel[];
  filterString: any;
  isReload: boolean;
  notificationData: any;
  isSearchClick: boolean;
}

class Assignments extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isPaginationDisabled: false,
      isPaginationReset: false,
      isTableLoading: false,
      tblData: [],
      totalRecordsCount: undefined,
      searchFilterData: [],
      filterData: [],
      rows: 5,
      page: 1,
      isNewTicket: false,
      newTicket: {} as CreateTicketModel,
      userName: "",
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isButtonLoading: false,
      selectedStartDate: null,
      selectedEndDate: null,
      isSearchButtonLoading: false,
      sortChange: "",
      selectedUser: "",
      selectedFilterStatus: "",
      searchText: "",
      isEmptyReset: false,
      usersData: [],
      selectedValues: [],
      filterString: "",
      isReload: false,
      notificationData: [],
      isSearchClick: false,
    };
  }
  async componentDidMount() {
    try {
      this.setState({ isTableLoading: true });
      const getAllUsers = await notificationServices.getAllUsersData();

      const usersData = getAllUsers.data.data.map((item: any) => {
        return {
          id: item.id,
          text: item.userName,
          value: item.userName,
          personaType: item.persona,
        };
      });
      const filteredUserData = usersData.filter((ele: any, index: any) => {
        return (
          index ===
          usersData.findIndex((val: any) => {
            return val.text === ele.text;
          })
        );
      });
      this.setState({ usersData: filteredUserData });
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userName = JSON.parse(decodedToken).name;

      this.setState({ userName, isTableLoading: false }, () => {
        this.fetchAssignmentData(5, 1);
      });
    } catch (error) {
      console.log(error);
    }
  }
  fetchAssignmentData = async (rows: number, page: number) => {
    try {
      this.setState({ isTableLoading: true });
      const assignmentObj: NotificationAssignmentPageModel = {
        notification_report_payload: [
          {
            whereclause: "",
            pageno: page,
            rows_per_page: rows,
            sort_order: "desc",
          },
        ],
      };
      const response =
        await notificationServices.getNotificationWidgetAssignment(
          assignmentObj
        );
      if (response.total_count > 0) {
        const tableData = JSON.parse(response.result_set).map((item: any) => {
          return {
            id: item.var_notification_id,
            title: item.title,
            assignedTo: item.var_assigned_to,
            createdBy: item.created_by,
            updatedBy: item.var_updated_by,
            "Created Date & Time": Utils.getFormattedDateTime(
              item.created_datetime
            ),
            comments: item.var_comments,
            status: item.var_status,
          };
        });

        this.setState({
          notificationData: JSON.parse(response.result_set),
          tblData: tableData,
          totalRecordsCount: response.total_count,
          isTableLoading: false,
          filterString: "",
          isReload: true,
        });
      } else {
        this.setState({
          tblData: [],
          totalRecordsCount: 0,
          isTableLoading: false,
          isPaginationReset: true,
          isEmptyReset: !this.state.isEmptyReset,
          page: 1,
          rows: 5,
          isButtonLoading: false,
          isSearchButtonLoading: false,
          filterString: "",
          isReload: false,
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
        isTableLoading: false,
        isButtonLoading: false,
        isSearchButtonLoading: false,
      });
    }
  };
  handleSelectChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        ...this.state,
        [targetId]: selected.value,
      });
    }
  };

  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate).getDate();
      const startDateMonth = new Date(this.state.selectedStartDate).getMonth();
      const startDateYear = new Date(
        this.state.selectedStartDate
      ).getFullYear();
      const endDate = new Date(value!).getDate();
      const endDateMonth = new Date(value!).getMonth();
      const endDateYear = new Date(value!).getFullYear();
      if (
        startDate > endDate &&
        startDateMonth >= endDateMonth &&
        startDateYear >= endDateYear
      ) {
        this.setState({
          openAlert: true,
          alertMsg: "End date should be greater than start date",
          alertType: "error",
        });
        return;
      }

      this.setState({
        selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
      });
    }
  };
  onChangePage = (page: number) => {
    this.setState({ page }, () => {
      !this.state.isSearchClick
        ? this.fetchAssignmentData(this.state.rows, page)
        : this.onSearchClick();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row, page: 1 }, () => {
      this.setState({ isEmptyReset: !this.state.isEmptyReset });
      !this.state.isSearchClick
        ? this.fetchAssignmentData(
            row,
            this.state.page > 1 ? 1 : this.state.page
          )
        : this.onSearchClick();
    });
  };
  onClickButton = (action: "createnewticket") => {
    switch (action) {
      case "createnewticket":
        this.setState({ isNewTicket: true });
        break;
    }
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchText = event.target.value;

    this.setState({
      searchText,
    });
  };

  handleCreateNewTicket = () => {
    this.setState({
      isNewTicket: false,
      newTicket: {} as CreateTicketModel,
      selectedValues: [],
    });
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newTicket: {
        ...this.state.newTicket,
        [event.target.name]: event.target.value,
      },
    });
  };
  getNotificationCount = async () => {
    try {
      const obj: NotificationWidgetCountModel = {
        par_assigned_to: this.state.userName,
      };
      const response = await notificationServices.getNotificationsCount(obj);

      sessionStorage.setItem("unreadCount", response.Unread_Notification_Count);
    } catch (error) {
      console.log(error);
    }
  };
  onSubmit = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const { newTicket, userName, selectedValues } = this.state;
      const createTicketPayload: NotificationWidgetModifyModel = {
        notification_payload: [
          {
            title: newTicket.title,
            comments: newTicket.comments,
            assigned_to: selectedValues.map((el) => el.text).join(","),
            created_by: userName,
            created_datetime: Utils.getCurrentUTCDateTimeMonth(),
            notification_id: 0,
            notification_status: "new",
            updated_by: userName,
          },
        ],
      };
      const response = await notificationServices.notificationWidgetModify(
        createTicketPayload
      );
      if (response.result === "Success") {
        this.setState(
          {
            isButtonLoading: false,
            newTicket: {} as CreateTicketModel,
            selectedValues: [],
            openAlert: true,
            alertMsg: "Ticket created successfully",
            alertType: "success",
            isPaginationReset: true,
            isEmptyReset: true,
            page: 1,
            rows: 5,
          },
          () => {
            this.getNotificationCount();
            this.fetchAssignmentData(5, 1);
          }
        );
      } else {
        this.setState({
          isButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onResetClick = () => {
    if (this.state.filterString.length > 0 || this.state.isReload) {
      this.setState(
        {
          selectedUser: "",
          selectedFilterStatus: "",
          searchText: "",
          sortChange: "",
          selectedEndDate: null,
          selectedStartDate: null,
          isPaginationReset: !this.state.isPaginationReset,
          page: 1,
          rows: 5,
          isButtonLoading: false,
          isSearchButtonLoading: false,
          isSearchClick: false,
        },
        () => {
          this.fetchAssignmentData(5, 1);
        }
      );
    } else {
      this.setState({
        selectedUser: "",
        selectedFilterStatus: "",
        searchText: "",
        sortChange: "",
        selectedEndDate: null,
        selectedStartDate: null,
        isPaginationReset: !this.state.isPaginationReset,
        page: 1,
        rows: 5,
        isButtonLoading: false,
        isSearchButtonLoading: false,
        isSearchClick: false,
      });
    }
  };
  onSearchClick = async () => {
    try {
      const filterString = this.createFilterString();
      this.setState(
        {
          isSearchClick: true,
          isTableLoading: true,

          isPaginationReset: true,
          filterString,

          // isEmptyReset: !this.state.isEmptyReset,
        },
        async () => {
          const assignmentObj: NotificationAssignmentPageModel = {
            notification_report_payload: [
              {
                whereclause: filterString,
                pageno: this.state.page,
                rows_per_page: this.state.rows,
                sort_order:
                  this.state.sortChange === "Newest"
                    ? "desc"
                    : this.state.sortChange === "Oldest"
                    ? "asc"
                    : "desc",
              },
            ],
          };
          const response =
            await notificationServices.getNotificationWidgetAssignment(
              assignmentObj
            );
          if (response.total_count > 0) {
            const tableData = JSON.parse(response.result_set).map(
              (item: any) => {
                return {
                  id: item.var_notification_id,
                  title: item.title,
                  assignedTo: item.var_assigned_to,
                  createdBy: item.created_by,
                  updatedBy: item.var_updated_by,
                  "Created Date & Time": Utils.getFormattedDateTime(
                    item.created_datetime
                  ),
                  comments: item.var_comments,
                  status: item.var_status,
                };
              }
            );

            this.setState({
              notificationData: JSON.parse(response.result_set),
              tblData: tableData,
              totalRecordsCount: response.total_count,
              isTableLoading: false,
              isSearchButtonLoading: false,
              isReload: true,
            });
          } else {
            this.setState({
              tblData: [],
              totalRecordsCount: response.total_count,
              isTableLoading: false,
              isSearchButtonLoading: false,
              isPaginationReset: true,
              isEmptyReset: !this.state.isEmptyReset,
              page: 1,
              rows: 5,
            });
          }
        }
      );
    } catch (error) {
      this.setState({
        isTableLoading: false,
        isSearchButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  createFilterString = () => {
    const {
      selectedUser,
      selectedFilterStatus,
      searchText,
      selectedStartDate,
      selectedEndDate,
    } = this.state;

    const filterConditions = [];

    if (selectedUser) {
      filterConditions.push(
        `persona=''''${selectedUser.toUpperCase().replace(" ", "_")}''''`
      );
    }

    if (selectedFilterStatus) {
      filterConditions.push(
        `lower(st.notification_status)=lower(''''${selectedFilterStatus.toLowerCase()}'''')`
      );
    }

    if (searchText) {
      if (Number(searchText)) {
        filterConditions.push(`notification_id = ${searchText}`);
      } else {
        filterConditions.push(
          `lower(title) like lower(''''%${searchText}%'''')`
        );
      }
    }

    if (selectedStartDate) {
      const startDateCondition = `created_datetime between Cast (''''${selectedStartDate}'''' as timestamp) and`;
      const endDateCondition = selectedEndDate
        ? `Cast(''''${selectedEndDate}'''' as timestamp)`
        : `Cast(''''9999-12-31'''' as timestamp)`;
      filterConditions.push(`${startDateCondition} ${endDateCondition}`);
    }

    const nonEmptyConditions = filterConditions.filter(
      (condition) => condition.trim() !== ""
    );

    if (nonEmptyConditions.length > 0) {
      return nonEmptyConditions.join(" and ");
    }

    return "";
  };
  isEmptyCheck = () => {
    const {
      selectedUser,
      selectedFilterStatus,
      searchText,
      selectedStartDate,
      selectedEndDate,
      sortChange,
    } = this.state;
    if (
      sortChange ||
      selectedUser ||
      selectedFilterStatus ||
      searchText ||
      selectedStartDate ||
      selectedEndDate
    ) {
      return false;
    }
    return true;
  };
  handleSearchClick = () => {
    this.setState(
      {
        rows: 5,
        page: 1,
        isPaginationReset: !this.state.isPaginationReset,
        isSearchButtonLoading: true,
      },
      () => {
        this.onSearchClick();
      }
    );
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId) {
      this.setState({ selectedValues: selected });
    }
  };
  render() {
    const {
      isPaginationDisabled,
      isPaginationReset,
      isTableLoading,
      totalRecordsCount,
      isNewTicket,
      newTicket,
      openAlert,
      alertMsg,
      alertType,
      isButtonLoading,
      selectedStartDate,
      selectedEndDate,
      isSearchButtonLoading,
      selectedFilterStatus,
      selectedUser,
      searchText,
      sortChange,
      isEmptyReset,
      usersData,
      selectedValues,
      tblData,
      notificationData,
    } = this.state;

    return (
      <Box pb={4}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        {isNewTicket ? (
          <CreateNewTicket
            handleMultiSelectChange={this.handleMultiSelectChange}
            selectedValues={selectedValues}
            usersData={usersData}
            isButtonLoading={isButtonLoading}
            onSubmit={this.onSubmit}
            onInputChange={this.onInputChange}
            newTicket={newTicket}
            handleCreateNewTicket={this.handleCreateNewTicket}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "25px 10px",
            }}
          >
            <PageHeader
              label="Assignments"
              style={{
                fontSize: "22px",
              }}
            />
            <Box style={{ display: "flex", gap: "1em" }}>
              <GradientButton
                id="button"
                label="Create New Ticket"
                onClick={() => {
                  this.onClickButton("createnewticket");
                }}
                startIcon={<AddIcon />}
              />
            </Box>
          </Box>
        )}

        <CardContainer>
          <Grid container p={2} flexDirection="row">
            <Grid container item columnSpacing={2}>
              <Grid
                item
                xs={2.6}
                className="SearchNameInput NotificationSearch"
              >
                <InputLabel>Search by Title or Notification ID</InputLabel>
                <SearchFilter
                  placeholder="Search by Title or Notification ID"
                  value={searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
              <Grid item xs={1.5}>
                <InputLabel> Select by User</InputLabel>
                <SingleSelect
                  id="selectedUser"
                  customOptions="None"
                  //defaultValue="None"
                  value={selectedUser}
                  values={[
                    { text: "Data Analyst", value: "Data Analyst" },
                    { text: "Publication Lead", value: "Publication Lead" },
                    { text: "GTITAdmin Support", value: "GTITAdmin Support" },
                    {
                      text: "Management Leadership",
                      value: "Management Leadership",
                    },
                    { text: "Super User", value: "Super User" },
                    { text: "Natc", value: "Natc" },
                  ]}
                  onChange={this.handleSelectChange}
                />
              </Grid>
              <Grid item xs={1.5}>
                <InputLabel> Filter By</InputLabel>
                <SingleSelect
                  id="selectedFilterStatus"
                  customOptions="None"
                  defaultValue="None"
                  value={selectedFilterStatus}
                  values={[
                    { text: "Open", value: "Open" },
                    { text: "In Progress", value: "In Progress" },
                    { text: "Review Requested", value: "Review Requested" },
                    { text: "Review Completed", value: "Review Completed" },
                    { text: "Rejected", value: "Rejected" },
                    { text: "Closed", value: "Closed" },
                    { text: "On Hold", value: "On Hold" },
                  ]}
                  onChange={this.handleSelectChange}
                />
              </Grid>

              <Grid item xs={1.5}>
                <InputLabel> Sort By</InputLabel>
                <SingleSelect
                  customOptions="None"
                  id="sortChange"
                  value={sortChange}
                  values={[
                    { text: "Newest", value: "Newest" },
                    { text: "Oldest", value: "Oldest" },
                  ]}
                  onChange={this.handleSelectChange}
                />
              </Grid>
              <Grid item ml={0.5} xs={1.5}>
                <InputLabel> From Date</InputLabel>
                <DatePicker
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={1.5} ml={0.5} className="EndDate">
                <InputLabel> To Date</InputLabel>
                <DatePicker
                  disabled={!selectedStartDate}
                  name="endDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid item xs={0.8} mt={3} ml={0.8}>
                <GradientButton
                  disabled={this.isEmptyCheck()}
                  isButtonLoad={isSearchButtonLoading}
                  label="Search"
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={0.8} mt={3}>
                <RedButton label="Reset" onClick={this.onResetClick} />
              </Grid>
            </Grid>
          </Grid>
          <hr
            style={{
              border: "0.5px solid #D8D8D8",
            }}
          />
          <AssignmentDataLoader
            notificationData={notificationData}
            isEmptyReset={isEmptyReset}
            isPaginationReset={isPaginationReset}
            isPaginationDisabled={isPaginationDisabled}
            isLoading={isTableLoading}
            tableData={tblData}
            totalRecordsCount={totalRecordsCount}
            onChangeRow={this.onChangeRow}
            onChangePage={this.onChangePage}
          />
        </CardContainer>
      </Box>
    );
  }
}
export default withRouter(Assignments);
