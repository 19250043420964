import React from "react";
import { Button, ButtonGroup } from "@mui/material";

interface Props {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  selectedButton?: boolean;
  buttons: any;
}
interface State {}

class ButtonGroupComponent extends React.Component<Props, State> {
  render() {
    const { label, onClick, buttons, disabled, selectedButton } = this.props;
    const style: React.CSSProperties = { textTransform: "none" };
    if (selectedButton) {
      style.backgroundImage = "linear-gradient(to right, #D52B1E,#78105B)";

      style.color = "#FFFFFF";
    }
    if (!selectedButton) {
      style.color = "#131523";
      style.background = "#ffffff";
    }
    return (
      <ButtonGroup variant="contained" aria-label="button group">
        {" "}
        {buttons.map((button: any) => (
          <Button
            key={button.id}
            onClick={onClick}
            className={button.id === selectedButton ? "active" : ""}
            style={style}
            disabled={disabled}
            size="medium"
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
    );
  }
}

export default ButtonGroupComponent;
