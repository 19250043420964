import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card } from "@mui/material";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { IconType } from "../Button/IconButton";

import { IToolbarMode } from "../../Models/IToolbarMode";
import { GetColumnDetailsResponseModel } from "../../Models/SDMTableRequestModel";
import BulkInsertTable from "./BulkInsertTable";
import Table from "./Table";

type tableModeForEntries =
  | "multipleTableEntries"
  | "SingleTableEntries"
  | "view"
  | undefined;

interface Props {
  onClickCheckBox?: (selectedRows: any, data?: any) => void;
  tableData: any;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;
  onActionClick?: (action: IconType, recordId: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleToolbarCancel?: (modes?: string) => void;
  handleInputChange: (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => void;
  handleBulkInputChange: (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => void;
  checkBoxDifferentiator?: string | number;
  bulkEditInputFormData: any;
  commentId: number;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  mode: IToolbarMode;
  modes: tableModeForEntries;
  columnDetails: GetColumnDetailsResponseModel[];
  handleReset: (event: any, type: any) => void;
  handleApply: (event: any) => void;
  handleClone: () => void;

  bulkInsertState: any;
  handleBulkInsertInputChange: (
    event: any,
    index: any,
    targetId?: string
  ) => void;
  handleActionEditClick: () => void;
  handleMode: (action: IToolbarMode) => void;
  handleSave: (mode: string) => void;
  isButtonLoad: boolean;
  handleAddMoreField: () => void;
  handleRemoveClick: (index: number) => void;
  handleDelete: () => void;
  selectedSdmTableValue: string;
  onRationaleInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  rationale: string;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
  validation:boolean;
  getSearchFilterTableData?: (response: any) => void;
  fetchFilterData: (obj: any, searchedDetails: any) => void;
  isdataFiltered: boolean;
  filteredColumn?: { column: any; value: string; type: any };
  page: number;
  rows: number;
  allColumnDetails:GetColumnDetailsResponseModel[]
}

interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  bulkInsertHeadCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  totalCount: number;
  showstep: boolean;
  caretHover: boolean;
}

class DataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
      showstep: false,
      caretHover: false,
      bulkInsertHeadCellData: [],
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
    if (prevProps.actionArray?.length !== this.props.actionArray?.length) {
      this.changeHeadCellData();
    }
    if (prevProps.bulkInsertState !== this.props.bulkInsertState) {
      this.changeHeadCellData();
    }
  };

  componentDidMount = () => {
    this.changeHeadCellData();
  };

  onCaretHover = () => {
    this.setState({ caretHover: true });
  };

  changeHeadCellData = () => {
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        const shouldBeLinked = k === this.props.checkBoxDifferentiator;
        return {
          key: k,
          label: k,
          linkTo: shouldBeLinked
            ? k[Number(this.props.checkBoxDifferentiator)]
            : null,
        };
      });

      if (this.props.actionArray?.length) {
        headerData.unshift({
          key: "Action",
          label: "Action",
          linkTo: null,
        });
      }
      this.setState({
        headCellData: headerData,
        bulkInsertHeadCellData: headerData,
      });
    } else {
      this.setState({ headCellData: [] });
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  handleCaretClick = () => {
    this.setState({ showstep: true });
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  handleCaretCloseClick = () => {
    this.setState({ showstep: false });
  };

  render() {
    const { headCellData, showstep } = this.state;
    const {validation} = this.props;

    return (
      <>
        {this.props.modes === "multipleTableEntries" ? (
          <BulkInsertTable
            isButtonLoad={this.props.isButtonLoad}
            handleSave={this.props.handleSave}
            handleRemoveClick={this.props.handleRemoveClick}
            handleAddMoreField={this.props.handleAddMoreField}
            handleBulkInsertInputChange={this.props.handleBulkInsertInputChange}
            bulkInsertState={this.props.bulkInsertState}
            handleApply={this.props.handleApply}
            handleReset={this.props.handleReset}
            bulkEditInputFormData={this.props.bulkEditInputFormData}
            handleBulkInputChange={this.props.handleBulkInputChange}
            coulmnDetails={this.props.columnDetails}
            checkBoxDifferentiator={this.props.checkBoxDifferentiator}
            headCellData={this.state.bulkInsertHeadCellData}
            headCellDataForEntries={this.state.bulkInsertHeadCellData}
            mode={this.props.mode}
            handleToolbarCancel={this.props.handleToolbarCancel}
            modes={this.props.modes}
            handleInputChange={this.props.handleInputChange}
            validation={validation}
          />
        ) : (
          <Table
            validation={validation}
            getSearchFilterTableData={this.props.getSearchFilterTableData}
            isEmptyReset={this.props.isEmptyReset}
            isPaginationReset={this.props.isPaginationReset}
            isPaginationDisabled={this.props.isPaginationDisabled}
            rationale={this.props.rationale}
            onRationaleInputChange={this.props.onRationaleInputChange}
            selectedSdmTableValue={this.props.selectedSdmTableValue}
            handleDelete={this.props.handleDelete}
            isButtonLoad={this.props.isButtonLoad}
            handleSave={this.props.handleSave}
            handleMode={this.props.handleMode}
            handleActionEditClick={this.props.handleActionEditClick}
            handleClone={this.props.handleClone}
            handleApply={this.props.handleApply}
            handleReset={this.props.handleReset}
            bulkEditInputFormData={this.props.bulkEditInputFormData}
            handleBulkInputChange={this.props.handleBulkInputChange}
            coulmnDetails={this.props.columnDetails}
            checkBoxDifferentiator={this.props.checkBoxDifferentiator}
            onActionClick={this.props.onActionClick}
            actionArray={this.props.actionArray}
            data={this.props.tableData}
            onClickCheckBox={this.props.onClickCheckBox}
            totalRecordsCount={this.props.totalRecordsCount || 0}
            headCellData={headCellData}
            headCellDataForEntries={headCellData}
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            sortConfig={null}
            onClickLink={this.props.onClickLink}
            isLoading={this.props.isLoading}
            fieldsToColor={this.props.fieldsToColor}
            mode={this.props.mode}
            handleToolbarCancel={this.props.handleToolbarCancel}
            modes={this.props.modes}
            handleInputChange={this.props.handleInputChange}
            fetchFilterData={this.props.fetchFilterData}
            isdataFiltered={this.props.isdataFiltered}
            filteredColumn={this.props.filteredColumn}
            page={this.props.page}
            rows={this.props.rows}
            allColumnDetails={this.props.allColumnDetails}
          />
        )}

        {showstep ? (
          <Card className="Card">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={this.handleCaretCloseClick}
              style={{ marginLeft: 800, marginTop: 12 }}
            />
            <Box style={{ width: "90%" }}></Box>
          </Card>
        ) : null}
      </>
    );
  }
}

export default DataLoader;
