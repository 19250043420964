import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  Link,
  Popover,
} from "@mui/material";
import _, { pick } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { reviewStatusData } from "../../Common/Constants";
import Utils from "../../Common/Utils";
import CardContainer from "../../Components/Card/CardContainer";
import NoRecords from "../../Components/NoRecords/NoRecords";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import ReviewRecordsDataLoader from "../../Components/Table/ReviewRecordsDataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import { RoleContext } from "../../Contexts";
import { IToolbarMode } from "../../Models/IToolbarMode";
import { AccessedSDMTable } from "../../Models/SDMTableAccessModel";
import {
  GetColumnDetailsRequestModel,
  GetColumnDetailsResponseModel,
  GetSDMTableRequestModel,
  SDMRecordPayloadModel,
} from "../../Models/SDMTableRequestModel";
import AuthProviderService from "../../Services/AuthProviderService";
import ExcelService from "../../Services/ExcelService";
import SDMTableService from "../../Services/SDMTableService";
import UserAuthService from "../../Services/UserAuthService";
import FilterColumns from "./FilterColumn";
const SDMTableServices = new SDMTableService();
type tableModeForEntries = "bulkEdit" | "singleEdit" | "view" | undefined;
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  modes: tableModeForEntries;
  filterCol: { fieldName: string; checked: boolean }[];
  headcellData: any[];
  tblData: any[];
  dynamicTable: any[];
  rows: number;
  page: number;
  selectedSdmTable: any;
  record: any;
  accessedSdmTableData: AccessedSDMTable[];
  roleData: any;
  selectedSdmTableValue: string;
  totalRecordCount: number;
  checked: boolean;
  filteredColData: any[];
  isLoading: boolean;
  isSDMListLoading: boolean;
  uniqueColumnObj: any;
  uniqueColumnValue: string;
  isOpenFilterByCol: boolean;
  columnDetails: GetColumnDetailsResponseModel[];
  AllColumnDetails: GetColumnDetailsResponseModel[];
  tableCellData: any;
  isResetAllData: boolean;
  isReset: boolean;
  checkedTableData: any;
  userId: string;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isButtonLoad: boolean;
  singleTableData: any;
  filterData: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  userRoles: any;
  isEmptyReset: boolean;
  notificationId: string;
  selectStatus: string;
  selectAll: boolean;
}

class ReviewRecords extends Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      filterCol: [],
      headcellData: [],
      tblData: [],
      dynamicTable: [],
      rows: 5,
      page: 1,
      selectedSdmTable: [],
      modes: undefined,
      record: [],
      accessedSdmTableData: [],
      roleData: [],
      selectedSdmTableValue: "",
      totalRecordCount: 0,
      checked: false,
      filteredColData: [],
      isLoading: false,
      isSDMListLoading: false,
      uniqueColumnObj: {},
      uniqueColumnValue: "",
      isOpenFilterByCol: false,
      columnDetails: [],
      AllColumnDetails: [],
      tableCellData: [],
      isResetAllData: false,
      isReset: false,
      checkedTableData: [],
      userId: "",
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isButtonLoad: false,
      singleTableData: [],
      filterData: [],
      isPaginationDisabled: false,
      isPaginationReset: false,
      userRoles: [],
      isEmptyReset: false,
      notificationId: "",
      selectStatus: "",
      selectAll: false,
    };
  }

  async componentDidMount() {
    if (this.context.accessedTables.length > 0) {
      this.setState({ isSDMListLoading: true });
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userId = JSON.parse(decodedToken).uid;
      const userRole = await UserAuthService.fetchUserRoles();
      const pathName = window.location.pathname.split("/");
      const isAccess = this.context.accessedTables.some((el: any) =>
        el.tables.includes(pathName[2].toUpperCase())
      );
      if (isAccess) {
        this.setState(
          {
            isSDMListLoading: false,
            uniqueColumnObj: this.context.uniqueColumnObj,
            selectedSdmTableValue: pathName[2].toUpperCase(),
            uniqueColumnValue:
              this.context.uniqueColumnObj[pathName[2].toUpperCase()],
            userId,
            userRoles: userRole.data.data.data.roles,
            notificationId: pathName[3],
          },
          () => {
            if (
              this.state.userRoles.includes("SUPER_USER") ||
              this.state.userRoles.includes("PUBLICATION_LEAD")
            ) {
              this.handleMenuChange(pathName[2].toUpperCase(), pathName[3]);
            } else {
              this.props.history.push("/auth/noaccess");
            }
          }
        );
      } else {
        this.props.history.push("/auth/noaccess");
      }
    }
  }
  handleFilterColClick = () => {
    this.setState({ isOpenFilterByCol: !this.state.isOpenFilterByCol });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.getTableData(
        this.state.selectedSdmTableValue,
        this.state.notificationId
      );
    });
  };
  onChangePage = (page: number) => {
    this.setState({ page }, () => {
      this.getTableData(
        this.state.selectedSdmTableValue,
        this.state.notificationId
      );
    });
  };
  onClickCheckBox = (selectedRows: any, data: any, selectAll: boolean) => {
    this.setState({
      selectedSdmTable: selectedRows,
      checkedTableData: data,
      selectAll,
    });
  };

  handleResetSingle = () => {
    this.setState({
      isReset: true,
    });
  };
  onResetClick = () => {
    this.setState({
      filterCol: this.state.filterCol.map((el) => ({ ...el, checked: false })),
      dynamicTable: this.state.tblData,
    });
  };
  onDoneClick = () => {
    const { tblData, filterCol, selectedSdmTableValue } = this.state;
    const checkedCols = filterCol
      .filter((el) => el.checked)
      .map((el) => el.fieldName);
    checkedCols.push(this.state.uniqueColumnValue.toLowerCase());
    const filterColumnData = tblData.map((el) => pick(el, checkedCols));
    const arrangedResponse = filterColumnData.map((el: any) => {
      const keys = [
        ...new Set([
          this.state.uniqueColumnObj[selectedSdmTableValue].toLowerCase(),
          ...Object.keys(el),
        ]),
      ];
      const obj: any = {};
      keys.forEach((key) => {
        obj[key] = el[key];
      });
      return obj;
    });
    this.setState({
      dynamicTable: arrangedResponse,
      isOpenFilterByCol: false,
    });
  };

  onToggleCheckbox = (fieldName: string) => {
    this.setState((prev) => {
      return {
        filterCol: prev.filterCol.map((el) => {
          if (el.fieldName === fieldName) {
            return {
              ...el,
              checked: !el.checked,
            };
          }
          return {
            ...el,
          };
        }),
      };
    });
  };

  handleSelectAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { filterCol } = this.state;
    if (_event.target.checked) {
      this.setState({
        filterCol: filterCol?.map((el) => ({ ...el, checked: true })),
      });
    } else {
      this.setState({
        filterCol: filterCol?.map((el) => ({ ...el, checked: false })),
      });
    }
  };

  handleToolbarIcons = (action: IToolbarMode) => {
    if (action === "edit") {
      this.setState({ isPaginationDisabled: true });
    }
  };

  handleEditClick = () => {
    this.setState({
      isPaginationDisabled: true,
    });
  };

  handleMenuChange = async (value: string, id: string) => {
    this.setState(
      {
        selectedSdmTableValue: value,

        isPaginationDisabled: false,
        isPaginationReset: true,
        page: 1,
        rows: 5,
      },
      () => {
        this.getTableData(value, id);
      }
    );
  };

  handleSelectChange = (selected: any, index: any, targetId?: any) => {
    this.setState({
      tblData: this.state.checkedTableData.map((el: any, ind: any) => {
        if (ind === index) {
          return {
            ...el,
            [targetId]: selected.value ? selected.value : null,
            checked: true,
          };
        }
        return el;
      }),
    });
  };

  getTableData = async (value: string, id: string) => {
    try {
      this.setState({ isLoading: true });
      const obj: GetSDMTableRequestModel = {
        sdm_tablename: value.toLowerCase(),
        var_whereclause: `notification_id=${Number(id)}`,
        var_order: null,
        var_page: this.state.page,
        var_recordsperpage: this.state.rows,
      };

      const response = await SDMTableServices.getSDMTableData(obj);
      if (response.result.length > 0 && this.state.uniqueColumnObj[value]) {
        const columnArr = Object.keys(response.result[0]).map((key) => {
          return {
            fieldName: key,
            checked: false,
          };
        });
        const arrangedResponse = response.result.map((el: any) => {
          const keys = [
            ...new Set([
              this.state.uniqueColumnObj[value].toLowerCase(),
              ...Object.keys(el),
            ]),
          ];
          const newKeys = keys.filter((elem) => elem !== "primary_col_name");
          const obj: any = {};
          newKeys.forEach((key) => {
            obj[key] = el[key];
          });
          return obj;
        });
        this.setState(
          {
            tblData: arrangedResponse,
            headcellData: Object.keys(arrangedResponse[0]),
            singleTableData: arrangedResponse,
            dynamicTable: arrangedResponse,
            filterCol: columnArr.filter(
              (el) =>
                ![
                  "primary_col_name",
                  this.state.uniqueColumnObj[
                    this.state.selectedSdmTableValue
                  ].toLowerCase(),
                ].includes(el.fieldName)
            ),
            totalRecordCount: Number(response.total_count),
            uniqueColumnValue: this.state.uniqueColumnObj[value],
          },
          () => {
            this.getColumnDetails({ tableName: value.toLowerCase() });

            this.setState({
              isLoading: false,
              isPaginationReset: false,
              isEmptyReset: false,
            });
          }
        );
      } else if (
        response.result.length === 0 &&
        this.state.uniqueColumnObj[value] &&
        this.state.page > 1
      ) {
        const obj: GetSDMTableRequestModel = {
          sdm_tablename: value.toLowerCase(),
          var_whereclause: null,
          var_order: null,
          var_page: 1,
          var_recordsperpage: this.state.rows,
        };
        const response = await SDMTableServices.getSDMTableData(obj);
        const columnArr = Object.keys(response.result[0]).map((key) => {
          return {
            fieldName: key,
            checked: false,
          };
        });
        const arrangedResponse = response.result.map((el: any) => {
          const keys = [
            ...new Set([
              this.state.uniqueColumnObj[value].toLowerCase(),
              ...Object.keys(el),
            ]),
          ];
          const obj: any = {};
          keys.forEach((key) => {
            obj[key] = el[key];
          });
          return obj;
        });

        this.setState(
          {
            tblData: arrangedResponse,
            singleTableData: arrangedResponse,
            dynamicTable: arrangedResponse,
            filterCol: columnArr.filter(
              (el) =>
                ![
                  "primary_col_name",
                  this.state.uniqueColumnObj[
                    this.state.selectedSdmTableValue
                  ].toLowerCase(),
                ].includes(el.fieldName)
            ),
            totalRecordCount: Number(response.total_count),
            uniqueColumnValue: this.state.uniqueColumnObj[value],
          },
          () => {
            this.getColumnDetails({ tableName: value.toLowerCase() });

            this.setState({ isLoading: false, isEmptyReset: true, page: 1 });
          }
        );
      } else if (
        response.result.length === 0 &&
        this.state.uniqueColumnObj[value]
      ) {
        this.setState({
          isLoading: false,
          tblData: [],
          totalRecordCount: 0,
          filterCol: [],
          uniqueColumnValue: this.state.uniqueColumnObj[value],
        });
      } else {
        this.setState({
          isLoading: false,
          tblData: [],
          singleTableData: [],
          dynamicTable: [],
          filterCol: [],
          totalRecordCount: 0,
          uniqueColumnValue: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  getColumnDetails = async (tableName: GetColumnDetailsRequestModel) => {
    const response = await SDMTableServices.getColumnDetails(tableName);
    const fieldName = response.data.fieldsData.map((key) => key.fieldName);
    fieldName.filter(
      (val) => val.toLowerCase() === this.state.uniqueColumnValue.toLowerCase()
    );
    fieldName.unshift(this.state.uniqueColumnValue.toLowerCase());
    let obj: any = {};
    fieldName.map((value) => {
      return (obj[value] = null);
    });

    this.setState({
      columnDetails: response.data.fieldsData.filter(
        (el) =>
          ![
            "last_updt_dt",
            "last_updt_usr",
            "lst_updt_dt",
            "lst_updt_usr",
            "ovr_last_updt_dt",
            "ovr_last_updt_usr",
            this.state.uniqueColumnValue.toLowerCase(),
          ].includes(el.fieldName)
      ),
      AllColumnDetails: response.data.fieldsData,
    });
  };

  handleSaveRecord = async () => {
    const {
      selectedSdmTableValue,
      userId,
      selectedSdmTable,
      singleTableData,
      uniqueColumnValue,
      notificationId,
    } = this.state;

    this.setState({ isButtonLoad: true });
    if (selectedSdmTable.length === singleTableData.length) {
      const changedProperties = [];

      for (let i = 0; i < singleTableData.length; i++) {
        const originalObj = singleTableData[i];
        const updatedObj = selectedSdmTable[i];

        const changes: any = {};

        for (const key in originalObj) {
          if (originalObj[key] !== updatedObj[key]) {
            changes[key] = updatedObj[key];
          }
        }

        if (Object.keys(changes).length > 0) {
          const changedObject = {
            [uniqueColumnValue.toLowerCase()]:
              updatedObj[uniqueColumnValue.toLowerCase()],
          };

          for (const key in changes) {
            changedObject[key] = changes[key];
          }
          if (
            Object.keys(selectedSdmTable[0]).find((el: any) =>
              ["lst_updt_dt", "lst_updt_usr"].includes(el)
            )
          ) {
            const obj = {
              lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
              lst_updt_usr: userId,
            };
            changedProperties.push({ ...changedObject, ...obj });
          } else if (
            Object.keys(selectedSdmTable[0]).find((el: any) =>
              ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
            )
          ) {
            const obj = {
              ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
              ovr_last_updt_usr: userId,
            };
            changedProperties.push({ ...changedObject, ...obj });
          } else {
            const obj = {
              last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
              last_updt_usr: userId,
            };
            changedProperties.push({ ...changedObject, ...obj });
          }
        }
      }
      if (changedProperties.length > 0) {
        const requestObj: SDMRecordPayloadModel = {
          sdm_tablename: selectedSdmTableValue.toLowerCase(),
          sdm_payload: changedProperties,
        };

        const response = await SDMTableServices.updateRecords(requestObj);

        if (response.result === "Success") {
          this.getTableData(selectedSdmTableValue, notificationId);
          this.setState({
            alertMsg: "Records are updated successfully.",
            alertType: "success",
            openAlert: true,
            isButtonLoad: false,
            isPaginationDisabled: false,
            selectedSdmTable: [],
            modes: "view",
            selectStatus: "",
            selectAll: false,
          });
        } else {
          this.setState({
            alertMsg: _.capitalize(
              response.result.split("CONTEXT")[0].toLowerCase()
            ),
            alertType: "error",
            openAlert: true,
            isButtonLoad: false,
          });
        }
      } else {
        this.setState({
          alertMsg: "Please update the selected record before submitting.",
          alertType: "error",
          openAlert: true,
          isButtonLoad: false,
        });
      }
    } else {
      const differences: any[] = [];
      const mergedObj: any[] = [];

      singleTableData.forEach((obj1: any) => {
        const obj2 = selectedSdmTable.find(
          (obj2: any) => obj2[uniqueColumnValue] === obj1[uniqueColumnValue]
        );

        if (obj2) {
          const differenceObj = {
            [uniqueColumnValue]: obj2[uniqueColumnValue],
          };

          for (const key in obj1) {
            if (obj1[key] !== obj2[key]) {
              differenceObj[key] = obj2[key];
            }
          }

          if (Object.keys(differenceObj).length > 1) {
            differences.push(differenceObj);
            const existingObj = mergedObj.find(
              (el: any) =>
                el[uniqueColumnValue.toLowerCase()] ===
                obj2[uniqueColumnValue.toLowerCase()]
            );

            if (existingObj) {
              Object.assign(existingObj, differenceObj);
            } else {
              if (
                Object.keys(selectedSdmTable[0]).find((el: any) =>
                  ["lst_updt_dt", "lst_updt_usr"].includes(el)
                )
              ) {
                const obj = {
                  lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  lst_updt_usr: userId,
                };
                mergedObj.push({ ...differenceObj, ...obj });
              } else if (
                Object.keys(selectedSdmTable[0]).find((el: any) =>
                  ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
                )
              ) {
                const obj = {
                  ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  ovr_last_updt_usr: userId,
                };
                mergedObj.push({ ...differenceObj, ...obj });
              } else {
                const obj = {
                  last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  last_updt_usr: userId,
                };
                mergedObj.push({ ...differenceObj, ...obj });
              }
            }
          }
        }
      });
      if (mergedObj.length > 0) {
        const requestObj: SDMRecordPayloadModel = {
          sdm_tablename: selectedSdmTableValue.toLowerCase(),
          sdm_payload: mergedObj,
        };

        const response = await SDMTableServices.updateRecords(requestObj);

        if (response.result === "Success") {
          this.getTableData(selectedSdmTableValue, notificationId);
          this.setState({
            alertMsg:
              selectedSdmTable.length > 1
                ? "Records are updated successfully."
                : "Record is updated successfully.",
            alertType: "success",
            openAlert: true,
            isButtonLoad: false,
            isPaginationDisabled: false,
            selectedSdmTable: [],
            modes: "view",
            selectStatus: "",
            selectAll: false,
          });
        } else {
          this.setState({
            alertMsg: _.capitalize(
              response.result.split("CONTEXT")[0].toLowerCase()
            ),
            alertType: "error",
            openAlert: true,
            isButtonLoad: false,
          });
        }
      } else {
        this.setState({
          alertMsg: "Please update the selected record before submitting.",
          alertType: "error",
          openAlert: true,
          isButtonLoad: false,
        });
      }
    }
  };

  handleExcelDownload = async (event: any) => {
    const { tblData } = this.state;
    const searchResult: any = [];
    const tableHeaders: any = Object.keys(tblData[0]);
    tblData.forEach((obj) => {
      const allValues = Object.values(obj);
      searchResult.push(allValues);
    });
    ExcelService.CreateDataFile(
      this.state.selectedSdmTableValue,
      searchResult,
      tableHeaders
    );
  };

  handleSelectStatusChange = (selected: SelectModel) => {
    this.setState(
      {
        selectStatus: selected.value,
        modes: "bulkEdit",
        isPaginationDisabled: true,
      },
      () => {
        const selectedSdmTableSet = new Set(
          this.state.selectedSdmTable.map(
            (val: any) => val[this.state.uniqueColumnValue]
          )
        );

        const data = this.state.checkedTableData.map((el: any) => {
          if (selectedSdmTableSet.has(el[this.state.uniqueColumnValue])) {
            return {
              ...el,
              review_status: selected.value,
              checked: true,
            };
          }
          return el;
        });

        this.setState({ tblData: data });
      }
    );
  };

  handleCancelClick = () => {
    const uncheckedData = this.state.checkedTableData.map((el: any) => {
      if (el.review_status === "") {
        return {
          ...el,
          review_status: "",
          checked: false,
        };
      }
      return el;
    });
    this.setState(
      {
        tblData: uncheckedData,
        modes: "view",
        selectedSdmTable: [],
        isPaginationDisabled: false,
        selectStatus: "",
      },
      () => {
        this.getTableData(
          this.state.selectedSdmTableValue,
          this.state.notificationId
        );
      }
    );
  };
  render() {
    const {
      tblData,
      selectedSdmTableValue,
      totalRecordCount,
      isLoading,
      isSDMListLoading,
      uniqueColumnValue,
      alertMsg,
      alertType,
      openAlert,
      isPaginationDisabled,
      isPaginationReset,
      isEmptyReset,
      filterCol,
      dynamicTable,
      selectedSdmTable,
      selectStatus,
      modes,
      selectAll,
      isButtonLoad,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Box pb={4}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "25px 10px",
            }}
          >
            <PageHeader
              label={`SDM - ${selectedSdmTableValue}`}
              style={{
                fontSize: "22px",
              }}
            />
          </Box>
          <CardContainer>
            {isSDMListLoading ? (
              <Box
                style={{
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  margin: "30vh",
                }}
              >
                <CircularProgress sx={{ color: "#d52b1e" }} />
              </Box>
            ) : (
              <>
                {tblData.length > 0 || isLoading ? (
                  <>
                    <Grid container p={2} flexDirection="row">
                      <Grid container item>
                        <Grid item xs={3} mt={2.5}>
                          {selectedSdmTable.length > 1 ? (
                            <SingleSelect
                              value={selectStatus}
                              values={reviewStatusData}
                              onChange={this.handleSelectStatusChange}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={7.5}
                          mt={2.5}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Link
                            style={{
                              color:
                                tblData.length > 0 &&
                                tblData[0][uniqueColumnValue] === null
                                  ? "#707070"
                                  : "#0577A3",
                              cursor:
                                tblData.length > 0 &&
                                tblData[0][uniqueColumnValue] === null
                                  ? "default"
                                  : "pointer",
                              textDecoration: "none",
                              marginTop: "10px",
                              display: "block",
                            }}
                            onClick={
                              tblData.length > 0 &&
                              tblData[0][uniqueColumnValue] === null
                                ? () => {}
                                : this.handleFilterColClick
                            }
                          >
                            <FilterListIcon
                              style={{
                                marginTop: "1px",
                                float: "left",
                              }}
                            />
                            Filter by Columns
                          </Link>
                          <Popover
                            open={
                              tblData.length > 0 &&
                              tblData[0][uniqueColumnValue] === null
                                ? false
                                : this.state.isOpenFilterByCol
                            }
                            anchorReference="anchorPosition"
                            anchorPosition={{ top: 150, left: 835 }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <FilterColumns
                              handleFilterColClick={this.handleFilterColClick}
                              onToggleCheckbox={this.onToggleCheckbox}
                              onDoneClick={this.onDoneClick}
                              onResetClick={this.onResetClick}
                              filterCol={this.state.filterCol}
                              handleSelectAll={this.handleSelectAll}
                            />
                          </Popover>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          mt={2.5}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Link
                            style={{
                              color: "#0577A3",
                              cursor: "pointer",
                              textDecoration: "none",
                              marginTop: "10px",
                              display: "block",
                            }}
                            onClick={this.handleExcelDownload}
                          >
                            <ArrowDownwardIcon
                              style={{
                                marginTop: "1px",
                                float: "left",
                                marginRight: "3px",
                              }}
                            />
                            Export to Excel
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                    <hr
                      style={{
                        border: "0.5px solid #D8D8D8",
                      }}
                    />

                    <ReviewRecordsDataLoader
                      isButtonLoad={isButtonLoad}
                      selectAllCheck={selectAll}
                      handleSave={this.handleSaveRecord}
                      handleCancelClick={this.handleCancelClick}
                      isLoading={isLoading}
                      isEmptyReset={isEmptyReset}
                      isPaginationReset={isPaginationReset}
                      isPaginationDisabled={isPaginationDisabled}
                      onChangePage={this.onChangePage}
                      onChangeRow={this.onChangeRow}
                      tableData={
                        filterCol.filter((el) => el.checked).length
                          ? dynamicTable
                          : tblData
                      }
                      totalRecordsCount={totalRecordCount}
                      onClickCheckBox={this.onClickCheckBox}
                      checkBoxDifferentiator={uniqueColumnValue.toLowerCase()}
                      onSelectChange={this.handleSelectChange}
                      modes={modes}
                    />
                  </>
                ) : (
                  <Box style={{ margin: "20vh" }}>
                    <NoRecords />
                  </Box>
                )}
              </>
            )}
          </CardContainer>
        </Box>
      </>
    );
  }
}

export default withRouter(ReviewRecords);
