import { Component } from "react";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import PageHeader from "../../../Components/Text/PageHeader";
type Props = {
  values: any;
};

type State = {
  isHovering: string;
};

export default class FilesInfo extends Component<Props, State> {
  render() {
    return (
      <Box>
        <PageHeader style={{ marginLeft: "5px" }} label="Files Info" />
        <Divider style={{ marginBottom: "1em" }} />
        <Grid
          container
          width={"30em"}
          maxHeight={"30em"}
          direction={"row"}
          rowSpacing={1}
          style={{
            alignContent: "left",
            paddingRight: "5px",
            paddingLeft: "2px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {this.props.values.length > 0 ? (
            <>
              {this.props.values.map((el: any, index: any) => {
                return (
                  <Grid container item key={index}>
                    <Grid item xs={12} style={{ color: "#D52B1E" }}>
                      <Typography>{el.roleName} :</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {el.fileName.map((val: any, ind: any) => {
                        
                        return (
                          <ul key={ind} style={{
                            paddingBottom:"5px"
                          }}>
                            <li style={{ fontSize: "1rem" }}>{val}</li>
                          </ul>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
                    
            </>
          ) : (
            <Box
              style={{
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                margin: "20vh",
              }}
            >
              <CircularProgress sx={{ color: "#d52b1e" }} />
            </Box>
          )}
        </Grid>
      </Box>
    );
  }
}
