import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDown from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUp from "@mui/icons-material/ArrowDropUpOutlined";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import * as React from "react";

import TuneIcon from "@mui/icons-material/Tune";
import Utils from "../../Common/Utils";
import { RoleContext, UserLoginContext } from "../../Contexts";
import AuthProviderService from "../../Services/AuthProviderService";
import GradientButton from "../Button/GradientButton";
import CustomizedView from "./CustomizedView";

interface Props {
  showMenu: boolean;
}
interface State {
  open: boolean;
  anchorEl: any;
  userName: string;
  showRoles: boolean;
  prvContext: boolean;
  showCustomisedDialog: boolean;
}

class AccountInfo extends React.Component<Props, State> {
  static contextType = UserLoginContext;
  context!: React.ContextType<typeof UserLoginContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      userName: "",
      showRoles: false,
      prvContext: false,
      showCustomisedDialog: false,
    };
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      this.context.isUserLoggedIn &&
      this.state.prvContext !== this.context.isUserLoggedIn
    ) {
      const isLoggedIn = await AuthProviderService.isLoggedIn();

      if (isLoggedIn) {
        AuthProviderService.getAccessToken()
          .then((token) => {
            const decodedToken = JSON.stringify(
              Utils.decodeJWT(token.accessToken)
            );

            const userName = JSON.parse(decodedToken).name;
            this.setState({ userName, prvContext: true });
          })
          .catch((er) => console.log(er));
      }
    }
  }
  onToggleCheckbox = () => {};

  handleModalClose = () => {
    this.setState({ showCustomisedDialog: false });
  };
  render() {
    const { anchorEl, userName, showRoles, showCustomisedDialog } = this.state;

    const open = Boolean(anchorEl);
    return (
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="My Account">
            <IconButton
              onClick={
                window.location.pathname === "/lockedview"
                  ? () => {}
                  : this.handleClick
              }
              size="small"
              sx={{
                ml: 2,
                cursor:
                  window.location.pathname === "/lockedview"
                    ? "default"
                    : "pointer",
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <AccountCircleIcon
                sx={{ width: "2rem", height: "2rem" }}
                style={{
                  color: "#FFFFFF",
                }}
              />
              {open ? (
                <ArrowDropUp
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              ) : (
                <ArrowDropDown
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Avatar /> {userName}
          </MenuItem>

          <Divider />
          {this.props.showMenu ? (
            <RoleContext.Consumer>
              {({ userRoles }) => (
                <>
                  <MenuItem
                    onClick={() => {
                      this.setState({ showRoles: !showRoles });
                    }}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>Roles</Box>
                    {showRoles ? (
                      <ArrowDropUp
                        style={{
                          color: "black",
                        }}
                      />
                    ) : (
                      <ArrowDropDown
                        style={{
                          color: "black",
                        }}
                      />
                    )}
                  </MenuItem>
                  {showRoles ? (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {userRoles.map((el) => {
                        return <Box>{el}</Box>;
                      })}
                    </MenuItem>
                  ) : null}
                  <Divider />
                </>
              )}
            </RoleContext.Consumer>
          ) : null}
          <MenuItem
            style={{
              display: "flex",
              alignItems: "start",
              marginBottom: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "24px !important" }}>
              <TuneIcon fontSize="small" style={{ color: "#00749E" }} />
            </ListItemIcon>
            <span
              style={{
                textDecoration: "underline",
                textDecorationColor: "#00749E",
                color: "#00749E",
                fontSize: "14px",
              }}
              onClick={() => {
                this.setState({ showCustomisedDialog: true });
              }}
            >
              Customised View
            </span>
          </MenuItem>
          <MenuItem style={{ display: "flex", justifyContent: "center" }}>
            <GradientButton
              id="button"
              label="Logout"
              onClick={this.handleLogout}
            />
          </MenuItem>
        </Menu>
        {showCustomisedDialog ? (
          <CustomizedView
            showCustomisedDialog={showCustomisedDialog}
            handleModalClose={this.handleModalClose}
          />
        ) : (
          <></>
        )}
        {/* <ModalDialog
          isOpen={showCustomisedDialog}
          blackTitleColor
          title="Customised View"
          onClose={() => this.setState({ showCustomisedDialog: false })}
          dialogWidth="sm"
          scrollBar={showCustomisedDialog}
        >
          <Grid container columns={12} spacing={3} pl={2}>
            <Grid item xs={6} className="SearchNameInput">
              <Typography fontWeight={"bold"}>Select Geographies </Typography>
              <div
                style={{
                  border: "1px solid #9e9e9e",
                  width: "240px",
                  borderRadius: "4px",
                }}
              >
                <SearchFilter
                  placeholder="Search"
                  value={searchGeogrphiesText}
                  onChange={this.handleSearchChange}
                />
                <div
                  style={{
                    maxHeight: 350,
                    overflowY: "scroll",
                    paddingTop: "10px",
                  }}
                >
                  {GeographiesList.map((el, index) => {
                    return (
                      <Grid item display="flex" key={index}>
                        <Checkbox
                          tabIndex={-1}
                          onChange={this.onToggleCheckbox}
                          sx={{
                            "&.MuiCheckbox-root": {
                              padding: "5px",
                            },
                            "&.Mui-checked": {
                              color: "#3DA470",
                            },
                            "& .MuiSvgIcon-root": {
                              borderRadius: "4px",
                            },
                          }}
                        />
                        <Typography
                          style={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                          }}
                          textTransform={"capitalize"}
                          sx={{
                            "&.MuiTypography-root": {
                              lineHeight: 0,
                            },
                            p: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        >
                          {el.name}
                        </Typography>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} className="SearchNameInput">
              <Typography fontWeight={"bold"}>Select Sources </Typography>
              <div
                style={{
                  border: "1px solid #9e9e9e",
                  width: "240px",
                  borderRadius: "4px",
                }}
              >
                <SearchFilter
                  placeholder="Search"
                  value={searchSourcesText}
                  onChange={this.handleSearchChange}
                />
                <div
                  style={{
                    maxHeight: 350,
                    overflowY: "scroll",
                    paddingTop: "10px",
                  }}
                >
                  {SourcesList.map((el, index) => {
                    return (
                      <Grid item display="flex" key={index}>
                        <Checkbox
                          tabIndex={-1}
                          onChange={this.onToggleCheckbox}
                          sx={{
                            "&.MuiCheckbox-root": {
                              padding: "5px",
                            },
                            "&.Mui-checked": {
                              color: "#3DA470",
                            },
                            "& .MuiSvgIcon-root": {
                              borderRadius: "4px",
                            },
                          }}
                        />
                        <Typography
                          style={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                          }}
                          textTransform={"capitalize"}
                          sx={{
                            "&.MuiTypography-root": {
                              lineHeight: 0,
                            },
                            p: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        >
                          {el.name}
                        </Typography>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container columnGap={1.5} justifyContent={"flex-end"} mt={3}>
            <RedOutlinedButton
              label="Cancel"
              onClick={() => this.setState({ showCustomisedDialog: false })}
            />

            <GradientButton label="Done" onClick={() => {}} />
          </Grid>
        </ModalDialog> */}
      </>
    );
  }
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleLogout = async () => {
    localStorage.clear();
    this.setState({ prvContext: false }, () => {
      this.context.setIsLoggedIn(false);
      AuthProviderService.logout();
    });
  };
}
export default AccountInfo;
