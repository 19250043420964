import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import "../../App.css";

interface Props {
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  label?: "";
  value: any;
  placeholder?: string;
  searchFilterclear?: any;
  isClearIconRequired?: boolean;
  disabled?: boolean;
}

interface State {}

class SearchFilter extends React.Component<Props, State> {
  handleOnClick = () => {
    this.props.searchFilterclear();
  };

  render() {
    const {
      label,
      onChange,
      value,
      placeholder,
      disabled,
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <TextField
            size="small"
            id="Search"
            style={{
              background: "#263F6A",
              color: "#fff",
              borderRadius: "4px",
            }}
            label={label}
            value={value}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    color="red"
                    icon={faMagnifyingGlass}
                    cursor="pointer"
                  />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    );
  }
}

export default SearchFilter;
