import { Box, Card, Grid, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import React, { Component } from "react";

type Props = {
  graphNumbers?: string | null | number;
  graphImg?: string;
  headingIcon?: string;
  headingText?: string;
  pricePercentText?: number;
  icon?: any;
  iconChart?: any;
  titleStyle?: React.CSSProperties;
  color?: string;
  expandImg?: string;
  isLoadingCard?: boolean;
};

class DashboardCard extends Component<Props> {
  render() {
    const {
      graphNumbers,
      graphImg,
      headingIcon,
      headingText,
      pricePercentText,
      icon,
      titleStyle,
      color,
      iconChart,
      expandImg,
    } = this.props;
    return (
      <Card
        sx={{
          borderRadius: 2,
          borderWidth: "4px",
          backgroundColor: "white",
          border:
            graphNumbers === "No Change"
              ? "solid 2px #939393"
              : `solid 2px ${color}`,
          width: this.props.iconChart ? "20em" : "25em",
          padding: this.props.iconChart ? 0 : 1,
        }}
      >
        <>
          <Box>
            <Box>
              <Box
                style={{
                  color: "#5A607F",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <img src={headingIcon} alt="" />
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "bold",
                      ...titleStyle,
                      color: graphNumbers === "No Change" ? "#939393" : color,
                      verticalAlign: "text-top",
                      lineHeight: this.props.iconChart ? "-1px" : "5px",
                    }}
                  >
                    {headingText}
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    gap: "1em",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  {this.props.iconChart || graphNumbers === "No Change" ? (
                    ""
                  ) : (
                    <img
                      src={expandImg}
                      alt="expandImg"
                      width={"12px"}
                      height={"12px"}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <CardContent
            style={{
              paddingBottom: this.props.iconChart ? "0px" : "5px",
            }}
            sx={{
              padding: 0,
              marginLeft: "0.5em",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Grid item xs={8}>
                <Typography
                  style={{ fontWeight: 900, fontSize: "22px" }}
                  color={graphNumbers === "No Change" ? "#939393" : "#000000"}
                >
                  {graphNumbers}
                </Typography>
              </Grid>
              <Grid xs={this.props.iconChart ? 2.5 : 4} item>
                <Box
                  style={{
                    border:
                      graphNumbers === "No Change"
                        ? "solid 2px #939393"
                        : `solid 2px ${color}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    width: this.props.iconChart ? "2.5em" : "3em",
                    height: this.props.iconChart ? "2.5em" : "3em",
                    zIndex: 0,
                    color: graphNumbers === "No Change" ? "#939393" : color,
                  }}
                >
                  <img src={graphImg} alt="" /> {iconChart}
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography style={{ color: "#7E84A3", fontSize: "13px" }}>
                  <>
                    {this.props.graphNumbers === "No Change" ? (
                      <>
                        <p></p>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color:
                              pricePercentText === 0 &&
                              graphNumbers === "No Change"
                                ? "#939393"
                                : pricePercentText! > 0
                                ? "green"
                                : "red",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          {pricePercentText!.toFixed(2)} % {icon}
                        </span>
                        <> than last month</>
                      </>
                    )}
                  </>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      </Card>
    );
  }
}
export default DashboardCard;
