import {
  faAngleLeft,
  faClone,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AlertColor,
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CardContainer from "../../Components/Card/CardContainer";

import PageHeader from "../../Components/Text/PageHeader";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import _, { isEqual } from "lodash";
import Utils from "../../Common/Utils";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { IBreadCrumb } from "../../Components/BreadCrumb/IBreadCrumb";
import GradientButton from "../../Components/Button/GradientButton";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import InputTypeSelector from "../../Components/InputTypeSelector/InputTypeSelector";
import ModalDialog from "../../Components/Modal/ModelDialog";
import NoRecords from "../../Components/NoRecords/NoRecords";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInputField";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { RoleContext } from "../../Contexts";
import {
  GetColumnDetailsRequestModel,
  GetSDMTableRequestModel,
  ReadRationalePayloadModel,
  SDMRecordPayloadModel,
} from "../../Models/SDMTableRequestModel";
import AuthProviderService from "../../Services/AuthProviderService";
import SDMTableService from "../../Services/SDMTableService";
import UserAuthService from "../../Services/UserAuthService";
const SDMTableServices = new SDMTableService();
interface Props extends RouteComponentProps<any, any, any> {
  validation: boolean;
}

type ScreenMode = "view" | "edit" | "clone";

interface State {
  lovId: number | undefined;
  recordId: any;
  mode: ScreenMode;
  showDeleteDialoge: boolean;
  editFormData: any;
  alertType: AlertColor;
  openAlert: boolean;
  alertMsg: string;
  initialFormData: any;
  record: any;
  uniqueValue: any;
  columnDetails: any;
  filterData: any;
  minHeight: any;
  isButtonLoad: boolean;
  selectedSdmTableValue: string;
  userId: string;
  isView: boolean;
  rationale: string;
  isLoading: boolean;
  rationaleHistory: any;
  validation: boolean;
  uniqueColumnObj: any;
  uniqueColumnValue: string;
  userRoles: any;
  data: any;
  isAccess: boolean;
}

class ViewAndEditRecord extends Component<Props, State> {
  private cardRef: React.RefObject<HTMLDivElement>;
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.cardRef = React.createRef();
    this.state = {
      mode: "view",
      recordId: undefined,
      lovId: undefined,
      uniqueValue: null,
      editFormData: [],
      showDeleteDialoge: false,
      alertType: "success",
      openAlert: false,
      alertMsg: "",
      initialFormData: [],
      record: [],
      columnDetails: [],
      filterData: [],
      minHeight: 0,
      isButtonLoad: false,
      selectedSdmTableValue: "",
      userId: "",
      isView: false,
      rationale: "",
      isLoading: false,
      rationaleHistory: [],
      validation: true,
      uniqueColumnObj: {},
      uniqueColumnValue: "",
      userRoles: [],
      data: [],
      isAccess: false,
    };
  }
  async componentDidMount() {
    if (this.context.accessedTables.length > 0) {
      this.setState({ isLoading: true });
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userId = JSON.parse(decodedToken).uid;
      const userRole = await UserAuthService.fetchUserRoles();
      const pathName = window.location.pathname.split("/");
      const data = this.context.accessedTables.map((el: any) => {
        return {
          roleAssigned: el.roleAssigned,
          tables: el.tables.map((val: any) => {
            return { text: val, value: val };
          }),
        };
      });
      const isAccess = this.context.accessedTables.some((el: any) =>
        el.tables.includes(pathName[2].toUpperCase())
      );

      this.setState(
        {
          isAccess,
          data,
          uniqueColumnObj: this.context.uniqueColumnObj,
          uniqueColumnValue:
            this.context.uniqueColumnObj[pathName[2].toUpperCase()],
          uniqueValue: this.context.uniqueColumnObj[pathName[2].toUpperCase()],
          userId,
          userRoles: userRole.data.data.data.roles,
          selectedSdmTableValue: pathName[2],
          recordId: pathName[3],
        },
        () => {
          if (isAccess) {
            this.getTableData(pathName[2].toUpperCase());
            this.getRationaleData(pathName);
          } else {
            this.props.history.push("/auth/noaccess");
          }
        }
      );
    }
  }
  getRationaleData = async (pathName: any) => {
    try {
      const rationaleObj: ReadRationalePayloadModel = {
        sdm_tablename: pathName[2],
        table_key_value: pathName[3],
      };
      const rationaleHistory = await SDMTableServices.readRationaleData(
        rationaleObj
      );

      this.setState(
        {
          rationaleHistory: JSON.parse(rationaleHistory.result),

          minHeight: this.cardRef.current?.offsetHeight,
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  getTableData = async (value: string) => {
    try {
      this.setState({ isLoading: true });
      const obj: GetSDMTableRequestModel = {
        sdm_tablename: value.toLowerCase(),
        var_whereclause: `${this.state.uniqueColumnValue}='${this.state.recordId}'`,
        var_order: null,
        var_page: null,
        var_recordsperpage: null,
      };

      const response = await SDMTableServices.getSDMTableData(obj);

      if (response.result.length > 0) {
        const arrangedResponse = response.result.map((el: any) => {
          const keys = [
            ...new Set([
              this.state.uniqueColumnObj[value].toLowerCase(),
              ...Object.keys(el),
            ]),
          ];
          const newKeys = keys.filter((elem) => elem !== "primary_col_name");
          const obj: any = {};
          newKeys.forEach((key) => {
            obj[key] = el[key];
          });
          return obj;
        });

        this.setState(
          {
            editFormData: arrangedResponse,
            initialFormData: arrangedResponse,
            record: arrangedResponse,
            isLoading: false,
          },
          () => {
            this.getColumnDetails({ tableName: value.toLowerCase() });
          }
        );
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  getColumnDetails = async (tableName: GetColumnDetailsRequestModel) => {
    try {
      const response = await SDMTableServices.getColumnDetails(tableName);

      this.setState(
        {
          columnDetails: response.data.fieldsData.filter(
            (el) =>
              ![
                "last_updt_dt",
                "last_updt_usr",
                "lst_updt_dt",
                "lst_updt_usr",
                "ovr_last_updt_dt",
                "ovr_last_updt_usr",
                this.state.uniqueColumnValue.toLowerCase(),
              ].includes(el.fieldName)
          ),
        },
        () => {
          const filterData = Object.keys(this.state.record[0])
            .filter(
              (key: any) =>
                !this.state.columnDetails.some(
                  (item: any) => item.fieldName === key
                )
            )
            .map((q) => ({ [q]: this.state.record[0][q] }));
          this.setState({ filterData });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  toggleScreenMode = () => {
    this.setState({ mode: this.state.mode === "edit" ? "view" : "edit" });
  };

  onClickAction = (action: "cancel" | "save" | "clone" | "edit") => {
    switch (action) {
      case "cancel":
        if (this.state.mode === "clone" || this.state.mode === "edit") {
          this.setState({ mode: "view", record: this.state.initialFormData });
        } else {
          this.setState({ mode: "view" });
        }
        break;
      case "edit":
        this.toggleScreenMode();
        break;
      case "save":
        if (this.state.mode === "clone") {
          this.handleSaveRecord();
        } else {
          this.setState({ mode: "edit" });
          this.handleUpdateRecord();
        }
        break;
      case "clone":
        this.setState({ mode: "clone" });
        break;
      default:
        break;
    }
  };
  onEditInputChange = (event: any, targetId?: string) => {
    if (targetId) {
      if (
        targetId.toLowerCase().startsWith("dt_") ||
        targetId.toLowerCase().endsWith("_dt")
      ) {
        this.setState({
          record: this.state.record.map((el: any, ind: any) => {
            return {
              ...el,
              [targetId]: event ? event : null,
            };
          }),
        });
      } else {
        this.setState({
          record: this.state.record.map((el: any, ind: any) => {
            return {
              ...el,
              [targetId]: event.target.value ? event.target.value : null,
            };
          }),
        });
      }
    }
  };
  handleDelete = async () => {
    const { record, uniqueValue, selectedSdmTableValue } = this.state;
    this.setState({ isButtonLoad: true });
    try {
      const filterArr = record.map((el: any) => ({
        [uniqueValue.toLowerCase()]: el[uniqueValue.toLowerCase()],
      }));

      const requestObj: SDMRecordPayloadModel = {
        sdm_tablename: selectedSdmTableValue.toLowerCase(),
        sdm_payload: filterArr,
      };
      const response = await SDMTableServices.deleteRecords(requestObj);

      if (response.result === "Success") {
        this.setState(
          {
            alertMsg: "Records are deleted successfully.",
            alertType: "success",
            openAlert: true,
            showDeleteDialoge: false,
            isButtonLoad: false,
          },
          () => {
            setTimeout(() => {
              this.props.history.push(`/sdm`);
            }, 1500);
          }
        );
      } else {
        this.setState({
          alertMsg: _.capitalize(
            response.result.split("CONTEXT")[0].toLowerCase()
          ),
          alertType: "error",
          openAlert: true,
          showDeleteDialoge: false,
          isButtonLoad: false,
        });
      }
    } catch (error) {
      this.setState({
        alertMsg: "Something went wrong",
        alertType: "error",
        openAlert: true,
        showDeleteDialoge: false,
        isButtonLoad: false,
      });
    }
  };

  onVerifyFields = () => {
    const { columnDetails, record } = this.state;
    const requiredCol = columnDetails.filter((el: any) => {
      return el.isRequired.toLowerCase() === "no";
    });
    const requireColArr = requiredCol.map((ele: any) =>
      ele.fieldName.toLowerCase()
    );

    const emptyCheck = (inputdata: any) => {
      const emptyKeyArr: any = [];
      requireColArr.forEach((ele: any) => {
        if (
          inputdata.hasOwnProperty(ele) &&
          (inputdata[ele] === null ||
            inputdata[ele] === undefined ||
            inputdata[ele] === "")
        ) {
          emptyKeyArr.push(ele);
        }
      });

      return emptyKeyArr.length === 0;
    };

    if (emptyCheck(record[0])) {
      return true;
    } else {
      return false;
    }
  };
  handleSaveRecord = async () => {
    const { mode, userId, selectedSdmTableValue, record, uniqueValue } =
      this.state;
    if (this.onVerifyFields() === true) {
      const recordObj = record;
      if (mode === "clone") {
        this.setState({ isButtonLoad: true });
        const newObj: any = {};

        if (
          record[0].hasOwnProperty("lst_updt_dt") &&
          record[0].hasOwnProperty("lst_updt_usr")
        ) {
          newObj["lst_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
          newObj["lst_updt_usr"] = userId;
        } else if (
          record[0].hasOwnProperty("ovr_last_updt_dt") &&
          record[0].hasOwnProperty("ovr_last_updt_usr")
        ) {
          newObj["ovr_last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
          newObj["ovr_last_updt_usr"] = userId;
        } else {
          newObj["last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
          newObj["last_updt_usr"] = userId;
        }
        delete recordObj[0][uniqueValue];

        const requestObj: SDMRecordPayloadModel = {
          sdm_tablename: selectedSdmTableValue.toLowerCase(),
          sdm_payload: [{ ...recordObj[0], ...newObj }],
        };
        const response = await SDMTableServices.createRecords(requestObj);

        if (response.result === "Success") {
          this.setState(
            {
              alertMsg: "Record is created successfully.",
              alertType: "success",
              openAlert: true,
              isButtonLoad: false,
            },
            () => {
              setTimeout(() => {
                this.props.history.push(`/sdm`);
              }, 1500);
            }
          );
        } else {
          this.setState({
            alertMsg: _.capitalize(
              response.result.split("CONTEXT")[0].toLowerCase()
            ),
            alertType: "error",
            openAlert: true,
            isButtonLoad: false,
          });
        }
      }
    } else {
      this.setState({
        alertMsg: "Please fill the required fields",
        alertType: "warning",
        openAlert: true,
        isButtonLoad: false,
        validation: true,
      });
    }
  };
  handleUpdateRecord = async () => {
    const {
      mode,
      userId,
      selectedSdmTableValue,
      record,
      uniqueValue,
      editFormData,
    } = this.state;

    if (this.onVerifyFields() === true) {
      this.setState({ validation: false }, async () => {
        if (mode === "edit") {
          this.setState({ isButtonLoad: true });
          const editArr = [];
          const differences: any = [];
          const sameResult = editFormData.filter(
            (val: any) =>
              val[uniqueValue.toLowerCase()] ===
              record[0][uniqueValue.toLowerCase()]
          );
          sameResult.forEach((obj1: any) => {
            for (let key in obj1) {
              if (obj1[key] !== record[0][key])
                differences.push({
                  [uniqueValue.toLowerCase()]:
                    record[0][uniqueValue.toLowerCase()],
                  [key]: record[0][key],
                });
            }
          });

          const singleEditObj = Object.assign({}, ...differences);

          if (
            record[0].hasOwnProperty("last_updt_dt") &&
            record[0].hasOwnProperty("last_updt_usr")
          ) {
            const obj = {
              last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
              last_updt_usr: userId,
            };
            editArr.push({ ...singleEditObj, ...obj });
          } else if (
            record[0].hasOwnProperty("ovr_last_updt_dt") &&
            record[0].hasOwnProperty("ovr_last_updt_usr")
          ) {
            const obj = {
              ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
              ovr_last_updt_usr: userId,
            };
            editArr.push({ ...singleEditObj, ...obj });
          } else {
            const obj = {
              lst_updt_dt:  Utils.getCurrentUTCDateTimeMonth(),
              lst_updt_usr: userId,
            };
            editArr.push({ ...singleEditObj, ...obj });
          }
          const requestObj: SDMRecordPayloadModel = {
            sdm_tablename: selectedSdmTableValue.toLowerCase(),
            sdm_payload: editArr,
          };
          const response = await SDMTableServices.updateRecords(requestObj);

          if (response.result === "Success") {
            this.setState(
              {
                alertMsg: "Record is updated successfully.",
                alertType: "success",
                openAlert: true,
                isButtonLoad: false,
              },
              () => {
                setTimeout(() => {
                  this.props.history.push(`/sdm`);
                }, 1500);
              }
            );
          } else {
            this.setState({
              alertMsg: _.capitalize(
                response.result.split("CONTEXT")[0].toLowerCase()
              ),
              alertType: "error",
              openAlert: true,
              isButtonLoad: false,
            });
          }
        }
      });
    } else {
      this.setState({
        alertMsg: "Please fill the required fields",
        alertType: "warning",
        openAlert: true,
        isButtonLoad: false,
        validation: true,
      });
    }
  };
  isSaveDisabled = () => {
    const { record, initialFormData } = this.state;

    return isEqual(initialFormData, record);
  };

  render() {
    const {
      mode,
      isButtonLoad,
      record,
      columnDetails,
      recordId,
      filterData,
      isView,
      isLoading,
      rationaleHistory,
      selectedSdmTableValue,
      validation,
    } = this.state;

    const breadCrumbs: IBreadCrumb[] = [
      {
        text: "SDM",
        link: `/sdm`,
      },
      {
        text:
          mode === "view"
            ? "View Record"
            : mode === "clone"
            ? "Clone Record"
            : mode === "edit"
            ? "Edit Record"
            : null,
      },
      {
        text: `${selectedSdmTableValue}`,
      },
    ];
    return (
      <Box mr={1} mb={5}>
        <SnackbarAlert
          alertType={this.state.alertType}
          open={this.state.openAlert}
          message={this.state.alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />

        <Grid mt={"3em"} mb={"2em"} container direction="row">
          <BreadCrumb breadcrumbs={breadCrumbs} />
        </Grid>
        {isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "40vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {record.length > 0 ? (
              <form
                noValidate
                onSubmit={(event: React.FormEvent) => {
                  event.preventDefault();
                  this.onClickAction("save");
                }}
              >
                <Box
                  mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "1em",
                      }}
                    >
                      <div
                        onClick={() => {
                          if (mode === "view") {
                            this.props.history.push(`/sdm`);
                          } else if (mode === "edit") {
                            this.props.history.push(`/sdmrecord`);
                            this.setState({ mode: "view" });
                          } else {
                            this.props.history.push(`/sdm`);
                          }
                        }}
                        style={{
                          color: "#00749E",
                          display: "flex",
                          gap: "0.5em",
                          /*  marginTop: 6, */
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#313131" }}
                          icon={faAngleLeft}
                          size="lg"
                        />
                        <Typography
                          style={{
                            color: "#00749E",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: 550,
                          }}
                        >
                          Back
                        </Typography>
                      </div>
                    </Box>
                    <Box>
                      {mode === "view" ? (
                        <PageHeader label={`Record - ${recordId}`} />
                      ) : null}
                      {mode === "edit" ? (
                        <PageHeader label={`Edit - ${recordId}`} />
                      ) : null}
                      {mode === "clone" ? (
                        <PageHeader label={`Clone `} />
                      ) : null}{" "}
                    </Box>
                  </Box>
                  {mode === "view" ? (
                    <Box style={{ display: "flex", gap: "1em" }}>
                      <RedOutlinedButton
                        startIcon={<FontAwesomeIcon icon={faClone} size="xs" />}
                        onClick={() => {
                          this.setState({ mode: "clone" });
                        }}
                        label="Clone"
                      />
                      <RedOutlinedButton
                        startIcon={
                          <FontAwesomeIcon icon={faTrashCan} size="xs" />
                        }
                        onClick={() => {
                          this.setState({ showDeleteDialoge: true });
                        }}
                        label="Delete"
                      />
                      <GradientButton
                        startIcon={
                          <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                        }
                        onClick={() => {
                          this.onClickAction("edit");
                        }}
                        label="Edit"
                      />
                    </Box>
                  ) : (
                    <Box style={{ display: "flex", gap: "1em" }}>
                      <RedOutlinedButton
                        onClick={() => {
                          this.onClickAction("cancel");
                        }}
                        label="Cancel"
                      />
                      <GradientButton
                        disabled={this.isSaveDisabled()}
                        isButtonLoad={isButtonLoad}
                        type="submit"
                        label="Submit"
                      />
                    </Box>
                  )}
                </Box>
                <Grid item container justifyContent="flex-start">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "red",

                      fontStyle: "italic",
                    }}
                  >
                    * Field Mandatory
                  </Typography>
                </Grid>
                <CardContainer
                  title={mode === "view" ? "" : undefined}
                  titleStyle={{ fontWeight: "bolder", fontSize: 20 }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      style={{ minHeight: "80vh" }}
                      ref={this.cardRef}
                      pl={7}
                      pr={2}
                      mb={5}
                      mt={5}
                    >
                      {record.map((val: any, ind: any) => {
                        return (
                          <Grid
                            key={ind}
                            container
                            columns={3}
                            columnSpacing={10}
                            rowSpacing={5}
                            display={"grid"}
                            gridTemplateColumns={"repeat(3, 32%)"}
                          >
                            {Object.keys(val).map((el, index) => {
                              const inputTypeObj = columnDetails.find(
                                (val: any) => val.fieldName === el
                              );

                              return (
                                <Grid
                                  className="ViewRecordInput"
                                  key={index}
                                  item
                                  xs={"auto"}
                                >
                                  <InputLabel
                                    key={`${el}-Label`}
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: 1,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {el}{" "}
                                    {inputTypeObj?.isRequired! === "NO" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : (
                                      <></>
                                    )}
                                  </InputLabel>

                                  {mode === "edit" || mode === "clone" ? (
                                    <>
                                      {" "}
                                      {filterData.some(
                                        (q: any) => q[el] === record[0][el]
                                      ) ? (
                                        <>
                                          {mode === "clone" ? (
                                            <TextInputField
                                              isDisabled={true}
                                              onChange={() => {}}
                                            />
                                          ) : (
                                            <Typography
                                              style={{ border: "none" }}
                                            >
                                              {this.state.record[0][el] ??
                                                "None"}
                                            </Typography>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {this.state.record[0][el] &&
                                          this.state.record[0][el].length >
                                            150 ? (
                                            <TextAreaInputField
                                              maxRows={3}
                                              isRequired={
                                                inputTypeObj?.isRequired! ===
                                                "NO"
                                                  ? true
                                                  : false
                                              }
                                              value={record[0][el]}
                                              onChange={(event) =>
                                                this.onEditInputChange(
                                                  event,
                                                  inputTypeObj?.fieldName
                                                )
                                              }
                                              width="232px"
                                            />
                                          ) : (
                                            <InputTypeSelector
                                              isDisabled={filterData.some(
                                                (q: any) =>
                                                  q[el] === record[0][el]
                                              )}
                                              type={inputTypeObj?.type!}
                                              isRequired={
                                                inputTypeObj?.isRequired! ===
                                                "NO"
                                                  ? true
                                                  : false
                                              }
                                              isRationale={
                                                el.toLowerCase() === "rationale"
                                                  ? true
                                                  : false
                                              }
                                              value={record[0][el]}
                                              onChange={(event) =>
                                                this.onEditInputChange(
                                                  event,
                                                  inputTypeObj?.fieldName
                                                )
                                              }
                                              validation={validation}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {this.state.record[0][el] ? (
                                        <>
                                          {this.state.record[0][el].length >
                                          150 ? (
                                            <Box mt={1}>
                                              <TextAreaInputField
                                                isDisabled={true}
                                                maxRows={3}
                                                onChange={() => {}}
                                                backgroundColor="#FFF"
                                                width="232px"
                                                color="#000"
                                                value={this.state.record[0][el]}
                                              />
                                            </Box>
                                          ) : (
                                            <Box
                                              sx={{
                                                border: "0.5px solid #9E9E9E",
                                                borderRadius: "6px",
                                                padding: "5px",
                                                height: "2.8rem",
                                                overflowY:
                                                  this.state.record[0][el]
                                                    .length > 25
                                                    ? "auto"
                                                    : "none",
                                                wordWrap: "break-word",
                                                hyphens: "auto",
                                                wordBreak: "break-all",
                                              }}
                                              mt={1}
                                            >
                                              <Typography
                                                style={{
                                                  border: "none",
                                                  wordWrap: "break-word",
                                                  hyphens: "auto",
                                                  wordBreak: "break-all",
                                                }}
                                              >
                                                {this.state.record[0][el] ??
                                                  "None"}
                                              </Typography>
                                            </Box>
                                          )}
                                        </>
                                      ) : (
                                        <Box
                                          sx={{
                                            border: "0.5px solid #9E9E9E",
                                            borderRadius: "6px",
                                            padding: "5px",
                                            height: "2.8rem",
                                          }}
                                          mt={1}
                                        >
                                          <Typography
                                            style={{
                                              border: "none",
                                              wordWrap: "break-word",
                                              hyphens: "auto",
                                              wordBreak: "break-all",
                                            }}
                                          >
                                            {this.state.record[0][el] ?? "None"}
                                          </Typography>
                                        </Box>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              );
                            })}
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      <CardContainer
                        title={
                          <>
                            Rationale <span style={{ color: "red" }}>*</span>
                          </>
                        }
                        titleStyle={{
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        <Divider />
                        <Grid item container pr={5} pl={2} pt={3} pb={50}>
                          <Grid item xs={12}>
                            <InputLabel
                              style={{ fontWeight: 500, marginBottom: 3 }}
                            >
                              Add Rationale
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            {" "}
                            {record.length > 0 ? (
                              <TextAreaInputField
                                isRequired={
                                  columnDetails.find(
                                    (val: any) => val.fieldName === "rationale"
                                  )?.isRequired! === "NO"
                                    ? true
                                    : false
                                }
                                isDisabled={mode === "view"}
                                id="rationale"
                                value={record[0].rationale}
                                minRows={3}
                                placeholder="Enter Rationale"
                                onChange={(event) =>
                                  this.onEditInputChange(event, "rationale")
                                }
                              />
                            ) : null}
                          </Grid>

                          {mode === "view" ? (
                            <>
                              <Grid item container mt={2}>
                                <Grid item justifyContent="flex-end">
                                  <Typography
                                    style={{
                                      color: "#0075A2",
                                      fontSize: "14px",
                                      marginTop: 2,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState({ isView: !isView })
                                    }
                                  >
                                    {isView
                                      ? "Hide Rationale"
                                      : "View Rationale"}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {isView ? (
                                    <ArrowDropDownIcon
                                      onClick={() =>
                                        this.setState({ isView: !isView })
                                      }
                                      style={{
                                        color: "#0075A2",
                                        fontSize: "1.9rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <ArrowDropUpIcon
                                      onClick={() =>
                                        this.setState({ isView: !isView })
                                      }
                                      style={{
                                        color: "#0075A2",
                                        fontSize: "1.9rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                              {isView ? (
                                <>
                                  {rationaleHistory.length > 0 ? (
                                    rationaleHistory.map(
                                      (el: any, ind: any) => {
                                        return (
                                          <Grid
                                            item
                                            container
                                            key={ind}
                                            style={{
                                              borderBottom:
                                                "1px solid #00000029",
                                            }}
                                            mt={1}
                                            mb={2}
                                          >
                                            <Grid item xs={1.5}>
                                              <AccountCircleIcon
                                                sx={{
                                                  color: "#00749E",
                                                  fontSize: "1.9rem",
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <Typography>
                                                {el.old_updated_by}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6.5}>
                                              <Typography
                                                style={{ color: "#606060" }}
                                              >
                                                {new Date(
                                                  el.old_updated_at
                                                ).toLocaleDateString()}{" "}
                                                |{" "}
                                                {new Date(
                                                  el.old_updated_at
                                                ).toLocaleTimeString()}
                                              </Typography>
                                            </Grid>

                                            <Grid item ml={4.6} mt={0.5} mb={1}>
                                              <Typography>
                                                {el.old_rationale_value}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Box ml={10} mt={4}>
                                      <NoRecords msg="No history available." />
                                    </Box>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </CardContainer>
                    </Grid>
                  </Grid>
                </CardContainer>

                <ModalDialog
                  isOpen={this.state.showDeleteDialoge}
                  title="Delete Record"
                  onClose={() => this.setState({ showDeleteDialoge: false })}
                  dialogWidth="sm"
                >
                  <Typography>Are you sure you want to delete ?</Typography>
                  <Grid item container mt={2.5}>
                    <Grid item>
                      <RedOutlinedButton
                        label="No"
                        onClick={() =>
                          this.setState({ showDeleteDialoge: false })
                        }
                      />
                    </Grid>
                    <Grid item ml={1.5}>
                      <GradientButton
                        isButtonLoad={isButtonLoad}
                        label="Yes"
                        onClick={this.handleDelete}
                      />
                    </Grid>
                  </Grid>
                </ModalDialog>
              </form>
            ) : (
              <Box mt={"15rem"}>
                <NoRecords />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
}

export default withRouter(ViewAndEditRecord);
