import {
  AlertColor,
  Box,
  Grid,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../../App.css";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import moment from "moment";
import SelectModel from "../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import MissingTransactionService from "../../../Services/MissingTransactionServices";
import DashboardService from "../../../Services/DashboardService";
import { LastLoadDataPayloadModel } from "../../../Models/DashboardModel";
import TrendingLineChart from "../../../Components/Charts/TrendingLineChart";
import PageHeader from "../../../Components/Text/PageHeader";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import Utils from "../../../Common/Utils";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import NoRecords from "../../../Components/NoRecords/NoRecords";
const MissingTransactionServices = new MissingTransactionService();
const DashboardServices = new DashboardService();
const chartCardColor = ["#4791FF", "#45BA7F", "#8B83FB", "#EF4A5F", "#F69A19"];

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  showDataTrendDialog: boolean;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  chartData: {
    color: string;
    data: number[];
    days: string[];
    marker: any;
    name: string;
    pointPlacement: string;
  }[];
  xAxisCategories: string[];
  resultArr: any;
  isSearchButtonLoading: boolean;
  isLoading: boolean;
}

class DataLoadingTrendChart extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showDataTrendDialog: false,
      selectedSourceValues: [],
      sourceMappingData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedStartDate: null,
      selectedEndDate: null,
      chartData: [],
      xAxisCategories: [],
      resultArr: [],
      isSearchButtonLoading: false,
      isLoading: false,
    };
  }
  async componentDidMount() {
    const response = await MissingTransactionServices.getSourceMappingData();
    const dataArr = response.data.data.map((el) => {
      return {
        ...el,
        searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
      };
    });

    let sourceData = dataArr
      .map((el) => {
        return {
          text: el.sourceName,
          value: el.sourceName,
        };
      })
      .filter((val) => val.text !== "Other");
    this.setState(
      {
        sourceMappingData: sourceData.sort((a, b) =>
          a.text.localeCompare(b.text)
        ),
      },
      () => {
        this.getDefaultData();
      }
    );
  }
  getDefaultData = () => {
    this.setState(
      {
        selectedSourceValues: [
          { text: "Concur", value: "Concur" },
          { text: "CPS", value: "CPS" },
          { text: "SAP", value: "SAP" },
        ],
        selectedStartDate: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.getChartData();
      }
    );
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
        selectedEndDate: null,
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate);
      const endDate = new Date(value!);
      const currentDate = new Date();
      const diffInDays = Math.abs(
        Utils.getDaysBetweenDates(endDate, startDate)
      );

      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 365",
          alertType: "error",
          selectedEndDate: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 365",
          alertType: "error",
          selectedEndDate: null,
        });
      }
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected });
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };

  getChartData = async () => {
    try {
      const { selectedSourceValues, selectedEndDate, selectedStartDate } =
        this.state;
      this.setState({ isLoading: true });
      const payloadObj: LastLoadDataPayloadModel = {
        var_spnd_src_sys_cd: `{${selectedSourceValues
          .map((el) => el.text)
          .join(",")}}`,
        var_startdate: selectedStartDate,
        var_enddate: selectedEndDate,
      };
      const response = await DashboardServices.getLastLoadData(payloadObj);
      // Sample response
      if (response.result.length > 0) {
        const min = new Date(selectedStartDate!); // start date
        const max = new Date(selectedEndDate!); // end date

        // Calculate the difference in days
        const diffDays = Math.abs(Utils.getDaysBetweenDates(max, min));
        const intervals =
          diffDays <= 12
            ? diffDays
            : diffDays <= 14
            ? 7
            : diffDays % 2 === 0
            ? 7
            : 6;
        const intervalLength = Math.ceil(diffDays / intervals);

        // Initialize an array to store the interval boundaries
        const intervalBoundaries = [];
        // Calculate the interval boundaries
        for (let i = 0; i <= diffDays; i += intervalLength) {
          const intervalStart = new Date(
            min.getTime() + i * 24 * 60 * 60 * 1000
          );
          let intervalEnd = new Date(
            intervalStart.getTime() + (intervalLength - 1) * 24 * 60 * 60 * 1000
          );
          intervalEnd = new Date(
            Math.min(intervalEnd.getTime(), max.getTime())
          ); // Ensure end date is not beyond max

          // Format the dates as "ddMMM"
          const formattedStart = intervalStart
            .toLocaleDateString("en-US", { day: "numeric", month: "short" })
            .replace(" ", "");
          const formattedEnd = intervalEnd
            .toLocaleDateString("en-US", { day: "numeric", month: "short" })
            .replace(" ", "");

          // If the date range is 12 days, format each day individually
          if (diffDays <= 12) {
            intervalBoundaries.push(formattedStart);
          } else {
            // Add the formatted date range to the array
            intervalBoundaries.push(`${formattedStart}-${formattedEnd}`);
          }
        }
        const dailyRowCountSums: any = {};
        if (diffDays <= 12) {
          for (const item of response.result) {
            // Convert the date string to a Date object
            const date = new Date(item.date);
            for (const day of intervalBoundaries) {
              const year = date.getFullYear();
              const boundaryDate = new Date(`${day}-${year}`);
              if (
                moment(date).format("YYYY-MM-DD") ===
                moment(boundaryDate).format("YYYY-MM-DD")
              ) {
                if (!dailyRowCountSums[item.spnd_src_sys_cd]) {
                  dailyRowCountSums[item.spnd_src_sys_cd] = {
                    data: [],
                    days: [],
                  };
                }
                if (
                  !dailyRowCountSums[item.spnd_src_sys_cd].days.includes(day)
                ) {
                  dailyRowCountSums[item.spnd_src_sys_cd].days.push(day);
                  dailyRowCountSums[item.spnd_src_sys_cd].data.push(
                    item.dailyrowcount
                  );
                } else {
                  const index =
                    dailyRowCountSums[item.spnd_src_sys_cd].days.indexOf(day);
                  dailyRowCountSums[item.spnd_src_sys_cd].data[index] +=
                    item.dailyrowcount;
                }
              }
            }
          }
        } else {
          for (const range of intervalBoundaries) {
            // Convert the date string to a Date object
            for (const item of response.result) {
              const date = new Date(item.date);
              const [startStr, endStr] = range.split("-");
              const startYear = moment(this.state.selectedStartDate).year();
              const endYear = moment(this.state.selectedEndDate).year();
              //const year = date.getFullYear();
              const start = new Date(`${startStr}-${startYear}`);
              const end = new Date(`${endStr}-${endYear}`);
              end.setHours(23, 59, 59);
              if (date >= start && date <= end) {
                if (!dailyRowCountSums[item.spnd_src_sys_cd]) {
                  dailyRowCountSums[item.spnd_src_sys_cd] = {
                    data: [],
                    days: [],
                  };
                }
                if (
                  !dailyRowCountSums[item.spnd_src_sys_cd].days.includes(range)
                ) {
                  dailyRowCountSums[item.spnd_src_sys_cd].days.push(range);
                  dailyRowCountSums[item.spnd_src_sys_cd].data.push(
                    item.dailyrowcount
                  );
                } else {
                  const index =
                    dailyRowCountSums[item.spnd_src_sys_cd].days.indexOf(range);
                  dailyRowCountSums[item.spnd_src_sys_cd].data[index] +=
                    item.dailyrowcount;
                }
              }
            }
          }
        }
        const transformedResponse = Object.keys(dailyRowCountSums).map(
          (key) => {
            return {
              spnd_src_sys_cd: key,
              data: dailyRowCountSums[key].data,
              days: dailyRowCountSums[key].days,
            };
          }
        );
        let groupedData: any = transformedResponse.map(
          (item: any, index: any) => {
            return {
              name: item.spnd_src_sys_cd,
              color: chartCardColor[index],
              marker: {
                symbol: "circle",
              },
              data: item.data,
              days: item.days,
              pointPlacement: "on",
            };
          }
        );

        this.setState({
          chartData: groupedData,
          xAxisCategories: groupedData[0].days,
          resultArr: response.result,
          isSearchButtonLoading: false,
          isLoading: false,
        });
      } else {
        this.setState({
          chartData: [],
          isSearchButtonLoading: false,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  isEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  isResetEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate ||
      selectedEndDate ||
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      selectedEndDate: null,
      selectedStartDate: null,
    });
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
      },
      () => {
        this.getChartData();
      }
    );
  };
  handleModalClose = () => {
    this.setState({ showDataTrendDialog: false });
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  render() {
    const {
      showDataTrendDialog,
      sourceMappingData,
      selectedSourceValues,
      selectedStartDate,
      selectedEndDate,
      alertMsg,
      alertType,
      openAlert,
      chartData,
      xAxisCategories,
      resultArr,
      isSearchButtonLoading,
      isLoading,
    } = this.state;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ overflowX: "auto" }}>
            <Grid item container>
              <Grid item xs={11}>
                <PageHeader
                  label="Data Loading Trend"
                  style={{
                    fontSize: "16px",
                    lineHeight: "1",
                  }}
                />
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="flex-end">
                <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
                  <img
                    alt="ExpandOpen"
                    src={ExpandOpen}
                    width={"14px"}
                    height={"14px"}
                    style={{
                      cursor: "pointer",
                      zIndex: "9999",
                    }}
                    onClick={() => {
                      this.setState({ showDataTrendDialog: true });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {isLoading ? (
              <Grid
                item
                style={{ height: "30em", width: "50em" }}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress
                  disableShrink
                  sx={{
                    color: "#d52b1e",
                    marginTop: 22,
                  }}
                />
              </Grid>
            ) : (
              <Grid item container>
                <Grid item>
                  {chartData.length > 0 ? (
                    <Grid item>
                      <TrendingLineChart
                        chartDataArr={chartData}
                        xAxisCategories={xAxisCategories}
                        resultArr={resultArr}
                      />
                    </Grid>
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "12em",
                        marginLeft: "20em",
                      }}
                    >
                      <NoRecords msg="No Data" />
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <ModalDialog
          isOpen={showDataTrendDialog}
          blackTitleColor
          title="Data Loading Trend"
          onClose={this.handleModalClose}
          dialogWidth="xl"
          scrollBar={showDataTrendDialog}
        >
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "0 25px 5px",
            }}
          >
            <Grid container columnGap={1} justifyContent="flex-end">
              <Grid item xs={5}>
                <InputLabel>Select Sources</InputLabel>
                <MultiAutoComplete
                  id="sourceName"
                  label="Select Sources"
                  selected={selectedSourceValues}
                  values={sourceMappingData}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={1.68}>
                <InputLabel> Select Start Date</InputLabel>
                <DatePicker
                  width="12.5rem"
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={1.68} className="EndDate">
                <InputLabel> Select End Date</InputLabel>
                <DatePicker
                  width="12.5rem"
                  disabled={!selectedStartDate}
                  name="endDate"
                  placeHolder="Select Date"
                  minDate={new Date(selectedStartDate!)}
                  maxDate={this.calculateMaxDate(selectedStartDate)}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid item xs={0.65} mt={3.2}>
                <GradientButton
                  disabled={this.isEmptyCheck()}
                  isButtonLoad={isSearchButtonLoading}
                  label="Search"
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={0.8} mt={3.2}>
                <RedButton
                  disabled={this.isResetEmptyCheck()}
                  label="Reset"
                  onClick={this.onResetClick}
                />
              </Grid>
            </Grid>
          </Grid>

          {isLoading ? (
            <Grid
              item
              style={{ height: "20em" }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{ color: "#d52b1e", marginTop: 16 }}
              />
            </Grid>
          ) : (
            <>
              {this.state.chartData.length > 0 ? (
                <TrendingLineChart
                  chartDataArr={chartData}
                  xAxisCategories={xAxisCategories}
                  resultArr={resultArr}
                />
              ) : (
                <Box
                  style={{
                    position: "sticky",
                    left: "50%",
                    marginTop: "20vh",
                    marginBottom: "20vh",
                  }}
                  width={"82px"}
                >
                  <NoRecords msg="No Data" />
                </Box>
              )}
            </>
          )}
        </ModalDialog>
      </>
    );
  }
}

export default withRouter(DataLoadingTrendChart);
