import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import CardContainer from "../../Components/Card/CardContainer";
import NoItems from "../../Components/NoItems/NoItems";
import SearchFilter from "../../Components/Search/SearchFilter";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import {
  NotificationHistoryModel,
  NotificationWidgetCountModel,
  NotificationWidgetSummaryModel,
} from "../../Models/NotificationWidgetModel";
import AuthProviderService from "../../Services/AuthProviderService";
import NotificationService from "../../Services/NotificationWidgetService";
import ChatBox from "./ChatBox";
import NoRecords from "../../Components/NoRecords/NoRecords";
import { RoleContext } from "../../Contexts";

const notificationServices = new NotificationService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  notificationData: any[];
  selectedItem: any;
  isItemSelected: boolean;
  selectedFilter: string;
  sortChange: string;
  selectedStatus: string;
  userName: string;
  isLoading: boolean;
  unreadCount: number;
  chatHistory: NotificationHistoryModel[];
  isChatBoxLoading: boolean;
  searchText: string;
  isSearchLoading: boolean;
  selectedValues: SelectModel[];
  usersData: SelectModel[];
  assignedUsers: SelectModel[];
  reassignedUserData: SelectModel[];
  notificationStatus: string;
  publicationLeadData: SelectModel[];
  tableValuesHistoryData: SelectModel[];
}

class Notifications extends Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      notificationData: [],
      selectedItem: {},
      isItemSelected: false,
      selectedFilter: "",
      sortChange: "",
      selectedStatus: "",
      userName: "",
      isLoading: false,
      unreadCount: 0,
      chatHistory: [],
      isChatBoxLoading: false,
      searchText: "",
      isSearchLoading: false,
      selectedValues: [],
      usersData: [],
      assignedUsers: [],
      reassignedUserData: [],
      notificationStatus: "",
      publicationLeadData: [],
      tableValuesHistoryData: [],
    };
  }
  async componentDidMount() {
    this.setState({ isLoading: true });

    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
    const userName = JSON.parse(decodedToken).name;

    this.setState({ userName }, async () => {
      this.handleFilterChange({ text: "All", value: "All" });
      this.getReadCount();
      this.fetchNotificationData();
      this.getUsersData();
      if (this.props.location.state) {
        if (this.props.location.state.el) {
          this.fetchNotificationDataAfterRead();
          this.getUsersData();
          this.getReadCount();
          this.getNotificationHistory(
            this.props.location.state.el.var_notification_id
          );
          this.setState({
            isItemSelected: true,
            selectedItem: this.props.location.state.el,
          });
        }

        if (
          this.props.location.state.record &&
          this.props.location.state.notificationData
        ) {
          this.setState({ isChatBoxLoading: true }, () => {
            this.fetchNotificationDataAfterRead();
            this.getUsersData();
            this.getReadCount();
            this.getNotificationHistory(this.props.location.state.record.id);
            const notificationData = this.props.location.state.notificationData;
            const notificationRecord = [
              notificationData.find(
                (el: any) =>
                  el.var_notification_id === this.props.location.state.record.id
              ),
            ].map((ele: any) => {
              return {
                var_notification_id: ele.var_notification_id,
                var_title: ele.title,
                created_datetime: ele.created_datetime,
                notification_status: ele.var_status,
              };
            });

            this.setState({
              isItemSelected: true,
              selectedItem: notificationRecord[0],
              isChatBoxLoading: false,
              notificationStatus: notificationRecord[0].notification_status,
            });
          });
        }
      }
    });
    this.setState({ isLoading: false });
  }
  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.location.state !== this.props.location.state) {
      if (this.props.location.state) {
        if (this.props.location.state.el) {
          this.fetchNotificationDataAfterRead();
          this.getUsersData();
          this.getReadCount();
          this.getNotificationHistory(
            this.props.location.state.el.var_notification_id
          );
          this.setState({
            isItemSelected: true,
            selectedItem: this.props.location.state.el,
          });
        }

        if (
          this.props.location.state.record &&
          this.props.location.state.notificationData
        ) {
          this.setState({ isChatBoxLoading: true }, () => {
            this.getUsersData();
            this.fetchNotificationDataAfterRead();
            this.getReadCount();
            this.getNotificationHistory(this.props.location.state.record.id);
            const notificationData = this.props.location.state.notificationData;
            const notificationRecord = [
              notificationData.find(
                (el: any) =>
                  el.var_notification_id === this.props.location.state.record.id
              ),
            ].map((ele: any) => {
              return {
                var_notification_id: ele.var_notification_id,
                var_title: ele.title,
                created_datetime: ele.created_datetime,
                notification_status: ele.var_status,
              };
            });

            this.setState({
              isItemSelected: true,
              selectedItem: notificationRecord[0],
              isChatBoxLoading: false,
              notificationStatus: notificationRecord[0].notification_status,
            });
          });
        }
      }
    }
  }
  getUsersData = async () => {
    try {
      const getAllUsers = await notificationServices.getAllUsersData();

      const usersData = getAllUsers.data.data.map((item: any) => {
        return {
          id: item.id,
          text: item.userName,
          value: item.userName,
          personaType: item.persona,
        };
      });
      const filteredUserData = usersData.filter((ele: any, index: any) => {
        return (
          index ===
          usersData.findIndex((val: any) => {
            return val.text === ele.text;
          })
        );
      });
      const publicationLeadData = usersData.filter(
        (el: any) => el.personaType.toLowerCase() === "publication_lead"
      );

      this.setState({ usersData: filteredUserData, publicationLeadData });
    } catch (error) {
      console.log(error);
    }
  };
  getReadCount = async () => {
    try {
      const obj: NotificationWidgetCountModel = {
        par_assigned_to: this.state.userName,
      };
      const response = await notificationServices.getNotificationsCount(obj);
      this.setState({ unreadCount: response.Unread_Notification_Count });
      sessionStorage.setItem("unreadCount", response.Unread_Notification_Count);
    } catch (error) {
      console.log(error);
    }
  };
  fetchNotificationData = async () => {
    try {
      this.setState({ isLoading: true });
      const obj: NotificationWidgetSummaryModel = {
        par_summary_type: "detailed",
        par_assigned_to: this.state.userName,
        par_whereclause: "",
        par_sortorder: "desc",
      };
      const response = await notificationServices.getNotificationWidgetSummary(
        obj
      );

      if (JSON.parse(response.result).length > 0) {
        this.setState({
          notificationData: JSON.parse(response.result),
          isLoading: false,
        });
      } else if (JSON.parse(response.result).length === 0) {
        this.setState({
          notificationData: [],
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchNotificationDataAfterRead = async (id?: any) => {
    try {
      const obj: NotificationWidgetSummaryModel = {
        par_summary_type: "detailed",
        par_assigned_to: this.state.userName,
        par_whereclause: this.createFilterString()
          ? this.createFilterString()
          : "",
        par_sortorder: "desc",
      };
      const response = await notificationServices.getNotificationWidgetSummary(
        obj
      );

      if (JSON.parse(response.result).length > 0) {
        this.getReadCount();
        if (id) {
          const selectedData = JSON.parse(response.result).find(
            (el: any) => el.var_notification_id === id
          );
          this.setState({
            selectedItem: selectedData,
            notificationData: JSON.parse(response.result),
            isLoading: false,
          });
        } else {
          this.setState({
            notificationData: JSON.parse(response.result),
            isLoading: false,
          });
        }
      }else{
        this.setState({
          notificationData:[],
          isLoading: false,
        }); 
      }
    } catch (error) {
      console.log(error);
    }
  };
  openChatBox = (el: any) => {
    this.setState(
      {
        selectedItem: el,
        isItemSelected: true,
        reassignedUserData: [],
        notificationStatus: "",
      },
      () => {
        this.fetchNotificationDataAfterRead(el.var_notification_id).then(() => {
          this.getNotificationHistory(el.var_notification_id);
        });
      }
    );
  };
  getNotificationHistory = async (notificationId: string) => {
    try {
      this.setState({ isChatBoxLoading: true });
      const obj = {
        par_notification_id: notificationId,
        par_userid: this.state.userName,
      };
      const response = await notificationServices.getNotificationsHistory(obj);
      if (response.result) {
        if (JSON.parse(response.result)[0].impacted_objects) {
          const tableValuesData = JSON.parse(
            response.result
          )[0].impacted_objects.split(",");

          const tableValuesHistoryData = tableValuesData.map((elem: any) => {
            return {
              id: elem,
              text: elem,
              value: elem,
            };
          });
          this.setState({ tableValuesHistoryData });
        }
        const assignedUsersData = JSON.parse(
          response.result
        )[0].var_agg_assigned_to.split(",");
        const assignedUsers = assignedUsersData.map((item: any) => {
          return {
            id: item,
            text: item,
            value: item,
          };
        });
        this.setState(
          {
            chatHistory: JSON.parse(response.result).sort((a: any, b: any) => {
              return (
                new Date(a.var_last_updated_datetime).getTime() -
                new Date(b.var_last_updated_datetime).getTime()
              );
            }),
            selectedValues: assignedUsers,
            isChatBoxLoading: false,
          },
          () => {
            this.fetchNotificationDataAfterRead();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleFilterChange = (selected: SelectModel) => {
    this.setState(
      {
        selectedFilter: selected.value,
      },
      () => {
        if (this.createFilterString().length > 0 && selected.value !== "All") {
          this.getSearchData(this.createFilterString());
        } else {
          this.getSearchData();
        }
      }
    );
  };
  handleSortChange = (selected: SelectModel) => {
    this.setState(
      {
        sortChange: selected.value,
      },
      () => {
        if (this.createFilterString().length > 0) {
          this.getSearchData(this.createFilterString());
        } else {
          this.getSearchData();
        }
      }
    );
  };

  getSearchData = async (filterString?: string) => {
    try {
      this.setState({ isSearchLoading: true });
      const obj: NotificationWidgetSummaryModel = {
        par_summary_type: "detailed",
        par_assigned_to: this.state.userName,
        par_whereclause: filterString ? filterString : "",
        par_sortorder:
          this.state.sortChange === "Newest"
            ? "desc"
            : this.state.sortChange === "Oldest"
            ? "asc"
            : "desc",
      };
      const response = await notificationServices.getNotificationWidgetSummary(
        obj
      );
      if (JSON.parse(response.result).length > 0) {
        this.setState({
          notificationData: JSON.parse(response.result),
          isSearchLoading: false,
        });
      } else {
        this.setState({
          notificationData: [],
          isSearchLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const searchText = event.target.value;

    this.setState(
      {
        searchText,
      },
      async () => {
        this.timer = setTimeout(async () => {
          if (this.state.searchText.length > 0) {
            this.setState({ isSearchLoading: true });
            if (this.createFilterString().length > 0) {
              this.getSearchData(this.createFilterString());
            }
          } else {
            this.getSearchData();
          }
        }, 1000);
      }
    );
  };
  createFilterString = () => {
    const { selectedFilter, searchText } = this.state;

    const filterConditions = [];

    if (selectedFilter && selectedFilter !== "All") {
      const filterValue =
        selectedFilter === "Unread"
          ? `LOWER(read_status)=LOWER(''${selectedFilter.toLowerCase()}'')`
          : `LOWER(st.notification_status)=LOWER(''${selectedFilter.toLowerCase()}'')`;
      filterConditions.push(filterValue);
    }

    if (searchText) {
      if (Number(searchText)) {
        filterConditions.push(`notification_id = ${searchText}`);
      } else {
        filterConditions.push(`lower(title) like LOWER(''%${searchText}%'')`);
      }
    }

    const nonEmptyConditions = filterConditions.filter(
      (condition) => condition.trim() !== ""
    );

    if (nonEmptyConditions.length > 0) {
      return nonEmptyConditions.join(" and ");
    }

    return "";
  };

  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId) {
      //const values = selected.map((p) => p.value as string);
      this.setState({ selectedValues: selected });
    }
  };

  handleReassignMultiSelectChange = (
    selected: SelectModel[],
    targetId?: string
  ) => {
    if (targetId) {
      //const values = selected.map((p) => p.value as string);
      this.setState({ reassignedUserData: selected });
    }
  };
  handleChatBoxOpen = () => {
    this.setState({ isItemSelected: false }, () => {
      this.fetchNotificationData();
    });
  };
  clearReassignedUserData = () => {
    this.setState({ reassignedUserData: [] });
  };
  render() {
    const {
      notificationData,
      selectedFilter,
      sortChange,
      isLoading,
      unreadCount,
      selectedItem,
      chatHistory,
      isChatBoxLoading,
      searchText,
      isSearchLoading,
      userName,
      usersData,
      selectedValues,
      isItemSelected,
      reassignedUserData,
      notificationStatus,
      publicationLeadData,
      tableValuesHistoryData,
    } = this.state;
    return (
      <>
        {isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <Box pb={4}>
            <Box
              style={{
                display: "flex",
                padding: "25px 10px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "1.5em",
                }}
              >
                Notifications{" "}
              </Typography>
              <Box
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#D52B1E",
                  color: "white",
                  marginLeft: "1em",
                  padding: "0.1em",
                  width: "1.35em",
                  height: "1.35em",
                  alignItems: "center",
                  alignContent: "right",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "9px",
                }}
              >
                <Box style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {unreadCount > 99 ? "99+" : unreadCount}
                </Box>
              </Box>
            </Box>
            <CardContainer>
              <Grid container>
                <Grid item xs={5} borderRight={"1px solid #D8D8D8"}>
                  <Grid container p={2} flexDirection="row">
                    <Grid container item columnSpacing={2}>
                      <Grid
                        item
                        xs={6.5}
                        className="SearchNameInput NotificationSearch"
                      >
                        <InputLabel>
                          Search by Title or Notification ID
                        </InputLabel>
                        <SearchFilter
                          placeholder="Search by Title or Notification ID"
                          value={searchText}
                          onChange={this.handleSearchChange}
                        />
                      </Grid>
                      <Grid item xs={2.7}>
                        <InputLabel> Filter By</InputLabel>
                        <SingleSelect
                          id="filter"
                          customOptions="None"
                          defaultValue="All"
                          value={selectedFilter}
                          values={[
                            { text: "All", value: "All" },
                            { text: "Unread", value: "Unread" },
                            { text: "Open", value: "Open" },
                            { text: "In Progress", value: "In Progress" },
                            { text: "Rejected", value: "Rejected" },
                            { text: "On Hold", value: "On Hold" },
                          ]}
                          onChange={this.handleFilterChange}
                        />
                      </Grid>
                      <Grid item xs={2.7}>
                        <InputLabel> Sort By</InputLabel>
                        <SingleSelect
                          customOptions="None"
                          id="sortChange"
                          value={sortChange}
                          values={[
                            { text: "Newest", value: "Newest" },
                            { text: "Oldest", value: "Oldest" },
                          ]}
                          onChange={this.handleSortChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    style={{
                      overflowY: "auto",
                      maxHeight: "749px",
                      overflowX: "hidden",
                      borderTop: "0.5px solid #D8D8D8",
                      marginTop: "5px",
                    }}
                  >
                    {isSearchLoading ? (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20vh",
                          marginBottom: "20vh",
                          overflow: "hidden",
                        }}
                      >
                        <CircularProgress sx={{ color: "#d52b1e" }} />
                      </Box>
                    ) : (
                      <>
                        {notificationData.length > 0 ? (
                          <>
                            {notificationData.map((el: any, index: number) => (
                              <React.Fragment key={index}>
                                <MenuItem
                                  key={index}
                                  onClick={() => {
                                    this.openChatBox(el);
                                  }}
                                  style={{
                                    backgroundColor:
                                      el.var_read_status.toLowerCase() ===
                                      "unread"
                                        ? "#E4EAF0"
                                        : "#CCD5DD",
                                    borderImage:
                                      el.var_read_status.toLowerCase() ===
                                      "unread"
                                        ? "linear-gradient(to bottom, #263F6A,  #890756) 0 0 0 1"
                                        : "none",
                                    borderWidth:
                                      el.var_read_status.toLowerCase() ===
                                      "unread"
                                        ? "5px"
                                        : "0px",
                                    borderStyle:
                                      el.var_read_status.toLowerCase() ===
                                      "unread"
                                        ? "solid"
                                        : "none",
                                    fontWeight:
                                      el.var_read_status.toLowerCase() ===
                                      "unread"
                                        ? "bold"
                                        : "normal",
                                    color: "black",
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Box style={{ display: "flex" }}>
                                    <AccountCircleIcon
                                      sx={{
                                        width: "2rem",
                                        height: "2rem",
                                        marginRight: "0.5em",
                                      }}
                                      style={{
                                        color: "#7A8FB2",
                                      }}
                                    />
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "30rem",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          wordBreak: "break-all",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        ID_{el.var_notification_id}{" "}
                                        {el.var_title}
                                      </Box>
                                      <Box
                                        style={{
                                          color: "#575757",
                                          fontWeight: 400,
                                          fontSize: "13px",
                                        }}
                                      >
                                        {Utils.getFormattedDateTime(
                                          el.last_updt_ime
                                        )}
                                      </Box>
                                      <Box
                                        position={"absolute"}
                                        right={"20px"}
                                        top={"25px"}
                                      >
                                        <CircleIcon
                                          style={{
                                            fontSize: "13px",
                                            color:
                                              el.notification_status.toLowerCase() ===
                                              "open"
                                                ? "#50AF7F"
                                                : el.notification_status.toLowerCase() ===
                                                  "in progress"
                                                ? "#F08508"
                                                : el.notification_status.toLowerCase() ===
                                                  "closed"
                                                ? "#868686"
                                                : el.notification_status.toLowerCase() ===
                                                  "rejected"
                                                ? "#F44E4E"
                                                : el.notification_status.toLowerCase() ===
                                                  "review requested"
                                                ? "#08E7F0"
                                                : el.notification_status.toLowerCase() ===
                                                  "review completed"
                                                ? "#50AF7F"
                                                : el.notification_status.toLowerCase() ===
                                                  "on hold"
                                                ? "#9D24F5"
                                                : "",
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </MenuItem>
                                <Divider
                                  variant="middle"
                                  sx={{
                                    "&.MuiDivider-root": {
                                      marginTop: 0,
                                      marginBottom: 0,
                                    },
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <Box style={{ margin: "20vh" }}>
                            <NoRecords />
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  {isItemSelected ? (
                    <ChatBox
                      tableValuesHistoryData={tableValuesHistoryData}
                      clearReassignedUserData={this.clearReassignedUserData}
                      notificationStatus={notificationStatus}
                      handleReassignMultiSelectChange={
                        this.handleReassignMultiSelectChange
                      }
                      reassignedUserData={reassignedUserData}
                      openChatBox={this.openChatBox}
                      handleMultiSelectChange={this.handleMultiSelectChange}
                      selectedValues={selectedValues}
                      usersData={usersData}
                      userName={userName}
                      isChatBoxLoading={isChatBoxLoading}
                      chatHistoryData={chatHistory}
                      title={selectedItem ? selectedItem.var_title : ""}
                      item={selectedItem}
                      handleChatBoxOpen={this.handleChatBoxOpen}
                      publicationLeadData={publicationLeadData}
                      notificationData={notificationData}
                    />
                  ) : (
                    <>
                      <Box
                        style={{
                          position: "sticky",
                          left: "50%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10.2vh",
                          marginBottom: "10.2vh",
                          paddingTop: "20vh",
                          borderTop: "0.5px solid #D8D8D8",
                        }}
                      >
                        <NoItems msg="No Items to Display" />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContainer>
          </Box>
        )}
      </>
    );
  }
}
export default withRouter(Notifications);
