import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Grid,
  InputLabel,
  Link,
  Popover,
  CardContent,
  Card,
} from "@mui/material";
import React, { Component } from "react";
import "../../../App.css";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import RedOutlinedButton from "../../../Components/Button/RedOutlinedButton";
import CardContainer from "../../../Components/Card/CardContainer";
import PageHeader from "../../../Components/Text/PageHeader";
import TextInputField from "../../../Components/TextInputField/TextInputField";
//import TableData from "./MockData/CustomerData.json";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchDataLoader from "../../../Components/Table/SearchDataLoader";
import { ClinicalTrialFilterModel } from "../../../Models/ClinicalTrialSearch";
import SearchService from "../../../Services/SearchService";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExcelService from "../../../Services/ExcelService";
const SearchServices = new SearchService();
type Props = {};

type State = {
  page: number;
  rows: number;
  totalRecordsCount: number;
  trialDataLoading: boolean;
  showSearchContent: boolean;
  tblData: any[];
  clinicalTrialSearchResultContent: boolean;
  isOpenAdvancedSearch: boolean;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  clinicalTrialSearchFields: ClinicalTrialFilterModel;
  openAlert: boolean;
  alertMsg: string;
  clinicalTrialSearchValue: string;
};

class ClinicalTrialSearchTab extends Component<Props, State> {
  state = {
    page: 0,
    rows: 5,
    totalRecordsCount: 0,
    showSearchContent: false,
    trialDataLoading: false,
    tblData: [],
    clinicalTrialSearchResultContent: false,
    isOpenAdvancedSearch: false,
    isPaginationDisabled: false,
    isPaginationReset: false,
    clinicalTrialSearchFields: {} as ClinicalTrialFilterModel,
    openAlert: false,
    alertMsg: "",
    clinicalTrialSearchValue: "Clinical_Trial",
  };
  showClinicalTrialFormContent = () => {
    this.setState({
      showSearchContent: true,
    });
  };
  onClinicalTrialSearchClick = async () => {
    try {
      const { clinicalTrialSearchFields, page, rows } = this.state;
      this.setState({ trialDataLoading: true });
      let clinicalTrialSearchFieldsCopy: any = { ...clinicalTrialSearchFields };
      for (var key in clinicalTrialSearchFields) {
        if (clinicalTrialSearchFieldsCopy[key] === "") {
          delete clinicalTrialSearchFieldsCopy[key];
        }
      }
      const response = await SearchServices.clinicalTrialSearch(
        clinicalTrialSearchFieldsCopy,
        page,
        rows
      );
      if (Object.keys(response.data.data).length > 0) {
        if (response.data.data.totalRecords === 0) {
          this.setState({
            page: 0,
            rows: 5,
            isPaginationReset: true,
          });
        }
        this.setState({
          clinicalTrialSearchResultContent: true,
          tblData: response.data.data.trialData,
          totalRecordsCount: response.data.data.totalRecords,
          trialDataLoading: false,
          isOpenAdvancedSearch: false,
        });
      } else {
        this.setState({
          trialDataLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          isPaginationReset: true,
          page: 0,
          rows: 5,
        });
      }
    } catch (error) {
      this.setState({
        trialDataLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        isPaginationReset: true,
        page: 0,
        rows: 5,
      });
    }
  };
  onClickClinicalTrialSearchbtn = () => {
    this.setState({
      clinicalTrialSearchResultContent: true,
    });
  };
  handleAdvancedSearchLinkClick = () => {
    this.setState({ isOpenAdvancedSearch: !this.state.isOpenAdvancedSearch });
  };
  isEmptyCheck = () => {
    return (
      Object.values(this.state.clinicalTrialSearchFields).filter(
        (val) => val.length > 0
      ).length <= 0
    );
  };
  formReset = (page?: string) => {
    if (page === "searchTablePage") {
      this.setState({
        clinicalTrialSearchFields: {} as ClinicalTrialFilterModel,
        isPaginationReset: true,
        page: 0,
        rows: 5,
        tblData: [],
      });
    } else {
      this.setState({
        clinicalTrialSearchFields: {} as ClinicalTrialFilterModel,
      });
    }
  };
  onClinicalTrialSearchCustomerInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      clinicalTrialSearchFields: {
        ...this.state.clinicalTrialSearchFields,
        [event.target.name]: event.target.value,
      },
    });
  };

  onChangePage = (page: number) => {
    this.setState({ page }, () => {
      this.onClinicalTrialSearchClick();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.onClinicalTrialSearchClick();
    });
  };
  handleExcelDownload = async (event: any) => {
    const { tblData } = this.state;
    const trialResulData: any = [];
    const tableHeaders: any = Object.keys(tblData[0]);
    const newTableData = tblData.map((elem: any) => {
      const newObj = { ...elem };
      delete newObj.id;
      return newObj;
    });
    newTableData.forEach((obj) => {
      const allValues = Object.values(obj);
      trialResulData.push(allValues);
    });

    ExcelService.CreateDataFile(
      this.state.clinicalTrialSearchValue,
      trialResulData,
      tableHeaders.filter((el: any) => el !== "id")
    );
  };
  render() {
    const {
      showSearchContent,
      clinicalTrialSearchResultContent,
      isPaginationDisabled,
      isPaginationReset,
      clinicalTrialSearchFields,
      trialDataLoading,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          open={this.state.openAlert}
          alertType="error"
          message={this.state.alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        {clinicalTrialSearchResultContent && (
          <Box
            style={{
              display: "flex",
              gap: "1em",
              position: "absolute",
              top: "86px",
              right: "25px",
            }}
          >
            <RedOutlinedButton
              label="Download Excel"
              onClick={this.handleExcelDownload}
              startIcon={<ArrowDownwardIcon />}
            />
          </Box>
        )}

        {!clinicalTrialSearchResultContent ? (
          <Box mr={10} ml={10} mt={10} className="SearchContent">
            <PageHeader
              label="Clinical Trial Search"
              style={{ color: "#ffffff", fontWeight: "normal" }}
            />

            <form>
              <Grid
                container
                columns={6}
                mt={3}
                columnSpacing={3}
                mb={4}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.MedLicenseComment
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Customer ID
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Customer ID"
                    name="MedLicenseComment"
                    id="MedLicenseComment"
                    value={clinicalTrialSearchFields.MedLicenseComment}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>

                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.StudyAlsCd
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {" "}
                    Trial Alias
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Trial Alias"
                    name="StudyAlsCd"
                    id="StudyAlsCd"
                    value={clinicalTrialSearchFields.StudyAlsCd}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>
                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.TrialUnitReference
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Trial Site
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Trial Site"
                    name="TrialUnitReference"
                    id="TrialUnitReference"
                    value={clinicalTrialSearchFields.TrialUnitReference}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>
                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.CplName
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Principal Investigator
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Principal Investigator"
                    name="CplName"
                    id="CplName"
                    value={clinicalTrialSearchFields.CplName}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>

                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.CentreName
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Center Name
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Center Name"
                    name="CentreName"
                    id="CentreName"
                    value={clinicalTrialSearchFields.CentreName}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>
                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: clinicalTrialSearchFields.AddressLine1
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Center Address
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Center Address"
                    name="AddressLine1"
                    id="AddressLine1"
                    value={clinicalTrialSearchFields.AddressLine1}
                    onChange={this.onClinicalTrialSearchCustomerInputChange}
                  />
                </Grid>
                {!showSearchContent && (
                  <Grid item xs={6} justifyContent={"flex-end"} mb={4}>
                    <Box
                      style={{ justifyContent: "flex-end", display: "flex" }}
                    >
                      <RedOutlinedButton
                        label="Advanced Search"
                        onClick={this.showClinicalTrialFormContent}
                        startIcon={<FilterListIcon />}
                      />
                    </Box>
                  </Grid>
                )}
                {showSearchContent && (
                  <>
                    <Grid item xs={2} mb={4}></Grid>
                    <Grid item xs={2} mb={4}>
                      <InputLabel
                        sx={{
                          visibility: clinicalTrialSearchFields.TownCity
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        Center City
                      </InputLabel>
                      <TextInputField
                        placeholder="Enter Center City"
                        name="TownCity"
                        id="TownCity"
                        value={clinicalTrialSearchFields.TownCity}
                        onChange={this.onClinicalTrialSearchCustomerInputChange}
                      />
                    </Grid>
                    <Grid item xs={2} mb={4}></Grid>
                  </>
                )}
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item mt={2.9}>
                    <RedButton
                      disabled={this.isEmptyCheck()}
                      label="Reset"
                      onClick={() => this.formReset()}
                    />
                  </Grid>
                  <Grid item mt={2.9}>
                    <GradientButton
                      isButtonLoad={trialDataLoading}
                      label="Search"
                      disabled={this.isEmptyCheck()}
                      onClick={this.onClinicalTrialSearchClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        ) : (
          <>
            <Box p={0} className={this.state.page > 0 ? "SearchTablePage" : ""}>
              <CardContainer>
                <form>
                  <Grid container p={2} flexDirection="row">
                    <Grid container item columnSpacing={2}>
                      <Grid item xs={2}>
                        <InputLabel
                          sx={{
                            visibility:
                              clinicalTrialSearchFields.MedLicenseComment
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          Customer ID
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter Customer ID"
                          name="MedLicenseComment"
                          id="MedLicenseComment"
                          value={clinicalTrialSearchFields.MedLicenseComment}
                          onChange={
                            this.onClinicalTrialSearchCustomerInputChange
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputLabel
                          sx={{
                            visibility: clinicalTrialSearchFields.StudyAlsCd
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          Trial Alias
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter Trial Alias"
                          name="StudyAlsCd"
                          id="StudyAlsCd"
                          value={clinicalTrialSearchFields.StudyAlsCd}
                          onChange={
                            this.onClinicalTrialSearchCustomerInputChange
                          }
                        />
                      </Grid>
                      <Grid item xs={2} mb={4}>
                        <InputLabel
                          sx={{
                            visibility:
                              clinicalTrialSearchFields.TrialUnitReference
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          Trial Site
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter Trial Site"
                          name="TrialUnitReference"
                          id="TrialUnitReference"
                          value={clinicalTrialSearchFields.TrialUnitReference}
                          onChange={
                            this.onClinicalTrialSearchCustomerInputChange
                          }
                        />
                      </Grid>
                      <Grid item xs={2.26} mb={4}>
                        <InputLabel
                          sx={{
                            visibility: clinicalTrialSearchFields.CplName
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          Principal Investigator
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter Principal Investigator"
                          name="CplName"
                          id="CplName"
                          value={clinicalTrialSearchFields.CplName}
                          onChange={
                            this.onClinicalTrialSearchCustomerInputChange
                          }
                        />
                      </Grid>

                      <Grid item xs={1.9} mt={2} ml={1.7}>
                        <Link
                          style={{
                            color: "#0577A3",
                            cursor: "pointer",
                            textDecoration: "none",
                            marginTop: "10px",
                            display: "block",
                          }}
                          onClick={this.handleAdvancedSearchLinkClick}
                        >
                          <FilterListIcon
                            style={{
                              marginTop: "1px",
                              float: "left",
                              marginRight: "3px",
                            }}
                          />
                          Advanced Search
                        </Link>
                        <Grid></Grid>
                        <Popover
                          open={this.state.isOpenAdvancedSearch}
                          anchorReference="anchorPosition"
                          anchorPosition={{ top: 150, left: 835 }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiPopover-paper": {
                              width: 700,
                              position: "absolute",
                              top: "305px!important",
                            },
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Grid container>
                              <Grid item xs={6} md={8}>
                                <PageHeader label="Advanced Search" />
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                style={{ textAlign: "right" }}
                              >
                                <FontAwesomeIcon
                                  size="2x"
                                  onClick={this.handleAdvancedSearchLinkClick}
                                  icon={faXmark}
                                />
                              </Grid>
                            </Grid>
                            <hr style={{ border: "1px solid #D1D1D1" }} />
                            <form>
                              <Grid
                                container
                                mt={3}
                                columns={4}
                                style={{ justifyContent: "space-around" }}
                                alignItems={"center"}
                                className={
                                  this.state.page > 0 ? "SearchTablePage" : ""
                                }
                              >
                                <Grid item xs={1.9} mb={4}>
                                  <InputLabel
                                    sx={{
                                      visibility:
                                        clinicalTrialSearchFields.CentreName
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  >
                                    Center Name
                                  </InputLabel>
                                  <TextInputField
                                    isReadonly={this.state.page > 0}
                                    placeholder="Enter Center Name"
                                    name="CentreName"
                                    id="CentreName"
                                    value={clinicalTrialSearchFields.CentreName}
                                    onChange={
                                      this
                                        .onClinicalTrialSearchCustomerInputChange
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1.9} mb={4}>
                                  <InputLabel
                                    sx={{
                                      visibility:
                                        clinicalTrialSearchFields.AddressLine1
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  >
                                    Center Address
                                  </InputLabel>
                                  <TextInputField
                                    isReadonly={this.state.page > 0}
                                    placeholder="Enter Center Address"
                                    name="AddressLine1"
                                    id="AddressLine1"
                                    value={
                                      clinicalTrialSearchFields.AddressLine1
                                    }
                                    onChange={
                                      this
                                        .onClinicalTrialSearchCustomerInputChange
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1.9} mb={4}>
                                  <InputLabel
                                    sx={{
                                      visibility:
                                        clinicalTrialSearchFields.TownCity
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  >
                                    Center City
                                  </InputLabel>
                                  <TextInputField
                                    isReadonly={this.state.page > 0}
                                    placeholder="Enter Center City"
                                    name="TownCity"
                                    id="TownCity"
                                    value={clinicalTrialSearchFields.TownCity}
                                    onChange={
                                      this
                                        .onClinicalTrialSearchCustomerInputChange
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1.9} mb={4}>
                                  <Grid
                                    container
                                    columnGap={1.5}
                                    justifyContent={"flex-end"}
                                    mt={2}
                                  >
                                    <RedButton
                                      label="Reset"
                                      disabled={this.isEmptyCheck()}
                                      onClick={() =>
                                        this.formReset("searchTablePage")
                                      }
                                    />

                                    <GradientButton
                                      isButtonLoad={trialDataLoading}
                                      disabled={
                                        this.isEmptyCheck() ||
                                        this.state.page > 0
                                      }
                                      label="Search"
                                      onClick={this.onClinicalTrialSearchClick}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </form>
                          </Box>
                        </Popover>
                      </Grid>
                      <Grid item mt={3}>
                        <RedButton
                          disabled={this.isEmptyCheck()}
                          label="Reset"
                          onClick={() => this.formReset("searchTablePage")}
                        />
                      </Grid>
                      <Grid item mt={3}>
                        <GradientButton
                          isButtonLoad={trialDataLoading}
                          disabled={this.isEmptyCheck() || this.state.page > 0}
                          label="Search"
                          onClick={this.onClinicalTrialSearchClick}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      border: "0.5px solid #D1D1D1",
                      opacity: "0.5",
                      marginLeft: "15px",
                      marginRight: "15px",
                    }}
                  />
                  <Card>
                    <CardContent>
                      <SearchDataLoader
                        tableData={this.state.tblData}
                        isPaginationReset={isPaginationReset}
                        isPaginationDisabled={isPaginationDisabled}
                        isLoading={this.state.trialDataLoading}
                        totalRecordsCount={this.state.totalRecordsCount}
                        onChangePage={this.onChangePage}
                        onChangeRow={this.onChangeRow}
                        sortingArrow={true}
                      />
                    </CardContent>
                  </Card>
                </form>
              </CardContainer>
            </Box>
          </>
        )}
      </>
    );
  }
}
export default ClinicalTrialSearchTab;
