import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { index } from "d3";
import React, { Component, SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CpsMenuList,
  monitoringRptSummaryData,
  reportMenuList,
} from "../../Common/Constants";
import { CustomEventType } from "../../Common/Enums";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SearchDataLoader from "../../Components/Table/SearchDataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import { OnDemandSelectionModel } from "../../Models/OnDemandReportModels";
import ExcelService from "../../Services/ExcelService";
import MonitoringService from "../../Services/MonitoringServices";
import OnDemandReportService from "../../Services/OnDemandReportServices";
const OnDemandReportServices = new OnDemandReportService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  showProceedContent: boolean;
  expanded: any;
  tblData: any[];
  responseData: any[];
  summaryData: any[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset: boolean;
  page: number;
  rows: number;
  totalRecordsCount: number;
  selectedYear: string;
  selectedReport: string;
  isLoading: boolean;
  yearData: SelectModel[];
  reportVal: string | undefined;
  headerData: any[];
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <KeyboardArrowDownIcon
        sx={{ fontSize: "0.9rem" }}
        style={{
          color: "#ffffff",
          fontSize: "20px",
          background: "linear-gradient(to right, #D52B1E,#78105B)",
          borderRadius: "50%",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const colorCodes = [
  "#E2948Eff",
  "#E07F78ff",
  "#DD6A61ff",
  "#DA554Bff",
  "#D84034",
  "#D52B1E",
];

const MonitoringServices = new MonitoringService();
class Monitoring extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showProceedContent: false,
      expanded: false,
      tblData: [],
      responseData: [],
      summaryData: monitoringRptSummaryData,
      isPaginationDisabled: false,
      isPaginationReset: false,
      isEmptyReset: false,
      page: 1,
      rows: 5,
      totalRecordsCount: 0,
      selectedYear: "",
      selectedReport: "State-CA",
      isLoading: false,
      yearData: [],
      reportVal: "State-CA",
      headerData: [],
    };
    document.addEventListener(
      CustomEventType[CustomEventType.ChangeMonitorTable],
      async (value: any) => {
        try {
          this.setState({ isLoading: true }, () => {
            this.getYearSelectionData().then(() => {
              const reportName = this.getReportName(value.detail.item);
              this.handleReportSelectChange(reportName);
              this.setState({ reportVal: reportName?.name });
            });
          });
        } catch (error) {
          console.error(error);
        }
      }
    );
  }
  async componentDidMount() {
    this.setState({ isLoading: true }, () =>
      this.getYearSelectionData().then(() => this.getTableData())
    );
  }
  getReportName = (value: any) => {
    if (value.name.includes("CPS")) {
      return CpsMenuList.find((el: any) => el.name === value.name);
    } else {
      return reportMenuList.find((el: any) => el.name === value.name);
    }
  };
  getYearSelectionData = async () => {
    try {
      const response = await OnDemandReportServices.getSelectionData();

      let yearData = [
        ...new Set(
          response.data.result.map((el: OnDemandSelectionModel) => el.year)
        ),
      ].map((elem) => {
        return {
          text: elem,
          value: elem,
        };
      });

      yearData = yearData.sort((a: any, b: any) => a.value - b.value);

      const lastYear = yearData[yearData.length - 1];

      this.setState({ yearData, selectedYear: lastYear.value });
    } catch (error) {
      console.log(error);
    }
  };

  getTableData = () => {
    this.setState({ isLoading: true, expanded: false }, async () => {
      const { selectedYear, selectedReport, headerData } = this.state;
      const obj = monitoringRptSummaryData.filter(
        (el: any) => el.key === selectedReport
      )[0];
      try {
        let newHeaderData: any = headerData;
        if (
          headerData.length === 0 ||
          this.state.selectedReport !== selectedReport
        ) {
          newHeaderData = await MonitoringServices.getHeaderData({
            par_rpt_type: selectedReport.toLowerCase(),
          });
          this.setState({ headerData: newHeaderData });
        }
        const payloadObj = {
          par_rpt_type: selectedReport.toLowerCase(),
          par_year: selectedYear,
          pageno: this.state.page,
          rows_per_page: this.state.rows,
        };
        const tableData = await MonitoringServices.getTableData(payloadObj);

        let mergedArr;
        if (newHeaderData.result.length > 0 && tableData.result.length > 0) {
          mergedArr = tableData.result.map((entry: { [x: string]: any }) => {
            const mergedEntry: any = {};
            newHeaderData.result.forEach(
              (column: { columnname: any; param: any }) => {
                mergedEntry[column.columnname] = entry[column.param];
              }
            );

            return mergedEntry;
          });
        } else {
          mergedArr = tableData.result;
        }

        if (selectedReport !== ("State-VT" || "State-NV" || "State-OR")) {
          mergedArr = this.assignColorsToData(mergedArr, colorCodes, {
            start: obj.start,
            end: obj.end,
          });
        }
        this.setState({
          tblData: mergedArr,
          isLoading: false,
          responseData: mergedArr,
          totalRecordsCount: tableData.result[0].total_records,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          tblData: [],
          responseData: [],
          isLoading: false,
          totalRecordsCount: 0,
          isPaginationReset: !this.state.isPaginationReset,
          rows: 5,
          page: 1,
        });
      }
    });
  };

  handleYearSelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState(
      {
        selectedYear: selected.value,
        page: 1,
        rows: 5,
        isPaginationReset: !this.state.isPaginationReset,
      },
      () => this.getTableData()
    );
  };
  handleReportSelectChange = (value: any) => {
    this.setState(
      {
        reportVal: value.name,
        selectedReport: value.as,
        rows: 5,
        page: 1,
        isPaginationReset: !this.state.isPaginationReset,
        headerData: [],
      },

      () => this.getTableData()
    );
  };

  handleExpandChange =
    (record: any) => (event: SyntheticEvent, isExpanded: boolean) => {
      this.setState({ expanded: isExpanded ? record : false });
    };
  handleExcelDownload = async (event: any) => {
    const { responseData } = this.state;
    const monitoringResulData: any = [];
    const tableHeaders: any = Object.keys(responseData[0]);

    const keysToRemove = ["color", "Statecol"];

    const newTableData = responseData.map((obj) => {
      const newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (!keysToRemove.includes(key)) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    });

    newTableData.forEach((obj) => {
      const allValues = Object.values(obj);
      monitoringResulData.push(allValues);
    });
    ExcelService.CreateDataFile(
      `${this.state.selectedReport}-${this.state.selectedYear}`,
      monitoringResulData,
      tableHeaders.filter(
        (el: any) => el !== "id" && el !== "Statecol" && el !== "color"
      )
    );
  };
  onChangePage = (page: number) => {
    this.setState({ page: page }, () => {
      this.getTableData();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.setState(
        {
          page: this.state.page > 1 ? 1 : this.state.page,
          isEmptyReset: !this.state.isEmptyReset,
        },
        () => {
          this.getTableData();
        }
      );
    });
  };
  assignColorsToData = (
    data: any[],
    colorCodes: string | any[],
    inputRange: { start: any; end: any }
  ) => {
    const { start, end } = inputRange;
    const median = Math.round((end - start) / 2) + start;
    const range = end - start;
    // Define custom rpt_spnd_amt ranges
    const customRanges = [
      { start, end: start + range / 5 },
      { start: start + range / 5, end: start + (range * 2) / 5 },
      { start: start + (range * 2) / 5, end: start + (range * 3) / 5 },
      { start: start + (range * 3) / 5, end: start + (range * 4) / 5 },
      { start: start + (range * 4) / 5, end },
    ];
    // Process each data entry
    const result = data.map((entry) => {
      const { rpt_spnd_amt } = entry;
      let assignedColor =
        rpt_spnd_amt < start ? "white" : colorCodes[colorCodes.length - 1]; // Default color

      // Check if rpt_spnd_amt falls into any custom range
      for (let i = 0; i < customRanges.length; i++) {
        const { start: rangeStart, end: rangeEnd } = customRanges[i];
        if (rpt_spnd_amt >= rangeStart && rpt_spnd_amt < rangeEnd) {
          assignedColor = colorCodes[i];
          break; // Exit loop once a range is found
        }
      }

      // Add the assigned color to the entry
      return { ...entry, color: assignedColor, Statecol: median };
    });
    result.sort((a, b) => b.rpt_spnd_amt - a.rpt_spnd_amt);
    return result;
  };

  render() {
    const {
      tblData,
      summaryData,
      isPaginationDisabled,
      isPaginationReset,
      isEmptyReset,
      isLoading,
      totalRecordsCount,
      selectedYear,
      selectedReport,
      yearData,
      expanded,
      reportVal,
    } = this.state;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "25px 10px",
          }}
        >
          <PageHeader
            label={`Monitoring - ${reportVal}`}
            style={{
              fontSize: "22px",
            }}
          />

          <Box style={{ display: "flex", gap: "1em" }}>
            <Grid container columnGap={1.5} justifyContent={"flex-end"}>
              <Grid item className="ContentWidth">
                <SingleSelect
                  size="small"
                  id="selectedYear"
                  customOptions="Select Year"
                  noneValue={true}
                  value={selectedYear}
                  values={yearData}
                  onChange={this.handleYearSelectChange}
                />
              </Grid>
              <Grid item>
                <RedOutlinedButton
                  disabled={tblData.length > 0 ? false : true}
                  label="Download Excel"
                  onClick={this.handleExcelDownload}
                  startIcon={<ArrowDownwardIcon />}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <>
          <Box pb={3}>
            <Accordion
              expanded={expanded}
              className="MonitoringCollapse"
              onChange={this.handleExpandChange(index)}
              style={{
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "6px 6px 0 0",
                opacity: 1,
                borderTop: "0",
              }}
            >
              <AccordionSummary
                style={{
                  fontWeight: "bold",
                }}
                sx={{
                  borderImage: "linear-gradient(to right, #D22921,  #8A0756) 1",
                  borderTop: "4px solid",
                }}
              >
                <Box>SUMMARY</Box>
              </AccordionSummary>
              <Divider />
              <AccordionDetails
                style={{
                  borderTop: "0",
                }}
              >
                {summaryData.map((el: any, ind: any) => {
                  if (el.key === selectedReport) {
                    let parser = new DOMParser();
                    let doc = parser.parseFromString(el.summary, "text/html");
                    let pText = doc.querySelector("p")?.textContent;
                    let liTexts = Array.from(doc.querySelectorAll("li")).map(
                      (li) => li.textContent
                    );
                    return (
                      <React.Fragment key={ind}>
                        <Typography style={{ margin: "10px 0" }}>
                          {pText}
                        </Typography>
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          {liTexts.map((text, index) => (
                            <li key={index}>
                              <Typography>{text}</Typography>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </AccordionDetails>
            </Accordion>

            <Card className="MonitoringTableContainer">
              <CardContent>
                <SearchDataLoader
                  tableData={tblData}
                  isPaginationReset={isPaginationReset}
                  isPaginationDisabled={isPaginationDisabled}
                  isLoading={isLoading}
                  isEmptyReset={isEmptyReset}
                  totalRecordsCount={totalRecordsCount}
                  onChangePage={this.onChangePage}
                  onChangeRow={this.onChangeRow}
                  sortingArrow={false}
                />
              </CardContent>
            </Card>
          </Box>
        </>
      </>
    );
  }
}
export default withRouter(Monitoring);
