import React, { Component } from "react";
import ChartPerCountry from "../../../../Components/Charts/ChartPerCountry";
import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import MultiAutoComplete from "../../../../Components/Select/MultiAutoComplete";
import DatePicker from "../../../../Components/DatePicker/DatePicker";
import SelectModel from "../../../../Components/Select/SelectModel";
import RedButton from "../../../../Components/Button/RedButton";
import GradientButton from "../../../../Components/Button/GradientButton";
import DashboardService from "../../../../Services/DashboardService";
import moment from "moment";
import Utils from "../../../../Common/Utils";
import CustomizedViewService from "../../../../Services/CustomizedViewService";
import NoRecords from "../../../../Components/NoRecords/NoRecords";
import { ExceptionByCountryResponse } from "../../../../Models/DashboardModel";
import SnackbarAlert from "../../../../Components/SnackBarAlert/SnackbarAlert";
const CustViewServices = new CustomizedViewService();
const DashboardServices = new DashboardService();
type Props = {
  showExceptioReportingDateDialog?: boolean;
};

type State = {
  selectedDate?: string | null;
  xAxisCategories: string[];
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedCountryValues: SelectModel[];
  countryMappingData: any;
  startDatePicker: string | null;
  endDatePicker: string | null;
  isSearchButtonLoading: boolean;
  isChartLoading: boolean;
  exceptionByCountry: ExceptionByCountryResponse[];
};
class ExceptionPerCountry extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      selectedDate: null,
      xAxisCategories: [],
      selectedStartDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedCountryValues: [],
      countryMappingData: [],
      startDatePicker: null,
      endDatePicker: null,
      isSearchButtonLoading: false,
      isChartLoading: false,
      exceptionByCountry: [],
    };
  }
  async componentDidMount() {
    const response = await CustViewServices.getAllCountryAndSourceData();
    console.log("result", response);
    
    const dataArr = response?.data.result.map((el: any) => {
      return {
        ...el,
      };
    });
    const filteredByCNTRY = dataArr.filter(
      (item: any) => item.type === "CNTRY"
    );
    let countryData = filteredByCNTRY
      .map((el: any) => {
        return {
          text: el.col.toUpperCase(),
          value: el.col.toUpperCase(),
        };
      })
      .filter((val: any) => val.text !== "Other");
    this.setState(
      {
        countryMappingData: countryData.sort((a: any, b: any) =>
          a.text.localeCompare(b.text)
        ),

        selectedCountryValues: this.props.showExceptioReportingDateDialog
          ? [{ text: "CO", value: "CO" }]
          : [],
      },
      () => {
        this.getDefaultData();
        this.getLastLoadData();
      }
    );
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (
      prevProps.showExceptioReportingDateDialog !==
      this.props.showExceptioReportingDateDialog
    ) {
      if (this.props.showExceptioReportingDateDialog === true) {
        this.getDefaultData();
      }
    }
  }

  getDefaultData = () => {
    this.setState(
      {
        selectedCountryValues: [{ text: "CO", value: "CO" }],
        selectedStartDate: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
        startDatePicker: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        endDatePicker: moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.getLastLoadData();
      }
    );
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 180 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 180",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleCountrySelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 1) {
      if (targetId) {
        this.setState({ selectedCountryValues: selected }, () => {});
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select only 1 country",
        alertType: "error",
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 179);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };

  onResetClick = () => {
    this.setState({
      selectedCountryValues: [],
      startDatePicker: null,
      endDatePicker: null,
    });
  };
  isEmptyCheck = () => {
    const { selectedCountryValues, startDatePicker, endDatePicker } =
      this.state;
    if (startDatePicker && endDatePicker && selectedCountryValues.length > 0) {
      return false;
    }
    return true;
  };
  handleSearchClick = () => {
    this.setState(
      {
        isChartLoading: true,
        isSearchButtonLoading: true,
        selectedStartDate: this.state.startDatePicker,
        selectedEndDate: this.state.endDatePicker,
      },
      () => {
        this.getLastLoadData();
      }
    );
  };

  getLastLoadData = async () => {
    const { selectedCountryValues, selectedEndDate, selectedStartDate } =
      this.state;

    try {
      let countryVal = selectedCountryValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let payloadObj1: any = {};
      payloadObj1 = {
        par_group_source: 0,
        par_start_date: selectedStartDate,
        par_end_date: selectedEndDate,
        par_whereclause: `Country in (${countryVal}) and CAST(exception_datetime as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}'' `,
      };
      this.setState({ isSearchButtonLoading: true, isChartLoading: true });
      let responseCountry = await DashboardServices.getCountryExceptionData(
        payloadObj1
      );
      this.setState({
        isSearchButtonLoading: false,
        isChartLoading: false,
        exceptionByCountry: responseCountry.result,
      });
    } catch (error) {
      console.log("unexpected error during fecthing:", error);
      this.setState({
        isSearchButtonLoading: false,
        isChartLoading: false,
      });
    }
  };
  render() {
    const {
      selectedStartDate,
      selectedEndDate,
      alertMsg,
      alertType,
      openAlert,
      countryMappingData,
      selectedCountryValues,
      startDatePicker,
      endDatePicker,
      isSearchButtonLoading,
      isChartLoading,
      exceptionByCountry,
    } = this.state;
    return (
      <Grid>
        <div className="AlertException">
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "40px 5px 25px 5px",
          }}
        >
          {" "}
          <Grid container columnGap={1}>
            <Grid item xs={5}>
              <InputLabel>Select Country</InputLabel>
              <MultiAutoComplete
                id="countryName"
                label="Select Country"
                selected={selectedCountryValues}
                values={countryMappingData}
                onChange={this.handleCountrySelectChange}
              />
            </Grid>
            <Grid item xs={2.2} ml={0.8}>
              <InputLabel> Select Start Date</InputLabel>
              <DatePicker
                name="startDate"
                placeHolder="Select Date"
                maxDate={
                  new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                }
                value={startDatePicker}
                onChange={this.handleStartDateChange}
              />
            </Grid>
            <Grid item xs={2.2} className="EndDate" ml={0.8}>
              <InputLabel> Select End Date</InputLabel>
              <DatePicker
                disabled={!startDatePicker}
                name="endDate"
                placeHolder="Select Date"
                minDate={new Date(startDatePicker!)}
                maxDate={this.calculateMaxDate(startDatePicker)}
                value={endDatePicker}
                onChange={this.handleEndDateChange}
              />
            </Grid>
            <Grid item xs={0.8} mt={3.2} ml={0.8}>
              <GradientButton
                disabled={this.isEmptyCheck()}
                isButtonLoad={isSearchButtonLoading}
                label="Search"
                onClick={this.handleSearchClick}
              />
            </Grid>
            <Grid item xs={0.8} mt={3.2} ml={1}>
              <RedButton label="Reset" onClick={this.onResetClick} />
            </Grid>
          </Grid>
        </Grid>
        {isChartLoading ? (
          <Grid
            item
            style={{
              height: "8rem",
              marginLeft: "auto",
              marginRight: "auto",
              margin: "10vh",
            }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress
              disableShrink
              sx={{ color: "#d52b1e", marginTop: 4 }}
            />
          </Grid>
        ) : (
          <>
            {exceptionByCountry.length > 0 ? (
              <ChartPerCountry
                chartDataCountryArr={exceptionByCountry}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
              />
            ) : (
              <Box
                style={{
                  position: "sticky",
                  left: "50%",
                  marginTop: "25vh",
                  marginBottom: "25vh",
                }}
                width={"82px"}
              >
                <NoRecords msg="No Data" />
              </Box>
            )}
          </>
        )}
      </Grid>
    );
  }
}
export default ExceptionPerCountry;
