export const HEADER_MAPPER = {
  enterpriseId: "Enterprise ID",
};

export const FIELDS_TO_LINK = {};

export const STATUS_END_POINTS = {};

export const COUNTRY_CODE = {
  Austria: "AT",
  France: "FR",
  Belgium: "BE",
  Bulgaria: "BG",
  Canada: "CA",
  Colombia: "CO",
  Croatia: "HR",
  Cyprus: "CY",
  Czechia: "CZ",
  Estonia: "EE",
  Finland: "FI",
  Germany: "DE",
  Hungary: "HU",
  Ireland: "IE",
  Italy: "IT",
  "South Korea": "KR",
  Latvia: "LV",
  Lithuania: "LT",
  Netherlands: "NL",
  Norway: "NO",
  Poland: "PL",
  Portugal: "PT",
  Romania: "RO",
  "Russian Federation": "RU",
  "Saudi Arabia": "SA",
  Serbia: "RS",
  "Sierra Leone": "SL",
  Slovakia: "SK",
  Spain: "ES",
  Sweden: "SE",
  Switzerland: "CH",
  Turkey: "TR",
  Ukraine: "UA",
  "United Kingdom of Great Britain and Northern Ireland": "GB",
  "United States": "US",
  Mexico: "MX",
  Brazil: "BR",
};
export const COUNTRY_DATA = [
  { text: "Austria", value: "Austria" },
  { text: "France", value: "France" },
  { text: "Belgium", value: "Belgium" },
  { text: "Bulgaria", value: "Bulgaria" },
  { text: "Canada", value: "Canada" },
  { text: "Colombia", value: "Colombia" },
  { text: "Croatia", value: "Croatia" },
  { text: "Cyprus", value: "Cyprus" },
  { text: "Czechia", value: "Czechia" },
  { text: "Estonia", value: "Estonia" },
  { text: "Finland", value: "Finland" },
  { text: "Germany", value: "Germany" },
  { text: "Hungary", value: "Hungary" },
  { text: "Ireland", value: "Ireland" },
  { text: "Italy", value: "Italy" },
  { text: "South Korea", value: "South Korea" },
  { text: "Latvia", value: "Latvia" },
  { text: "Lithuania", value: "Lithuania" },
  { text: "Netherlands", value: "Netherlands" },
  { text: "Norway", value: "Norway" },
  { text: "Poland", value: "Poland" },
  { text: "Portugal", value: "Portugal" },
  { text: "Romania", value: "Romania" },
  { text: "Russian Federation", value: "Russian Federation" },
  { text: "Saudi Arabia", value: "Saudi Arabia" },
  { text: "Serbia", value: "Serbia" },
  { text: "Sierra Leone", value: "Sierra Leone" },
  { text: "Slovakia", value: "Slovakia" },
  { text: "Spain", value: "Spain" },
  { text: "Sweden", value: "Sweden" },
  { text: "Switzerland", value: "Switzerland" },
  { text: "Turkey", value: "Turkey" },
  { text: "Ukraine", value: "Ukraine" },
  {
    text: "United Kingdom of Great Britain and Northern Ireland",
    value: "United Kingdom of Great Britain and Northern Ireland",
  },
  { text: "United States", value: "United States" },
  { text: "Mexico", value: "Mexico" },
  { text: "Brazil", value: "Brazil" },
];
export const DATA_TYPES = [
  {
    text: "varchar(255)",
    value: "varchar(255)",
  },
  {
    text: "int",
    value: "int",
  },
];

export const USER_ROLES = {
  PUBLICATION_LEAD: "PUBLICATION_LEAD",
  SUPER_USER: "SUPER_USER",
  DATA_ANALYST: "DATA_ANALYST",
  NATC: "NATC",
  MANAGEMENT_LEADERSHIP: "MANAGEMENT_LEADERSHIP",
  GTITADMIN_SUPPORT: "GTITADMIN_SUPPORT",
};

export const URIs = {
  MICROSOFT_GRAPH_MEMBEROF: "https://graph.microsoft.com/v1.0/me/memberOf",
  AUTH_INIT_USER: "/auth/me",
  AUTH_ROLES: "/auth/roles",
  ALLOWED_SDM_TABLES: "/accesstables",
  GET_SDM_TABLE_DATA: "/sdm/read",
  GET_COLUMN_DETAILS: "/getcolumndetails",
  CREATE_SDM_RECORDS: "/sdm/insert",
  UPDATE_SDM_RECORDS: "/sdm/update",
  DELETE_SDM_RECORDS: "/sdm/delete",
  READ_RATIONALE: "/sdm/rationaleread",
  KEY_TABLES: "/sdm/keytables",
  SDM_FILEUPLOAD: "/uploadfile",
  CREATE_SDM_FILEUPLOAD: "/sdmfile/create",
  GET_SDM_UPLOADED_FILESDATA: "/uploadedfilesdata",
  SUPPORT_FILEUPLOAD: "/supportuploadfile",
  CREATE_FILEUPLOAD: "/supportfile/create",
  GET_SUPPORT_UPLOADED_FILESDATA: "/supportfilesdata",
  CLINICAL_TRIAL_SEARCH: "/search/clinicaltrials",
  CUSTOMER_SEARCH: "/customer/search",
  SDMFILE_SEARCH: "/searchuploadedfilesdata",
  SUPPORTFILE_SEARCH: "/searchsupportfilesdata",
  NOTIFICATION_SUMMARY: "/notification/summary",
  NOTIFICATION_COUNT: "/notification/count",
  NOTIFICATION_MODIFY: "/notification/modify",
  NOTIFICATION_HISTORY: "/notification/history",
  NOTIFICATION_ASSIGNMENT: "/notification/assignment",
  GETALL_USERS: "/allusers",
  REPORTING_DEADLINE: "/reportingdata",
  SOURCE_MAPPING: "/sourcemapping",
  MISSING_TRANSACTION_REPORT: "/mtreport",
  OTHER_APPS_CREATE: "/otherapp/create",
  OTHER_APPS_UPDATE: "/otherapp/update",
  OTHER_APPS_DELETE: "/otherapp/delete",
  OTHER_APPS_GETALL: "/otherapp/getall",
  MYLINK_APPS_CREATE: "/mylink/create",
  MYLINK_APPS_UPDATE: "/mylink/update",
  MYLINK_APPS_DELETE: "/mylink/delete",
  MYLINK_APPS_GETALL: "/mylink/getall",
  MYLINK_APPS_SEARCH: "/mylink/search",
  LAST_LOAD_DATA: "/dataload",
  ON_DEMAND_SELECTION_DATA: "/reportselectdata",
  ON_DEMAND_RPT_SUBMISSION: "/report/submission",
  ON_DEMAND_RPT_HISTORY: "/report/history",
  REPORT_DOWNLOAD: "/s3download",
  AUDIT_HISTORY: "/audithistory",
  EXCEPTION_REPORTING_COUNT: "/exceptionreport/count",
  EXCEPTION_REPORTING_DATA: "/exceptionreport/data",
  EXCEPTION_REPORTING_UPDATE: "/exceptionreport/update",
  EXCEPTION_LINK_MAPPING: "/link/mapping",
  EXCEPTION_ASSIGNED_USER: "/exception/assigneduser",
  MONITORING_HEADER:"/monitoringrpt/header",
  MONITORING_DATA:"/monitoringrpt/data",
  EXCEPTIONREPORT_DATA:"/exceptionwidget/source",
  EXCEPTIONPERCOUNTRY_DATA:"/exceptionwidget/country",
  COUNTRY_SOURCE_DATA: "/countrysourcedata",
  CREATE_MYVIEW_DATA: "/myview/create",
  UPDATE_MYVIEW_DATA: "/myview/update",
  DELETE_MYVIEW_DATA: "/myview/delete",
  GET_MYVIEW_DATA: "/myview/getall",
  KICKOUT_DATA: "/kickoutdata",
};

export const SUPPORT_DATA_FILE_TYPE = [
  "_Payments",
  "sln_hcp_found",
  "NPPES",
  "RPT_STATE_LIC_EXCPTN",
  "RPT_STATE_MA_RCP",
  "RPT_STATE_VT_HCP_RCP",
  "VPL",
];

export const SDM_FILE_UPLOAD_TABLES = {
  PUBLICATION_LEAD: [
    "RPT_OVERRIDES.xlsx",
    "RPT_RCRD_ADD_RQSTS.xlsx",
    "RPT_RCRD_UPDT_RQSTS.xlsx",
    "RPT_SUBMSN_REMOVAL.xlsx",
  ],
  DATA_ANALYST: [
    "CO_CUST_ALT_ID.xlsx",
    "EMPL_INFO.xlsx",
    "NV_SALES_REP_ID.xlsx",
    "OR_SALES_REP_ID.xlsx",
    "SA_CUST_ALT_ID.xlsx",
    "SUNSHINE_PRE_CLIN_COST_CTRS.xlsx",
    "SUNSHINE_TEACHING_HOSPITAL.xlsx",
    "UNAPLD_RSRCH_AGGRCRD.xlsx",
    "RPT_COMMENT.xlsx",
    "RPT_US_HCO_DATA.xlsx",
    "KR_CLINICAL_TRIAL.xlsx",
  ],
  SUPER_USER: [
    "RPT_OVERRIDES.xlsx",
    "RPT_RCRD_ADD_RQSTS.xlsx",
    "RPT_RCRD_UPDT_RQSTS.xlsx",
    "RPT_SUBMSN_REMOVAL.xlsx",
    "CO_CUST_ALT_ID.xlsx",
    "EMPL_INFO.xlsx",
    "NV_SALES_REP_ID.xlsx",
    "OR_SALES_REP_ID.xlsx",
    "SA_CUST_ALT_ID.xlsx",
    "SUNSHINE_PRE_CLIN_COST_CTRS.xlsx",
    "SUNSHINE_TEACHING_HOSPITAL.xlsx",
    "UNAPLD_RSRCH_AGGRCRD.xlsx",
    "RPT_COMMENT.xlsx",
    "RPT_US_HCO_DATA.xlsx",
    "KR_CLINICAL_TRIAL.xlsx",
  ],
};

export const sdmTemplate = {
  RPT_STATE_LIC_EXCPTN: [
    "LPID",
    "HCP Name",
    "States with Active License",
    "VT/MN Address?",
    "Professional Designation",
    "MN Practice?",
    "VT practice?",
    "How often?",
    "Last Practiced In",
    "Date MedPro Checked",
    "Date Exception Form Sent to Rep",
    "Date Signed",
    "Date Received",
    "Exception Approved or Denied",
    "Date Approved or Denied",
    "Expiration Date",
    "Explanation",
    "Rationale",
    "Lilly Contact",
    "Immediate Supervisor",
    "Item Type",
    "Path",
  ],
  RPT_STATE_MA_RCP: [
    "ID",
    "FirstName",
    "MiddleName",
    "Lastname",
    "Suffix",
    "FullName",
    "Organization",
    "Address",
    "Address2",
    "City",
    "State",
    "Zipcode",
    "Entity_Type",
    "LicenseType",
    "License_Number",
    "RATIONALE",
  ],
  RPT_STATE_VT_HCP_RCP: [
    "License",
    "First Name",
    "Middle Name",
    "Last Name",
    "City/Town",
    "State",
    "RATIONALE",
  ],
};

export const reviewSdmTable = [
  { text: "RPT_RCRD_ADD_RQSTS", value: "RPT_RCRD_ADD_RQSTS" },
  { text: "RPT_RCRD_UPDT_RQSTS", value: "RPT_RCRD_UPDT_RQSTS" },
  { text: "RPT_OVERRIDES", value: "RPT_OVERRIDES" },
];
export const reviewStatusData = [
  { text: "Review Rejected", value: "Review Rejected" },
  { text: "Review Completed", value: "Review Completed" },
];

export const linkMappingData = {
  "NDC Directory": "https://www.accessdata.fda.gov/scripts/cder/ndc/index.cfm",
  Veeva: "https://veevanetwork.lilly.com",
  NPPES: "https://npiregistry.cms.hhs.gov/search",
  "Health Guide USA": "https://www.healthguideusa.org",
  "Clinical Trials": "https://clinicaltrials.gov",
};

export const monitoringRptSummaryData = [
  {
    key: "State-CA",
    summary: `<p>Compliance Monitoring for California includes checking that Lilly is not approaching the $3,000 annual limit for the below items provided to California covered recipients who practice in California.</p> 
      <li>Promotional meals not associated with contracted services or interviewing for employment.</li>
      <li>Promotional educational items that educate/benefit the HCP.</li>`,
    start: 2000,
    end: 3000,
  },
  {
    key: "State-DC",
    summary: `<p>Employees of HCPs are covered recipients for DC. Monitor and track meals >$25</p>`,
    start: 25,
    end: 50,
  },
  {
    key: "State-MA",
    summary: `<p>Compliance monitoring for Massachusetts includes investigating meals provided to MA licensed prescribers (regardless if the meal takes place in Massachusetts and regardless if the prescriber practices in Massachusetts) unless the meal is associated with:</p>
    <li>an educational program.</li>
    <li>contracted services.</li> 
    <li>interviewing for employment at Lilly.</li>`,
    start: 0,
    end: 0,
  },
  {
    key: "State-MN",
    summary: `<p>Compliance monitoring for Minnesota includes investigating transactions with a Minnesota licensed prescribers practicing in Minnesota or border state where Lilly has provided more than $50 in:</P>
    <li>meals not associated with contracted services or interviewing for employment.</li>
    <li>promotional consulting.</li>
    <li>educational items that benefit the HCP.</li>`,
    start: 35,
    end: 50,
  },
  {
    key: "State-NV",
    summary: `<p>Monitoring for no state ID when a Lilly representative spends >4 days in Nevada.</p>`,
    start: 0,
    end: 0,
  },
  {
    key: "State-VT",
    summary: `<p>Compliance monitoring for Vermont includes investigating:</p>
    <li>transactions with a Vermont HCP Covered Recipients where Lilly has provided a meal that is not associated with contracted services or interviewing for employment with Lilly.</li>
    <li>grants or charitable donations provided to Vermont hospitals or nursing homes unless it is associated with supporting a free clinic.</li>`,
    start: 0,
    end: 0,
  },
  {
    key: "State-OR",
    summary: `<p>Monitoring for no state ID when a Lilly representative spends >14 days in Oregon.</p>`,
    start: 0,
    end: 0,
  },
  {
    key: "All_State_Non_Classified",
    summary: `<p>Monitor for non-classified spend. Investigate any non-classified events and correct or override as required.</p>`,
    start: 0,
    end: 0,
  },
  {
    key: "CPS_AMEX_Comparison",
    summary: `<p>Flag HCP's who have spend in both CPS and AMX over $15,000 in total.</p>`,
    start: 0,
    end: 0,
  },
  {
    key: "CPS_High_Earners",
    summary: `<p>Flag spend over $100,000 in total for CPS source per HCP.</p>`,
    start: 0,
    end: 0,
  },
  {
    key: "CPS_Travel_Compensation",
    summary: `<p>Flag supplemental travel spend over $15,000 for CPS source per HCP.</p>`,
    start: 0,
    end: 0,
  },
];

export const reportMenuList = [
  {
    name: "All State Non Classified",
    as: "All_State_Non_Classified",
  },
  {
    name: "Concur Duplicates",
    as: "Concur_Duplicates",
  },

  {
    name: "State-CA",
    as: "State-CA",
  },
  {
    name: "State-DC",
    as: "State-DC",
  },
  {
    name: "State-MA",
    as: "State-MA",
  },
  {
    name: "State-MN",
    as: "State-MN",
  },
  {
    name: "State-NV",
    as: "State-NV",
  },
  {
    name: "State-VT",
    as: "State-VT",
  },

  {
    name: "State-OR",
    as: "State-OR",
  },
];
export const CpsMenuList = [
  {
    name: "CPS AMEX Comparison",
    as: "CPS_AMEX_Comparison",
  },
  {
    name: "CPS High Earners",
    as: "CPS_High_Earners",
  },
  {
    name: "CPS Travel Compensation",
    as: "CPS_Travel_Compensation",
  },
];

export const CHART_COLORS = ["#8B83FB", "#45BA7F", "#4791FF", "#EF4A5F", "#F69A19"];