import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { isEqual, isNull } from "lodash";
import Utils from "../../Common/Utils";
import { IconType } from "../Button/IconButton";
import NoRecords from "../NoRecords/NoRecords";

import GradientButton from "../Button/GradientButton";
import InputTypeSelector from "../InputTypeSelector/InputTypeSelector";
import ActionMenuDropdown from "./ActionMenuDropdown";
import CommentSection from "./CommentSection";

import React from "react";
import { IToolbarMode } from "../../Models/IToolbarMode";
import {
  GetColumnDetailsResponseModel,
  GetSDMTableRequestModel,
} from "../../Models/SDMTableRequestModel";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import TableInputTypeSelector from "../InputTypeSelector/TableInputTypeSelector";
import PaginationNew from "../Pagination";
import "./style.css";

import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

type SortType = {
  key: string;
  direction: string;
};

type tableModeForEntries =
  | "multipleTableEntries"
  | "SingleTableEntries"
  | "view"
  | undefined;

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCellDataForEntries: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  sortConfig: SortType | null;
  data: any;
  totalRecordsCount: number;
  onClickCheckBox?: (selectedRows: any, data: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  actionArray?: IconType[];
  onActionClick?: (action: IconType, record: any) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleComment?: (record: any) => void;
  handleInputChange: (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => void;
  handleBulkInputChange: (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => void;
  commentForId?: number;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  checkBoxDifferentiator?: string | number;
  mode: IToolbarMode;
  handleToolbarCancel?: () => void;
  modes: tableModeForEntries;

  coulmnDetails: GetColumnDetailsResponseModel[];
  bulkEditInputFormData: any;
  handleReset: (event: any, type: any) => void;

  handleApply: (event: any) => void;
  handleClone: () => void;
  handleActionEditClick: () => void;
  handleMode: (action: IToolbarMode) => void;
  handleSave: (mode: string) => void;
  isButtonLoad: boolean;
  handleDelete: () => void;
  selectedSdmTableValue: string;
  onRationaleInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  rationale: string;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
  validation: boolean;
  getSearchFilterTableData?: (response: any) => void;
  fetchFilterData: (obj: any, searchedDetails: any) => void;
  isdataFiltered: boolean;
  filteredColumn?: { column: any; value: any; type: any };
  page: number;
  rows: number;
  allColumnDetails: GetColumnDetailsResponseModel[];
}

interface State {
  sortConfig: SortType | null;
  data: any;
  resetRow: any;
  selectedData: any;
  selectAll: boolean;
  selectSingle: boolean;
  rowsPerPage: number;
  page: number;
  caretHover: boolean;
  ShowComponent: boolean;
  open: boolean;
  anchorEl: any;
  anchorReference: any;
  ActionMenu: boolean;
  addComment: number | undefined;
  showEditField: number | undefined;
  showCloneFields: number | undefined;
  showbulkEditFields: number | undefined;
  commentId: any;
  editId: any;
  cloneId: any;
  record: any;
  commentForId: any;
  formData: { [index: string]: any };
  cloneFormData: { [index: string]: any };
  uniqueValue: any;
  showDelDialoge: boolean;
  editInput: string;
  selectedSdmTableValue: string;
  pageValue: number;
  isOpenSearchFilterByCol: boolean;
  searchFilterCol: { fieldName: string; checked: boolean }[];
  tooltipVisible: boolean;
  searchFilterInputValue: string;
  isLoading: boolean;
  filteredColumn: { column: any; value: any; type: any };
  isPaginationReset: boolean;
  isEmptyReset: boolean;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 3px 6px #00000029",
    fontSize: 15,
    maxWidth: "none",
    padding: "0",
    cursor: "pointer",
  },
}));
const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#E5E5E5",
    zIndex: "99999",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    zIndex: "99999",
  },
  "td:nth-of-type(2), th:nth-of-type(2)": {
    position: "sticky",
    left: "70px",
    zIndex: "99999",
    background: "white",
    borderBottom: "2px solid #e0e0e0",
    borderRight: "2px solid #e0e0e0",
  },
}));

class Table extends Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  constructor(props: Props) {
    super(props);
    this.state = {
      sortConfig: null,
      data: [],
      resetRow: [],
      selectAll: false,
      selectSingle: false,
      selectedData: [],
      rowsPerPage: this.props.rows,
      page: this.props.page,
      caretHover: false,
      ShowComponent: false,
      open: false,
      anchorEl: null,
      anchorReference: null,
      ActionMenu: false,
      addComment: undefined,
      showEditField: undefined,
      showCloneFields: undefined,
      showbulkEditFields: undefined,
      editId: null,
      commentId: null,
      cloneId: null,
      record: [],
      commentForId: undefined,
      formData: {},
      cloneFormData: {},
      uniqueValue: null,
      showDelDialoge: false,
      editInput: "",
      selectedSdmTableValue: "",
      pageValue: 0,
      isOpenSearchFilterByCol: false,
      searchFilterCol: [],
      tooltipVisible: false,
      searchFilterInputValue: "",
      isLoading: this.props.isLoading || false,

      filteredColumn: {
        column: this.props.filteredColumn?.column,
        value: this.props.filteredColumn?.value,
        type: this.props.filteredColumn?.type,
      },
      isPaginationReset: false,
      isEmptyReset: false,
    };
  }

  handleCommentClick = (record: any) => {
    this.props.handleMode("comment");
    this.setState({ commentForId: record });
  };
  handleDialogClick = () => {
    this.setState({ showDelDialoge: true });
  };
  handleEditClick = (record: any) => {
    this.setState({ showEditField: record });
    this.props.handleActionEditClick();
  };
  getRecordDetails = async () => {
    const formData: { [index: string]: any } = {};
    StyledTableRow.Each((el: any) => {
      formData[el.fieldName] = el.value;
    });
    this.setState({
      data: StyledTableRow.data,
      formData,
      cloneFormData: formData,
    });
  };
  handleSearchFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      searchFilterInputValue: event.target.value,
    });
  };
  handleSearchChange = (event: any, targetId?: any) => {
    if (
      targetId.toLowerCase().startsWith("dt_") ||
      targetId.toLowerCase().endsWith("_dt") ||
      ["date_val", "date_time_val"].includes(targetId.toLowerCase())
    ) {
      this.setState({
        searchFilterInputValue: event ? event : null,
      });
    } else {
      this.setState({
        searchFilterInputValue: event.target.value ? event.target.value : null,
      });
    }
  };
  // Filte UI
  getFilterData = async (rows: number, page: number) => {
    if (this.props.filteredColumn) {
      const { searchFilterInputValue } = this.state;
      const { filteredColumn } = this.props;

      this.props.handleMode("filter");
      const key = this.state.sortConfig?.key;

      let inputTypeObj;
      if (key?.toLowerCase() === "rcrd_key") {
        inputTypeObj = { fieldName: "rcrd_key", type: "" };
      } else {
        if (!filteredColumn.type) {
          inputTypeObj = this.props.allColumnDetails.find(
            (val: any) => val.fieldName === key
          );
        }
      }

      const type = filteredColumn.type?.length
        ? filteredColumn.type
        : inputTypeObj?.type;
      const searchValue = filteredColumn?.value
        ? filteredColumn.value
        : searchFilterInputValue;
      const obj: GetSDMTableRequestModel = {
        sdm_tablename: this.props.selectedSdmTableValue.toLowerCase(),
        var_whereclause:
          type === "text"
            ? `lower(${
                filteredColumn.column.length > 0 ? filteredColumn.column : key
              }) like lower('%${
                filteredColumn.value.length > 0
                  ? filteredColumn.value
                  : searchFilterInputValue
              }%')`
            : type === "date"
            ? `${
                filteredColumn.column.length > 0 ? filteredColumn.column : key
              }='${
                filteredColumn.value.length > 0
                  ? filteredColumn.value
                  : searchFilterInputValue
              }'`
            : `${
                filteredColumn.column.length > 0 ? filteredColumn.column : key
              }=${
                filteredColumn.value.length > 0
                  ? filteredColumn.value
                  : searchFilterInputValue
              }`,
        var_order: null,
        var_page:
          type === "text" || type === "date" || type === "number" ? page : null,
        var_recordsperpage:
          type === "text" || type === "date" || type === "number" ? rows : null,
      };

      let searchedDetails = {
        fieldName: filteredColumn.column
          ? filteredColumn.column
          : inputTypeObj?.fieldName,
        type: filteredColumn.type ? filteredColumn.type : inputTypeObj?.type,
        searchValue,
      };
      this.props.fetchFilterData(obj, searchedDetails);
      this.setState({ isEmptyReset: false, searchFilterInputValue: "" });
    }
  };
  handleSearchFilterClick = () => {
    try {
      const { searchFilterInputValue } = this.state;
      if (searchFilterInputValue.length > 0) {
        this.setState(
          {
            isLoading: true,
            filteredColumn: { column: "", value: "", type: "" },
            tooltipVisible: false,
          },
          () => {
            this.getFilterData(this.props.rows, this.props.page);
          }
        );
      } else {
        this.setState({ tooltipVisible: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        searchFilterInputValue: "",
      });
    }
  };
  handleChangePage = (page: number) => {
    if (this.props.isdataFiltered) {
      this.setState({ page: page }, () => {
        this.getFilterData(this.props.rows, page);
      });
    } else {
      this.props.onChangePage(page);
    }
  };
  handleChangeRowsPerPage = (rows: number) => {
    if (this.props.isdataFiltered) {
      this.setState({ rowsPerPage: rows }, () => {
        this.setState({ isEmptyReset: true }, () => {
          this.getFilterData(rows, this.props.page > 1 ? 1 : this.props.page);
        });
      });
    } else {
      this.props.onChangeRow(rows);
    }
  };
  handleCloneClick = () => {
    this.props.handleClone();
  };

  handleActionOptionClick = () => {
    this.setState({ ActionMenu: !this.state.ActionMenu });
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        data: this.props.data,
        selectAll: false,
        sortConfig: null,
        filteredColumn: {
          column: this.props.filteredColumn?.column,
          value: this.props.filteredColumn?.value,
          type: this.props.filteredColumn?.type,
        },
        tooltipVisible: false,
        searchFilterInputValue: "",
      });
    }
    if (!isEqual(prevProps.checkBoxDifferentiator, this.state.uniqueValue)) {
      this.setState({ uniqueValue: this.props.checkBoxDifferentiator });
    }
    if (
      !isEqual(
        prevProps.selectedSdmTableValue,
        this.state.selectedSdmTableValue
      )
    ) {
      this.setState({
        selectedSdmTableValue: this.props.selectedSdmTableValue,
      });
    }
    if (this.props.onClickCheckBox) {
      if (!isEqual(prevState.data, this.state.data)) {
        this.setState({
          selectedData: this.state?.data?.filter(
            (el: any) => el.checked === true
          ),
        });
      }

      if (!isEqual(prevState.selectedData, this.state.selectedData)) {
        this.props.onClickCheckBox(this.state.selectedData, this.state.data);
      }
    }
  };

  componentDidMount = () => {
    if (this.props.checkBoxDifferentiator) {
      this.setState({
        data: this.props.data,
        uniqueValue: this.props.checkBoxDifferentiator,
        selectedSdmTableValue: this.props.selectedSdmTableValue,
      });
    }
  };

  requestSort = (key: string, direction: string) => {
    this.setState({ sortConfig: { key, direction } }, () => {
      this.sorter();
    });
  };

  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;

    if (sortConfig !== null) {
      sortedData = sortedData.sort(
        (a: { [x: string]: number }, b: { [x: string]: number }) => {
          if (sortConfig.key === "sqnc_nbr") {
            if (Number(a[sortConfig.key]) < Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (Number(a[sortConfig.key]) > Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          return 0;
        }
      );
    }

    this.setState({
      data: sortedData,
      filteredColumn: { column: sortConfig?.key, value: "", type: "" },
    });

    return sortedData;
  };

  onEditInputChange = (event: any, index: any, targetId?: string) => {
    this.props.handleInputChange(event, index, targetId);
  };
  onBulkEditInputChange = (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => {
    this.props.handleInputChange(event, index, targetId, data);
  };
  handleResetClick = (event: any, type: any) => {
    if (type === "bulkformreset") {
      this.props.handleReset(event, type);
    }
  };
  onFormInputChange = (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => {
    this.props.handleBulkInputChange(event, index, targetId, data);
  };

  defaultSortIconColor = "#959595";
  activeSortIconColor = "#000000";

  positionArrow = (key: string) => {
    if (["action"].includes(key.toLowerCase()) || this.props.isdataFiltered) {
      return null;
    }
    const { tooltipVisible, sortConfig } = this.state;
    if (sortConfig?.key === key && tooltipVisible) {
      return (
        <FontAwesomeIcon
          icon={faAngleUp}
          color={this.defaultSortIconColor}
          size="xs"
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faAngleDown}
        color={this.defaultSortIconColor}
        size="sm"
      />
    );
  };

  onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: "single" | "all",
    singleRow?: any
  ) => {
    if (this.props.onClickCheckBox) {
      switch (action) {
        case "single":
          const { uniqueValue } = this.state;
          if (this.props.checkBoxDifferentiator) {
            const dataMapped = this.state.data.map((el: any) => {
              if (el[uniqueValue] === singleRow[uniqueValue]) {
                return { ...el, checked: event.target.checked };
              }
              return el;
            });
            this.setState({ data: dataMapped });
            if (
              dataMapped.some((d: any) => d.checked === false) &&
              this.state.selectSingle
            ) {
              this.setState({ selectAll: false });
            } else if (
              dataMapped.every((d: any) => d.checked === true) &&
              !this.state.selectAll
            ) {
              this.setState({ selectSingle: true });
            }
          }
          break;
        case "all":
          this.setState({
            data: this.state.data.map((d: any) => ({
              ...d,
              checked: event.target.checked,
            })),
          });
          this.setState({ selectAll: event.target.checked });
          break;
        default:
          break;
      }
    }
  };

  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  };

  handleSave = (event: any) => {
    event.preventDefault();

    if (this.props.modes === "SingleTableEntries") {
      this.props.handleSave("SingleTableEntries");
      //this.handleRemoveFilter();
    }
    if (this.props.mode === "edit" && this.state.selectedData.length === 1) {
      this.props.handleSave("edit");
      this.handleRemoveFilterFromComment();
    }
    if (this.props.mode === "clone") {
      this.props.handleSave("clone");
    }
    if (this.state.selectedData.length > 1) {
      this.props.handleSave("bulkEdit");
      //this.handleRemoveFilter();
    }
  };

  toggleTooltip = (key: string) => {
    let direction = "";

    if (key === this.state.sortConfig?.key) {
      direction = this.state.sortConfig?.direction;

      this.setState({ tooltipVisible: !this.state.tooltipVisible });
    } else {
      this.setState({
        tooltipVisible: !this.state.tooltipVisible,

        sortConfig: { key, direction },

        searchFilterInputValue: "",
      });
    }
  };

  handleRemoveFilter = () => {
    this.setState(
      {
        isEmptyReset: false,
        page: 1,
        rowsPerPage: 5,
        sortConfig: null,
        searchFilterInputValue: "",
        tooltipVisible: false,
      },
      () => {
        if (this.props.handleToolbarCancel) {
          this.props.handleToolbarCancel();
        }
      }
    );
  };
  handleRemoveFilterFromComment = () => {
    this.setState({
      isEmptyReset: false,
      page: 1,
      rowsPerPage: 5,
      sortConfig: null,
      searchFilterInputValue: "",
      isPaginationReset: false,
      showEditField: undefined,
    });
  };
  searchFilterClear = () => {
    this.setState({ searchFilterInputValue: "" });
  };

  handleDelete = () => {
    this.setState(
      {
        isPaginationReset: true,
        filteredColumn: { column: "", value: "", type: "" },
        sortConfig: null,
        searchFilterInputValue: "",
      },
      () => {
        this.props.handleDelete();
      }
    );
  };
  onKeyDown = (event: any) => {
    event.preventDefault();
  };
  render() {
    const {
      headCellData,
      headCellDataForEntries,
      onClickCheckBox,
      fieldsToColor,
      coulmnDetails,
      bulkEditInputFormData,
      isButtonLoad,
      validation,
      selectedSdmTableValue,
    } = this.props;
    const {
      data,
      selectAll,
      uniqueValue,
      tooltipVisible,
      searchFilterInputValue,
      sortConfig,
    } = this.state;
    return (
      <Box>
        {headCellDataForEntries.length && headCellData.length > 0 ? (
          <>
            <form onSubmit={this.handleSave} autoComplete="off" noValidate>
              <Grid container mt={2}>
                {this.props.modes === "SingleTableEntries" ||
                this.props.mode === "edit" ||
                this.props.mode === "clone" ||
                this.state.showEditField ? (
                  <>
                    <Grid item ml={3} mr={2}>
                      <RedOutlinedButton
                        label="Cancel"
                        onClick={this.handleCancel}
                      />
                    </Grid>
                    <Grid item>
                      <GradientButton
                        disabled={_.isEqual(
                          bulkEditInputFormData,
                          data.find((elem: any) => elem.checked === true)
                        )}
                        isButtonLoad={isButtonLoad}
                        label="Save"
                        type="submit"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              <TableContainer component={Paper}>
                {this.props.isLoading ? (
                  <Box
                    style={{
                      justifyContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                      margin: "30vh",
                    }}
                  >
                    <CircularProgress sx={{ color: "#d52b1e" }} />
                  </Box>
                ) : (
                  <MaterialTable
                    stickyHeader
                    sx={{ minWidth: "fit-content" }}
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {onClickCheckBox ? (
                          <TableCell
                            style={{
                              fontSize: "16px",
                              borderBottom: "2px solid red",
                              position: "sticky",
                              zIndex: 9999,

                              left: "0",
                            }}
                          >
                            <Checkbox
                              disabled={
                                ["edit", "clone", "comment"].includes(
                                  this.props.mode!
                                ) || data.length > 0
                                  ? data[0][this.state.uniqueValue] === null
                                  : false
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "#3DA470",
                                },
                                "& .MuiSvgIcon-root": {
                                  borderRadius: "4px",
                                },
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                              size="small"
                              checked={selectAll}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => this.onClickCheckBox(event, "all")}
                            />
                          </TableCell>
                        ) : null}

                        {headCellData.map((el, index) => {
                          const inputTypeObj = this.props.allColumnDetails.find(
                            (val: any) => val.fieldName === el.key
                          );
                          if (el && !["id", "checked"].includes(el.key)) {
                            return (
                              <LightTooltip
                                className="searchPopup"
                                key={`${index}-head`}
                                open={
                                  (this.props.isdataFiltered ? false : true) &&
                                  el.key === sortConfig?.key &&
                                  tooltipVisible
                                }
                                title={
                                  el?.key !== "Action" ? (
                                    <Box>
                                      <Grid container>
                                        <InputTypeSelector
                                          onKeyDown={this.onKeyDown}
                                          width="15rem"
                                          validation={false}
                                          type={inputTypeObj?.type!}
                                          value={searchFilterInputValue}
                                          onChange={(event) => {
                                            this.handleSearchChange(
                                              event,
                                              inputTypeObj?.fieldName
                                            );
                                          }}
                                          isDisabled={
                                            this.props.isdataFiltered
                                              ? true
                                              : false
                                          }
                                        />
                                      </Grid>
                                      <Grid container>
                                        <Grid item mb={0} ml={2} mt={2}>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Sort By:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <List className="ColumnSorting">
                                            <ListItem disablePadding>
                                              <ListItemButton
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor:
                                                      "#FFE7E5!important",
                                                  },
                                                  "&.Mui-focusVisible": {
                                                    backgroundColor:
                                                      "#00000029!important",
                                                  },
                                                  ":hover": {
                                                    backgroundColor:
                                                      "#D9D9D9!important",
                                                  },
                                                }}
                                                selected={
                                                  sortConfig?.direction ===
                                                  "ascending"
                                                }
                                                onClick={() =>
                                                  this.requestSort(
                                                    el.key,
                                                    "ascending"
                                                  )
                                                }
                                              >
                                                <ListItemIcon
                                                  style={{
                                                    marginRight: "-16px",
                                                  }}
                                                >
                                                  <NorthIcon
                                                    style={{
                                                      color: "#D42B1F",
                                                      fontSize: "16px",
                                                    }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText primary="Ascending" />
                                              </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                              <ListItemButton
                                                selected={
                                                  sortConfig?.direction ===
                                                  "descending"
                                                }
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor:
                                                      "#FFE7E5!important",
                                                  },
                                                  "&.Mui-focusVisible": {
                                                    backgroundColor:
                                                      "#00000029!important",
                                                  },
                                                  ":hover": {
                                                    backgroundColor:
                                                      "#D9D9D9!important",
                                                  },
                                                }}
                                                onClick={() =>
                                                  this.requestSort(
                                                    el.key,
                                                    "descending"
                                                  )
                                                }
                                              >
                                                <ListItemIcon
                                                  style={{
                                                    marginRight: "-16px",
                                                  }}
                                                >
                                                  <SouthIcon
                                                    style={{
                                                      color: "#D42B1F",
                                                      fontSize: "16px",
                                                    }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText primary="Descending" />
                                              </ListItemButton>
                                            </ListItem>
                                          </List>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        columnGap={1.5}
                                        justifyContent={"flex-end"}
                                        p={2}
                                        pt={0}
                                      >
                                        <RedOutlinedButton
                                          label="Cancel"
                                          onClick={() =>
                                            this.setState({
                                              tooltipVisible: false,
                                            })
                                          }
                                        />
                                        <GradientButton
                                          label="Done"
                                          onClick={this.handleSearchFilterClick}
                                          disabled={
                                            searchFilterInputValue
                                              ? false
                                              : true
                                          }
                                        />
                                      </Grid>
                                    </Box>
                                  ) : null
                                }
                                placement="bottom-start"
                              >
                                <TableCell
                                  key={`${index}-head-1-${el.key}`}
                                  style={
                                    el?.key !== "Action"
                                      ? {
                                          fontSize: "16px",
                                          borderBottom: "2px solid red",
                                          whiteSpace: "nowrap",
                                          minWidth: "10em",
                                          fontWeight: "600",
                                        }
                                      : {
                                          position: "sticky",
                                          fontWeight: "600",
                                          left: "70px",
                                          zIndex: 9999,
                                          fontSize: "16px",
                                          borderBottom: "2px solid red",
                                          whiteSpace: "nowrap",
                                        }
                                  }
                                  onClick={() => this.toggleTooltip(el.key)}
                                >
                                  {Utils.camelToTitle(el.label)}{" "}
                                  {data.length > 0 &&
                                  data[0][this.state.uniqueValue] === null
                                    ? null
                                    : this.positionArrow(el.key)}
                                </TableCell>
                              </LightTooltip>
                            );
                          }
                          return null;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {this.props.modes === "SingleTableEntries" ||
                        this.props.mode === "edit" ||
                        this.props.mode === "clone" ? (
                          <>
                            {this.state.selectedData.length > 1 ||
                            this.props.modes === "SingleTableEntries" ||
                            this.props.mode === "clone" ? (
                              <>
                                {this.props.modes === "SingleTableEntries" ||
                                this.props.mode === "clone" ? null : (
                                  <TableRow
                                    style={{
                                      position: "sticky",
                                      left: 0,
                                      background: "white",
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        position: "sticky",
                                        marginLeft: 20,
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        position: "sticky",
                                        left: "70px",
                                        background: "white",
                                        fontSize: 14,
                                        color: "#FF4A4A",
                                        textAlign: "left",
                                        fontStyle: "italic",
                                        fontFamily: "Roboto",
                                        textAlignLast: "right",
                                      }}
                                      colSpan={3}
                                    >
                                      Enter the value to apply across all
                                      records displayed
                                    </TableCell>
                                    <TableCell
                                      colSpan={headCellData.length - 3}
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                )}

                                <StyledTableRow>
                                  <TableCell
                                    style={{
                                      position: "sticky",
                                      left: 0,
                                      backgroundColor: "#FFFFFF",
                                      zIndex: 99999,
                                      borderBottom: "2px solid #e0e0e0",
                                      borderLeft: "2px solid #e0e0e0",
                                    }}
                                  >
                                    {this.props.modes ===
                                      "SingleTableEntries" ||
                                    this.props.mode === "clone" ? null : (
                                      <Link
                                        style={{
                                          color: "#0577A3",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          marginTop: "10px",
                                          display: "block",
                                        }}
                                        onClick={(event) =>
                                          this.props.handleApply(event)
                                        }
                                        to={""}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 16,
                                            color: "grey",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Apply
                                        </Typography>
                                      </Link>
                                    )}
                                  </TableCell>

                                  {headCellData.map((el, index) => {
                                    const inputTypeObj = coulmnDetails.find(
                                      (val: any) => val.fieldName === el.key
                                    );

                                    if (!["id", "checked"].includes(el.key)) {
                                      return (
                                        <React.Fragment
                                          key={`${index}-column-head`}
                                        >
                                          {el.key === "Action" ? (
                                            <TableCell key={`${index}-celll`}>
                                              {" "}
                                              <Link
                                                style={{
                                                  color: "#0577A3",
                                                  cursor: "pointer",

                                                  textDecoration: "none",
                                                  marginTop: "10px",
                                                  display: "block",
                                                }}
                                                onClick={(event) =>
                                                  this.handleResetClick(
                                                    event,
                                                    "bulkformreset"
                                                  )
                                                }
                                                to={""}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: 16,
                                                    color: "gray",
                                                    textDecoration: "underline",
                                                  }}
                                                >
                                                  Reset
                                                </Typography>
                                              </Link>
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              key={`${el.key}-input-${index}`}
                                            >
                                              <>
                                                <InputTypeSelector
                                                  validation={validation}
                                                  type={inputTypeObj?.type!}
                                                  isRequired={
                                                    this.state.selectedData
                                                      .length > 1
                                                      ? false
                                                      : inputTypeObj?.isRequired! ===
                                                        "NO"
                                                      ? true
                                                      : false
                                                  }
                                                  value={
                                                    bulkEditInputFormData[
                                                      inputTypeObj?.fieldName!
                                                    ]
                                                  }
                                                  onChange={(event) => {
                                                    this.onFormInputChange(
                                                      event,
                                                      index,
                                                      inputTypeObj?.fieldName
                                                    );
                                                  }}
                                                />
                                              </>
                                            </TableCell>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                    return null;
                                  })}
                                </StyledTableRow>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                      {data.length > 0 &&
                      data[0][this.state.uniqueValue] !== null ? (
                        data.map((record: any, index: number) => {
                          return (
                            <React.Fragment key={`frag-row-${index}`}>
                              <StyledTableRow key={`${index}-row`}>
                                {this.props.modes === "multipleTableEntries" ? (
                                  <TableCell>
                                    {index === 0 ? (
                                      <>
                                        {" "}
                                        <Typography
                                          style={{
                                            fontSize: 16,
                                            color: "gray",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Reset
                                        </Typography>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                ) : (
                                  <>
                                    {onClickCheckBox ? (
                                      <TableCell
                                        style={{
                                          fontSize: "16px",
                                          position: "sticky",
                                          left: "0",
                                          zIndex: "9999",
                                          backgroundColor: "white",
                                          borderBottom: "2px solid #e0e0e0",
                                          borderLeft: "2px solid #e0e0e0",
                                        }}
                                      >
                                        <Checkbox
                                          disabled={[
                                            "edit",
                                            "clone",
                                            "comment",
                                          ].includes(this.props.mode!)}
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "#3DA470",
                                            },
                                            "& .MuiSvgIcon-root": {
                                              borderRadius: "4px",
                                            },
                                          }}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          size="small"
                                          checked={record.checked || false}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            this.onClickCheckBox(
                                              event,
                                              "single",
                                              record
                                            )
                                          }
                                        />
                                      </TableCell>
                                    ) : null}
                                  </>
                                )}

                                {headCellData.map((el, cellIndex) => {
                                  const inputTypeObj = coulmnDetails.find(
                                    (val: any) => val.fieldName === el.key
                                  );
                                  if (el?.key !== "id") {
                                    const clrObj = fieldsToColor?.find(
                                      (clr) => clr.key === el?.key
                                    );

                                    return (
                                      <TableCell
                                        key={`${index}=${cellIndex}-cell`}
                                        style={{
                                          fontSize: "16px",
                                          wordWrap: "break-word",
                                          hyphens: "auto",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {el?.key !== "Action" &&
                                        record.checked &&
                                        this.props.mode === "edit" ? (
                                          <TableInputTypeSelector
                                            validation={validation}
                                            type={inputTypeObj?.type!}
                                            isRequired={
                                              inputTypeObj?.isRequired! === "NO"
                                                ? true
                                                : false
                                            }
                                            value={data[index][el.key]}
                                            onChange={(event) => {
                                              //console.log("çhanging inputtype");
                                              this.onBulkEditInputChange(
                                                event,
                                                index,
                                                inputTypeObj?.fieldName,
                                                data
                                              );
                                            }}
                                          />
                                        ) : (
                                          <>
                                            {el?.key !== "Action" &&
                                            this.state.showEditField ===
                                              record[uniqueValue] &&
                                            record.checked ? (
                                              <InputTypeSelector
                                                key={`${index}-${cellIndex}-id`}
                                                validation={validation}
                                                type={inputTypeObj?.type!}
                                                isRequired={
                                                  inputTypeObj?.isRequired! ===
                                                  "NO"
                                                    ? true
                                                    : false
                                                }
                                                value={data[index][el.key]}
                                                onChange={(event) =>
                                                  this.onEditInputChange(
                                                    event,
                                                    index,
                                                    inputTypeObj?.fieldName
                                                  )
                                                }
                                              />
                                            ) : (
                                              <>
                                                {!isNull(el?.linkTo) ? (
                                                  <Link
                                                    style={{
                                                      color: "#0075A2",
                                                    }}
                                                    onClick={() => {
                                                      this.onClickLink(
                                                        el.key,
                                                        record
                                                      );
                                                    }}
                                                    to={{
                                                      pathname: `/view/${selectedSdmTableValue.toLocaleLowerCase()}/${
                                                        record[
                                                          uniqueValue.toLowerCase()
                                                        ]
                                                      }`,
                                                    }}
                                                  >
                                                    {record[el?.key]}
                                                  </Link>
                                                ) : (
                                                  <>
                                                    <Box
                                                      style={{
                                                        color: clrObj
                                                          ? clrObj.values[
                                                              record[el?.key]
                                                            ]
                                                          : "black",
                                                      }}
                                                    >
                                                      {`${record[el?.key]}`
                                                        .length > 100
                                                        ? `${record[el?.key]}`
                                                            .slice(0, 80)
                                                            .concat(".....")
                                                        : record[el?.key]}
                                                    </Box>
                                                    {el.elementToLoad}{" "}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                        <>
                                          {this.props.modes ===
                                          "multipleTableEntries" ? null : (
                                            <>
                                              {el?.key === "Action" ? (
                                                <>
                                                  <Box
                                                    style={{
                                                      display: "flex",
                                                      gap: 2,
                                                      zIndex: 9999,
                                                    }}
                                                  >
                                                    {this.props.actionArray?.map(
                                                      (action, index) => (
                                                        <ActionMenuDropdown
                                                          key={`${index}-action`}
                                                          isButtonLoad={
                                                            this.props
                                                              .isButtonLoad
                                                          }
                                                          disabled={
                                                            [
                                                              "edit",
                                                              "clone",
                                                              "comment",
                                                            ].includes(
                                                              this.props.mode!
                                                            ) ||
                                                            !record.checked ||
                                                            this.state
                                                              .selectedData
                                                              .length > 1
                                                          }
                                                          cloneId={
                                                            record[uniqueValue]
                                                          }
                                                          delDialogId={
                                                            record[uniqueValue]
                                                          }
                                                          commentId={
                                                            record[uniqueValue]
                                                          }
                                                          editId={
                                                            record[uniqueValue]
                                                          }
                                                          onCommentClick={
                                                            this
                                                              .handleCommentClick
                                                          }
                                                          onEditClick={
                                                            this.handleEditClick
                                                          }
                                                          onCloneClick={
                                                            this
                                                              .handleCloneClick
                                                          }
                                                          handleDialogClick={
                                                            this
                                                              .handleDialogClick
                                                          }
                                                          handleDelete={
                                                            this.handleDelete
                                                          }
                                                        />
                                                      )
                                                    )}
                                                  </Box>
                                                  <></>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </>{" "}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })}
                              </StyledTableRow>

                              {this.props.mode === "comment" &&
                              this.state.data[index].checked ? (
                                <TableRow
                                  key={`${index}-comment-row`}
                                  style={{
                                    position: "sticky",
                                    left: 0,
                                    background: "white",
                                  }}
                                >
                                  <TableCell
                                    className="sticky-row"
                                    style={{
                                      position: "sticky",
                                      marginLeft: 20,
                                      borderBottom: "0.5px solid #e0e0e0",
                                      borderTop: "0.5px solid #e0e0e0",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    style={{
                                      position: "sticky",
                                      left: "120px",
                                      zIndex: 999999,
                                      background: "white",
                                      borderBottom: "0.5px solid #e0e0e0",
                                      borderTop: "0.5px solid #e0e0e0",
                                    }}
                                    colSpan={3}
                                    className="sticky-row"
                                  >
                                    <CommentSection
                                      key={`${index}-rationale`}
                                      mode={this.props.mode}
                                      handleRemoveFilter={
                                        this.handleRemoveFilterFromComment
                                      }
                                      handleSave={this.props.handleSave}
                                      rationale={this.props.rationale}
                                      onRationaleInputChange={
                                        this.props.onRationaleInputChange
                                      }
                                      isButtonLoad={this.props.isButtonLoad}
                                      columnDetails={coulmnDetails}
                                      onCommentClose={this.handleCommentClose}
                                    />
                                  </TableCell>
                                  <TableCell
                                    colSpan={headCellData.length - 3}
                                    className="sticky-row"
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "0.5px solid #e0e0e0",
                                      borderTop: "0.5px solid #e0e0e0",
                                    }}
                                  ></TableCell>
                                </TableRow>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <Box
                          style={{
                            position: "sticky",
                            left: "50%",
                            marginTop: "20vh",
                            marginBottom: "20vh",
                          }}
                          width={"82px"}
                        >
                          <NoRecords msg="No Records" />
                        </Box>
                      )}
                    </TableBody>
                  </MaterialTable>
                )}
              </TableContainer>

              <PaginationNew
                isEmptyReset={
                  this.props.isdataFiltered
                    ? this.state.isEmptyReset
                    : this.props.isEmptyReset
                }
                isPaginationReset={this.props.isPaginationReset}
                isDisabled={this.props.isPaginationDisabled}
                onChangePage={this.handleChangePage}
                onChangeRow={this.handleChangeRowsPerPage}
                totalRecordsCount={this.props.totalRecordsCount}
              />
            </form>
          </>
        ) : (
          <>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
            <Box style={{ margin: "20vh" }}>
              <NoRecords />
            </Box>
          </>
        )}
      </Box>
    );
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCommentClose = (event: any) => {
    this.setState({ commentForId: undefined });
    if (this.props.handleToolbarCancel) {
      this.props.handleToolbarCancel();
    }
  };
  handleCancel = (event: any) => {
    this.setState({
      showEditField: undefined,
      selectAll: false,
      data: this.state.data.map((d: any) => ({
        ...d,
        checked: false,
      })),
    });
    if (this.state.data[0][this.state.uniqueValue] !== null) {
      this.handleRemoveFilter();
    }

    if (this.props.handleToolbarCancel) {
      this.props.handleToolbarCancel();
    }
  };

  handleCancelforSelectAll = () => {
    this.setState({ showEditField: undefined });
  };
  handleCancelforEditRow = () => {
    this.setState({ showbulkEditFields: undefined });
  };
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
}

export default withRouter(Table);
