import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SelectModel from "../../../Components/Select/SelectModel";
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import LineKOChart from "../../../Components/Charts/LineKOChart";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import DashboardCard from "../../../Components/Card/DashboardCard";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import koChart from "../../../Static/koChart.png";
import koIcon from "../../../Static/koIcon.png";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import Utils from "../../../Common/Utils";
import MissingTransactionService from "../../../Services/MissingTransactionServices";
import DashboardService from "../../../Services/DashboardService";
import NoRecords from "../../../Components/NoRecords/NoRecords";
const MissingTransactionServices = new MissingTransactionService();
const DashboardServices = new DashboardService();
const splineGradientColor = [
  ["#54D8FF", "rgba(0, 0, 255, 0)"],
  ["#A3A0FB", "rgba(0, 0, 255, 0)"],
];

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  showKODateDialog: boolean;
  selectedValues: SelectModel[];
  sourceMappingData: any;
  alertType: AlertColor;
  openAlert: boolean;
  alertMsg: string;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  startDatePicker: string | null;
  endDatePicker: string | null;
  chartData: {
    color: string;
    data: number[];
    days: string[];
    marker: any;
    name: string;
    pointPlacement: string;
  }[];
  xAxisCategories: string[];
  resultArr: any;
  isSearchButtonLoading: boolean;
  isLoading: boolean;
  dashboardPercent: number | undefined;
  dashboardTotalCount: number | undefined;
}

class KOVolumeChart extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showKODateDialog: false,
      startDatePicker: null,
      endDatePicker: null,
      selectedStartDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedEndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59.999"),
      selectedValues: [],
      sourceMappingData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      chartData: [],
      xAxisCategories: [],
      resultArr: [],
      isLoading: false,
      isSearchButtonLoading: false,
      dashboardPercent: undefined,
      dashboardTotalCount: undefined,
    };
  }
  async componentDidMount() {
    const response = await MissingTransactionServices.getSourceMappingData();
    const dataArr = response.data.data.map((el) => {
      return {
        ...el,
        searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
      };
    });

    let sourceData = dataArr
      .map((el) => {
        return {
          text: el.sourceName,
          value: el.sourceName,
        };
      })
      .filter((val) => val.text !== "Other");
    this.setState(
      {
        sourceMappingData: sourceData.sort((a, b) =>
          a.text.localeCompare(b.text)
        ),
      },
      () => {
        this.getDefaultData();
      }
    );
  }
  getDefaultData = () => {
    this.setState(
      {
        selectedValues: [
          { text: "Concur", value: "Concur" },
          { text: "CPS", value: "CPS" },
          { text: "SAP", value: "SAP" },
        ],
        selectedStartDate: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
        startDatePicker: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        endDatePicker: moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.getChartData();
      }
    );
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 365",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedValues: selected });
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };
  isEmptyCheck = () => {
    const {
      selectedValues,
      startDatePicker,
      endDatePicker,
    } = this.state;
    if (startDatePicker && endDatePicker && selectedValues.length > 0) {
      return false;
    }
    return true;
  };
  onResetClick = () => {
    this.setState({
      selectedValues: [],
      startDatePicker: null,
      endDatePicker: null,
    });
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
        selectedStartDate: this.state.startDatePicker,
        selectedEndDate: this.state.endDatePicker,
      },
      () => {
        this.getChartData();
      }
    );
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  getChartData = async () => {
    try {
      const {
        selectedValues,
        selectedEndDate,
        selectedStartDate,
        showKODateDialog,
      } = this.state;

      let sourceVal = selectedValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      if (showKODateDialog) {
        const payloadKickOutSource: any = {
          par_group_source: 1,
          par_start_date: selectedStartDate,
          par_end_date: selectedEndDate,
          par_whereclause: `srcsyscd in (${sourceVal}) and CAST(avarundt as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}''`,
          par_whereclause1: null,
        };
        this.setState({ isLoading: true });
        let response = await DashboardServices.getkickoutSourceData(
          payloadKickOutSource
        );
        if (response.result.length > 0) {
          const min = new Date(selectedStartDate!); // start date
          const max = new Date(selectedEndDate!); // end date

          // Calculate the difference in days
          const diffDays = Math.abs(Utils.getDaysBetweenDates(max, min));
          const intervals =
            diffDays <= 12
              ? diffDays
              : diffDays <= 14
              ? 7
              : diffDays % 2 === 0
              ? 7
              : 6;
          const intervalLength = Math.ceil(diffDays / intervals);

          // Initialize an array to store the interval boundaries
          const intervalBoundaries = [];
          // Calculate the interval boundaries
          for (let i = 0; i <= diffDays; i += intervalLength) {
            const intervalStart = new Date(
              min.getTime() + i * 24 * 60 * 60 * 1000
            );
            let intervalEnd = new Date(
              intervalStart.getTime() +
                (intervalLength - 1) * 24 * 60 * 60 * 1000
            );
            intervalEnd = new Date(
              Math.min(intervalEnd.getTime(), max.getTime())
            ); // Ensure end date is not beyond max

            // Format the dates as "ddMMM"
            const formattedStart = intervalStart
              .toLocaleDateString("en-US", { day: "numeric", month: "short" })
              .replace(" ", "");
            const formattedEnd = intervalEnd
              .toLocaleDateString("en-US", { day: "numeric", month: "short" })
              .replace(" ", "");

            // If the date range is 12 days, format each day individually
            if (diffDays <= 12) {
              intervalBoundaries.push(formattedStart);
            } else {
              // Add the formatted date range to the array
              formattedStart !== formattedEnd
                ? intervalBoundaries.push(`${formattedStart}-${formattedEnd}`)
                : intervalBoundaries.push(`${formattedEnd}`);
            }
          }
          const dailyRowCountSums: any = {};
          if (diffDays <= 12) {
            for (const item of response.result) {
              // Convert the date string to a Date object
              const date = new Date(item.exception_date);
              for (const day of intervalBoundaries) {
                const year = date.getFullYear();
                const boundaryDate = new Date(`${day}-${year}`);
                if (
                  moment(date).format("YYYY-MM-DD") ===
                  moment(boundaryDate).format("YYYY-MM-DD")
                ) {
                  if (item.source !== "All Source") {
                    if (!dailyRowCountSums[item.source]) {
                      dailyRowCountSums[item.source] = {
                        data: [],
                        days: [],
                      };
                    }
                    if (!dailyRowCountSums[item.source].days.includes(day)) {
                      dailyRowCountSums[item.source].days.push(day);
                      dailyRowCountSums[item.source].data.push(
                        item.totalcount !== null || undefined
                          ? Number(item.totalcount)
                          : 0
                      );
                    } else {
                      const index =
                        dailyRowCountSums[item.source].days.indexOf(day);
                      dailyRowCountSums[item.source].data[index] +=
                        item.totalcount !== null || undefined
                          ? Number(item.totalcount)
                          : 0;
                    }
                  }
                }
              }
            }
          } else {
            for (const range of intervalBoundaries) {
              // Convert the date string to a Date object
              for (const item of response.result) {
                const date = new Date(item.exception_date);
                const [startStr, endStr] = range.split("-");
                const startYear = moment(this.state.selectedStartDate).year();
                const endYear = moment(this.state.selectedEndDate).year();
                //const year = date.getFullYear();
                const start = new Date(`${startStr}-${startYear}`);
                const end = endStr
                  ? new Date(`${endStr}-${endYear}`)
                  : new Date(`${startStr}-${startYear}`);
                end.setHours(23, 59, 59);
                if (date >= start && date <= end) {
                  if (item.source !== "All Source") {
                    if (!dailyRowCountSums[item.source]) {
                      dailyRowCountSums[item.source] = {
                        data: [],
                        days: [],
                      };
                    }
                    if (!dailyRowCountSums[item.source].days.includes(range)) {
                      dailyRowCountSums[item.source].days.push(range);
                      dailyRowCountSums[item.source].data.push(
                        item.totalcount !== null || undefined
                          ? Number(item.totalcount)
                          : 0
                      );
                    } else {
                      const index =
                        dailyRowCountSums[item.source].days.indexOf(range);
                      dailyRowCountSums[item.source].data[index] +=
                        item.totalcount !== null || undefined
                          ? Number(item.totalcount)
                          : 0;
                    }
                  }
                }
              }
            }
          }
          const transformedResponse = Object.keys(dailyRowCountSums).map(
            (key) => {
              return {
                source: key,
                data: dailyRowCountSums[key].data,
                days: dailyRowCountSums[key].days,
              };
            }
          );
          let groupedData: any;
          if (transformedResponse.length > 0) {
            groupedData = transformedResponse
              .map((item: any, index: any) => {
                return {
                  name: item.source,
                  color: (item.color = {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                      [
                        0.8,
                        splineGradientColor[
                          index % splineGradientColor.length
                        ][0],
                      ], // Start color

                      [
                        1,
                        splineGradientColor[
                          index % splineGradientColor.length
                        ][1],
                      ], // Mid color
                      [
                        1,
                        splineGradientColor[
                          index % splineGradientColor.length
                        ][1],
                      ], // End color
                    ],
                  }),

                  marker: {
                    symbol: "circle",
                  },
                  data: item.data,
                  days: item.days,
                  pointPlacement: "on",
                };
              })
              .sort();
          } else {
            groupedData = [];
          }

          this.setState({
            chartData: groupedData,
            xAxisCategories: groupedData.length > 0 ? groupedData[0].days : [],
            resultArr: response.result,
            isSearchButtonLoading: false,
            isLoading: false,
          });
        } else {
          this.setState({
            chartData: [],
            isSearchButtonLoading: false,
            isLoading: false,
          });
        }
      } else {
        const payloadObjDashboardCard: any = {
          par_group_source: 3,
          par_start_date: "2022-08-01",
          par_end_date: "2022-08-15",
          par_whereclause:
            "srcsyscd in (''SAP'') and CAST(avarundt as DATE) BETWEEN ''2022-08-01'' and ''2022-08-15''",
          par_whereclause1:
            "srcsyscd in (''SAP'') and CAST(avarundt as DATE) BETWEEN ''2022-07-01'' and ''2022-07-15''",
        };
        const reponseDashboardCard =
          await DashboardServices.getkickoutSourceData(payloadObjDashboardCard);
        this.setState({
          dashboardPercent:
            ((Number(reponseDashboardCard.result[0].totalcount) -
              Number(reponseDashboardCard.result[1].totalcount)) /
              Number(reponseDashboardCard.result[1].totalcount)) *
              100 ===
            Infinity
              ? Number(reponseDashboardCard.result[0].totalcount) * 100
              : ((Number(reponseDashboardCard.result[0].totalcount) -
                  Number(reponseDashboardCard.result[1].totalcount)) /
                  Number(reponseDashboardCard.result[1].totalcount)) *
                100,

          dashboardTotalCount: Number(
            reponseDashboardCard.result[0].totalcount
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      showKODateDialog,
      selectedValues,
      startDatePicker,
      endDatePicker,
      isSearchButtonLoading,
      alertMsg,
      alertType,
      openAlert,
      isLoading,
      chartData,
      xAxisCategories,
      resultArr,
      sourceMappingData,
      dashboardPercent,
      dashboardTotalCount,
    } = this.state;

    return (
      <>
        <Grid
          mb={2}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ showKODateDialog: true });
          }}
        >
          {!dashboardTotalCount ? (
            <Card
              sx={{
                borderRadius: 2,
                borderWidth: "4px",
                backgroundColor: "white",
              }}
            >
              <Grid
                item
                style={{ height: "125px", width: "420px" }}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress
                  disableShrink
                  sx={{ color: "#d52b1e", marginTop: 4 }}
                />
              </Grid>
            </Card>
          ) : (
            <DashboardCard
              graphImg={koChart}
              headingIcon={koIcon}
              headingText="KO Volume"
              expandImg={ExpandOpen}
              graphNumbers={dashboardTotalCount}
              pricePercentText={dashboardPercent || 0}
              icon={
                dashboardPercent === 0 ? (
                  <></>
                ) : dashboardPercent === 0 ? (
                  ""
                ) : dashboardPercent! > 0 ? (
                  <NorthIcon
                    style={{
                      top: "2px",
                      position: "relative",
                      fontSize: "15px",
                      color: "green",
                    }}
                  />
                ) : (
                  <SouthIcon
                    style={{
                      top: "2px",
                      position: "relative",
                      fontSize: "15px",
                      color: "red",
                    }}
                  />
                )
              }
            />
          )}
        </Grid>

        <ModalDialog
          isOpen={showKODateDialog}
          blackTitleColor
          title="KO Volume"
          onClose={() => this.setState({ showKODateDialog: false })}
          dialogWidth="xl"
          scrollBar={showKODateDialog}
        >
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0",
                }}
              >
                <Grid item xs={4}>
                  <h3>KO Volume Per Source</h3>
                </Grid>
                <Grid container columnGap={1} justifyContent={"flex-end"}>
                  <Grid item xs={5}>
                    <InputLabel>Select Sources</InputLabel>
                    <MultiAutoComplete
                      id="sourceName"
                      label="Select Sources"
                      selected={selectedValues}
                      values={sourceMappingData}
                      onChange={this.handleMultiSelectChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} ml={0.8}>
                    <InputLabel> Select Start Date</InputLabel>
                    <DatePicker
                      name="startDate"
                      placeHolder="Select Date"
                      maxDate={
                        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                      }
                      value={startDatePicker}
                      onChange={this.handleStartDateChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} className="EndDate" ml={0.8}>
                    <InputLabel> Select End Date</InputLabel>
                    <DatePicker
                      disabled={!startDatePicker}
                      name="endDate"
                      placeHolder="Select Date"
                      minDate={new Date(startDatePicker!)}
                      maxDate={this.calculateMaxDate(startDatePicker)}
                      value={endDatePicker}
                      onChange={this.handleEndDateChange}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={0.8}>
                    <GradientButton
                      disabled={this.isEmptyCheck()}
                      isButtonLoad={isSearchButtonLoading}
                      label="Search"
                      onClick={this.handleSearchClick}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={1}>
                    <RedButton label="Reset" onClick={this.onResetClick} />
                  </Grid>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid
                  item
                  style={{ height: "20em" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress
                    disableShrink
                    sx={{ color: "#d52b1e", marginTop: 16 }}
                  />
                </Grid>
              ) : (
                <>
                  {this.state.chartData.length > 0 ? (
                    <LineKOChart
                      chartDataArr={chartData}
                      xAxisCategories={xAxisCategories}
                      resultArr={resultArr}
                    />
                  ) : (
                    <Box
                      style={{
                        position: "sticky",
                        left: "50%",
                        marginTop: "20vh",
                        marginBottom: "20vh",
                      }}
                      width={"82px"}
                    >
                      <NoRecords msg="No Data" />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </ModalDialog>
      </>
    );
  }
}
export default withRouter(KOVolumeChart);
