import { Checkbox } from "@mui/material";
import { Component } from "react";
import DatePicker from "../DatePicker/DatePicker";
import RadioButtons from "../RadioButtons/RadioButtons";
import SingleSelect from "../Select/SingleSelect";
import "./style.css";
type Props = {
  type: "dropdown" | "text" | "radio" | "checkbox" | "number" | "date";
  label?: string;
  width?: string;
  values?: any;
  value?: any;
  name?: string;
  isDisabled?: boolean;
  radioDirection?: "row" | "column";
  onChange: (event: any, targetId?: any, name?: string ) => void;
  size?: "small" | "medium";
  defaultValue?: string;
  isRequired?: boolean;
  noneValue?: boolean;
  maxDate?: any;
  customOptions?: string;
  validation:boolean;
  /* validationType: ValidationType; */
};

type State = {};

export default class InputTypeSelector extends Component<Props, State> {
  /* public static defaultProps = {
    validationType: ValidationType.NONE,
  } */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      type,
      label,
      noneValue,
      isRequired,
      defaultValue,
      values,
      value,
      size,
      onChange,
      radioDirection,
      isDisabled,
      width,
      name,
      maxDate,
      customOptions,
      validation
    } = this.props;

    switch (type) {
      case "number":
        return (
          <div className="textfield-container">
            <input
              className="textfield-input"
              disabled={isDisabled}
              type="number"
              required={isRequired}
              name={name}
              width={width}
              onChange={onChange}
              value={value || ''}
              style={{
                background: "#263F6A",
                color: "#fff",
              }}
            />
             <span style={{
              color:"#FF4A4A",
              fontStyle: "italic",
              fontFamily: "Roboto",
              fontSize:"14px",
              display:"block",
              height:"14px",
              visibility: validation ? "visible" :"hidden"
              }}>{validation ? isRequired ? value ? "" : "*field required" : "" : ""}</span>
          </div>
        );

      case "text":
        return (
          <div className="textfield-container">
            <input
              className="textfield-input"
              type="text"
              
              disabled={isDisabled}
              required={isRequired}
              name={name}
              width={width}
              onChange={onChange}
              value={value || ""}
              style={{
                background: "#263F6A",
                color: "#fff",
              }}
            />
             <span style={{
              color:"#FF4A4A",
              fontStyle: "italic",
              fontFamily: "Roboto",
              fontSize:"14px",
              display:"block",
              height:"14px",
              visibility: validation ? "visible" :"hidden"
              }}>{validation ? isRequired ? value ? "" : "*field required" : "" : ""}</span>
          </div>
        );

      case "date":
        return (
          <>
          <DatePicker
            isRequired={isRequired}
            name={name}
            maxDate={maxDate}
            width={width}
            onChange={onChange}
            value={value || null}
            disabled={isDisabled}
          />
          <span style={{
            color: "#FF4A4A",
            fontStyle: "italic",
            fontFamily: "Roboto",
            fontSize: "14px",
            display:"block",
            height:"14px",
            visibility: validation ? "visible" :"hidden"
          }}>{validation ? isRequired ? value ? "" : "*field required" : "" : ""}</span></>
        );

      case "dropdown":
        return (
          <SingleSelect
            isRequired={isRequired}
            customOptions={customOptions}
            noneValue={noneValue}
            defaultValue={defaultValue}
            id={name}
            name={name}
            onChange={onChange}
            label={label}
            values={values}
            value={value}
          />
        );

      case "checkbox":
        return (
          <Checkbox
            readOnly={isDisabled}
            disabled={isDisabled}
            name={name}
            size={size || "small"}
            inputProps={{ "aria-label": "controlled" }}
            checked={value}
            onChange={onChange}
          />
        );

      case "radio":
        return (
          <RadioButtons
            name={name}
            value={value}
            buttonValues={values}
            direction={radioDirection}
            onChange={onChange}
          />
        );

      default:
        return null;
    }
  }
}
