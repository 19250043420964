import {
  Box,
  Button,
  Grid,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import Utils from "../../Common/Utils";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import { IToolbarMode } from "../../Models/IToolbarMode";
import { GetColumnDetailsResponseModel } from "../../Models/SDMTableRequestModel";
import GradientButton from "../Button/GradientButton";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import TableInputTypeSelector from "../InputTypeSelector/TableInputTypeSelector";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    zIndex: 999999,
    top: "10px",
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF",
    },
  },
}));

type tableModeForEntries =
  | "multipleTableEntries"
  | "SingleTableEntries"
  | "view"
  | undefined;

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCellDataForEntries: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  handleInputChange: (event: any, index: any, targetId?: string) => void;
  handleBulkInputChange: (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => void;
  handleBulkInsertInputChange: (
    event: any,
    index: any,
    targetId?: string
  ) => void;
  commentForId?: number;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  checkBoxDifferentiator?: string | number;
  mode: IToolbarMode;
  handleToolbarCancel?: (modes?: string) => void;
  modes: tableModeForEntries;
  coulmnDetails: GetColumnDetailsResponseModel[];
  bulkEditInputFormData: any;
  handleReset: (event: any, type: any) => void;
  handleApply: (event: any) => void;
  handleAddMoreField: () => void;
  handleRemoveClick: (index: number) => void;
  bulkInsertState: any;
  handleSave: (mode: string) => void;
  isButtonLoad: boolean;
  validation:boolean
}

interface State {
  data: any;
  resetRow: any;
  uniqueValue: any;
  headData: any[];
  insertState: any[];
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    zIndex: "99999",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    zIndex: "99999",
  },
  "td:nth-of-type(2), th:nth-of-type(2)": {
    position: "sticky",
    left: "70px",
    zIndex: "99999",
    background: "white",
  },
}));

class BulkInsertTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      resetRow: [],
      uniqueValue: null,
      headData: [],
      insertState: [],
    };
  }

  componentDidMount() {
    const headData = this.props.coulmnDetails.map((el) =>
      this.props.headCellData.find((val: any) => val.key === el.fieldName)
    );
    headData.unshift({
      key: "Action",
      label: "Action",
      linkTo: null,
    });
    this.setState({ headData });
    if (this.props.bulkInsertState) {
      this.setState({
        data: this.props.bulkInsertState,
        insertState: this.props.bulkInsertState,
      });
    }
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!isEqual(prevProps.bulkInsertState, this.props.bulkInsertState)) {
      this.setState({ data: this.props.bulkInsertState });
    }
    if (!isEqual(prevProps.headCellData, this.props.headCellData)) {
      const headData = this.props.coulmnDetails.map((el) =>
        this.props.headCellData.find((val: any) => val.key === el.fieldName)
      );
      headData.unshift({
        key: "Action",
        label: "Action",
        linkTo: null,
      });
      this.setState({ headData });
    }
  }
  onEditInputChange = (event: any, index: any, targetId?: string) => {
    this.props.handleInputChange(event, index, targetId);
  };
  onBulkEditInputChange = (event: any, index: any, targetId?: string) => {
    this.props.handleInputChange(event, index, targetId);
  };
  handleResetClick = (event: any, type: any) => {
    if (type === "bulkformreset") {
      this.props.handleReset(event, type);
    }
  };
  onFormInputChange = (
    event: any,
    index: any,
    targetId?: string,
    data?: any
  ) => {
    this.props.handleBulkInputChange(event, index, targetId, data);
  };
  onBulkInsertInputChange = (event: any, index: any, targetId?: string) => {
    this.props.handleBulkInsertInputChange(event, index, targetId);
  };
  handleCancel = (event: any) => {
    if (this.props.handleToolbarCancel) {
      this.props.handleToolbarCancel("multipleTableEntries");
    }
  };
  handleAddMoreClick = () => {
    this.props.handleAddMoreField();
  };

  handleRemoveClick = (index: number) => {
    this.props.handleRemoveClick(index);
  };
  handleSave = (event: any) => {
    event.preventDefault();
    if (this.props.modes === "multipleTableEntries") {
      this.props.handleSave("multipleTableEntries");
    }
  };

  render() {
    const { coulmnDetails, bulkEditInputFormData, isButtonLoad,validation } = this.props;
    const { headData: headCellData, data } = this.state;

    return (
      <Box>
        {headCellData.length > 0 ? (
          <>
            {/*  <button onClick={() => this.handleDuplicateRow()}>click</button> */}
            <form onSubmit={this.handleSave} noValidate>
              <Grid container>
                {this.props.modes === "multipleTableEntries" ? (
                  <>
                    <Grid item ml={3} mr={2}>
                      <RedOutlinedButton
                        label="Cancel"
                        onClick={this.handleCancel}
                      />
                    </Grid>
                    <Grid item>
                      <GradientButton
                        isButtonLoad={isButtonLoad}
                        label="Save"
                        type="submit"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <TableContainer component={Paper}>
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                          position: "sticky",
                          zIndex: 9999,
                          background: "white",
                          left: "0",
                        }}
                      ></TableCell>

                      {headCellData.map((el, index) => {
                        if (el && !["id", "checked"].includes(el.key)) {
                          return (
                            <TableCell
                              key={`${el.key}-${index}-head`}
                              style={
                                el?.key !== "Action"
                                  ? {
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      borderBottom: "2px solid red",
                                      whiteSpace: "nowrap",
                                      minWidth: "10em",
                                    }
                                  : {
                                      position: "sticky",
                                      fontWeight: "600",
                                      background: "white",
                                      left: "70px",
                                      zIndex: 9999,
                                      fontSize: "16px",
                                      borderBottom: "2px solid red",
                                      whiteSpace: "nowrap",
                                    }
                              }
                            >
                              {Utils.camelToTitle(el.label)}{" "}
                            </TableCell>
                          );
                        }

                        return null;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      <StyledTableRow>
                        <TableCell
                          style={{
                            position: "sticky",
                            marginLeft: 20,
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: "70px",
                            background: "white",
                            fontSize: 14,
                            color: "#FF4A4A",
                            textAlign: "left",
                            fontStyle: "italic",
                            fontFamily: "Roboto",
                            textAlignLast: "right",
                          }}
                          colSpan={3}
                        >
                          Enter the value to apply across all records displayed
                        </TableCell>
                        <TableCell
                          colSpan={headCellData.length - 3}
                          style={{
                            backgroundColor: "white",
                          }}
                        ></TableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: "#FFFFFF",
                            zIndex: 99999,
                          }}
                        >
                          <Link
                            style={{
                              color: "#0577A3",
                              cursor: "pointer",
                              textDecoration: "none",
                              marginTop: "10px",
                              display: "block",
                            }}
                            onClick={(event) => this.props.handleApply(event)}
                            to=""
                          >
                            <Typography
                              style={{
                                fontSize: 16,
                                color: "gray",
                                textDecoration: "underline",
                              }}
                            >
                              Apply
                            </Typography>
                          </Link>
                        </TableCell>

                        {headCellData.map((el, index) => {
                          const inputTypeObj = coulmnDetails.find(
                            (val: any) => val.fieldName === el.key
                          );
                          if (!["id", "checked"].includes(el.key)) {
                            return (
                              <TableCell key={`${el.key}-${index}`}>
                                {el.key === "Action" ? (
                                  <Link
                                    style={{
                                      color: "#0577A3",
                                      cursor: "pointer",

                                      textDecoration: "none",
                                      marginTop: "10px",
                                      display: "block",
                                    }}
                                    onClick={(event) =>
                                      this.handleResetClick(
                                        event,
                                        "bulkformreset"
                                      )
                                    }
                                    to={""}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        color: "gray",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Reset
                                    </Typography>
                                  </Link>
                                ) : (
                                  <TableInputTypeSelector
                                    type={inputTypeObj?.type!}
                                    value={
                                      bulkEditInputFormData[
                                        inputTypeObj?.fieldName!
                                      ]
                                    }
                                    onChange={(event) =>
                                      this.onFormInputChange(
                                        event,
                                        index,
                                        inputTypeObj?.fieldName
                                      )
                                    }
                                    validation={validation}
                                  />
                                )}
                              </TableCell>
                            );
                          }
                          return null;
                        })}
                      </StyledTableRow>
                    </>

                    {data.map((record: any, index: number) => {
                      return (
                        <StyledTableRow key={`${index}-row`}>
                          <TableCell
                            style={{
                              fontSize: "14px",
                              position: "sticky",
                              left: 0,
                              zIndex: "999999",
                              background: "white",
                            }}
                          >
                            {data.length > 1 ? (
                              <LightTooltip title="Remove" arrow>
                                <Button
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    zIndex: "999999",
                                    textTransform: "none",
                                  }}
                                  key={index}
                                  startIcon={
                                    <FontAwesomeIcon
                                      style={{ color: "#d52b1e" }}
                                      icon={faMinus}
                                    />
                                  }
                                  onClick={() => this.handleRemoveClick(index)}
                                ></Button>
                              </LightTooltip>
                            ) : null}
                          </TableCell>

                          {headCellData.map((el, cellIndex) => {
                            const inputTypeObj: any = coulmnDetails.find(
                              (val: any) => val.fieldName === el.key
                            );

                            if (el?.key !== "id") {
                              return (
                                <TableCell
                                  key={`${el.key}-${index}-${cellIndex}`}
                                  style={{
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    hyphens: "auto",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <TableInputTypeSelector
                                    type={inputTypeObj?.type!}
                                    isRequired={
                                      inputTypeObj?.isRequired! === "NO"
                                        ? true
                                        : false
                                    }
                                    value={record[inputTypeObj?.fieldName!]}
                                    onChange={(event) =>
                                      this.onBulkInsertInputChange(
                                        event,
                                        index,
                                        inputTypeObj?.fieldName
                                      )
                                    }
                                    validation={validation}
                                  />

                                  {el?.key === "Action" ? (
                                    <>
                                      <Grid
                                        item
                                        style={{
                                          position: "sticky",
                                          left: "70px",
                                          zIndex: "999999",
                                          background: "white",

                                          paddingLeft: 4,
                                        }}
                                      >
                                        {data.length - 1 === index ? (
                                          <LightTooltip title="Add" arrow>
                                            <Button
                                              disabled={
                                                data.length < 10 ? false : true
                                              }
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                zIndex: "999999",
                                                textTransform: "none",
                                                textAlignLast: "right",
                                              }}
                                              startIcon={
                                                <FontAwesomeIcon
                                                  style={{
                                                    color:
                                                      data.length < 10
                                                        ? "#d52b1e"
                                                        : "#9E9E9E",
                                                  }}
                                                  icon={faPlus}
                                                />
                                              }
                                              onClick={this.handleAddMoreClick}
                                            ></Button>
                                          </LightTooltip>
                                        ) : null}
                                      </Grid>
                                    </>
                                  ) : null}
                                </TableCell>
                              );
                            }
                            return null;
                          })}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            </form>
          </>
        ) : null}
      </Box>
    );
  }
}

export default withRouter(BulkInsertTable);
