import { AlertColor, Box, Grid, InputLabel } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../App.css";
import Utils from "../../Common/Utils";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SelectModel from "../../Components/Select/SelectModel";
import PageHeader from "../../Components/Text/PageHeader";
import AuthProviderService from "../../Services/AuthProviderService";
import ExceptionReportingChart from "./ExceptionReportingChart/ExceptionReportingChart";
import LastLoadDataGraph from "./LastLoadData/LastLoadDataGraph";
import ReportingDeadlines from "./ReportingDeadlines";
import MultiAutoComplete from "../../Components/Select/MultiAutoComplete";
import AODSChart from "./AODSChart";
import DataLoadingTrendingChart from "./DataLoadingTrendChart/DataLoadingTrendingChart";
import KOVolumeChart from "./KOVolumeChart/KOVolumeChart";
import NoOfExceptionsChart from "./NoOfExceptionsChart/NoOfExceptionsChart";
import KickoutMonitoringSources from "./KickoutMonitoringSources";
import GroupButton from "../../Components/Button/GroupButton";
import ExpandOpen from "../../Static/ExpandOpen.png";
import SwitchButton from "../../Components/Button/Switchbutton";
import MissingTransactionServices from "../../Services/MissingTransactionServices";
import CustomizedViewService from "../../Services/CustomizedViewService";

const missingTransactionServices = new MissingTransactionServices();
const custViewServices = new CustomizedViewService();

const buttons = [
  { id: 1, label: "Days" },
  { id: 2, label: "Weeks" },
  { id: 3, label: "Months" },
];

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  showmapDialog: boolean;
  showLastLoadDateDialog: boolean;
  showNoOfExceptionDateDialog: boolean;
  showKODateDialog: boolean;
  userName: string;
  selectedDate?: string | null;
  selectedSourceValues: SelectModel[];
  usersData: SelectModel[];
  switchOn: boolean;
  selectedStartDate: string | null;
  selectedEndDate: string | null;
  startDatePicker: string | null;
  endDatePicker: string | null;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  sourceDropdownValues: SelectModel[];
  countryDropdownValues: SelectModel[];
  selectedCountries: SelectModel[];
}

class Dashboard extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showmapDialog: false,
      showLastLoadDateDialog: false,
      showNoOfExceptionDateDialog: false,
      showKODateDialog: false,
      userName: "",
      selectedDate: null,
      selectedSourceValues: [],
      selectedCountries: [],
      usersData: [],
      switchOn: false,
      selectedStartDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
      startDatePicker: null,
      endDatePicker: null,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      countryDropdownValues: [],
      sourceDropdownValues: [],
    };
  }
  async componentDidMount() {
    const [sourceResponse, countryRespose] = await Promise.all([
      missingTransactionServices.getSourceMappingData(),
      custViewServices.getAllCountryAndSourceData()])
   
    const sourceData = sourceResponse.data.data
      .map((el) => {
        return {
          id: el.id,
          text: el.sourceName.toUpperCase(),
          value: el.sourceName.toUpperCase(),
        };
      })
      .filter((val) => val.text !== "Other");
      
      const filteredByCNTRY = countryRespose?.data.result.filter(
        (item: any) => item.type === "CNTRY" && item.col?.toLowerCase() !== 'other'
      );
      const countryData = filteredByCNTRY
        .map((el: any) => {
          return {
            text: el.col.toUpperCase(),
            value: el.col.toUpperCase(),
          };
        })
       
      
    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
    const userName = JSON.parse(decodedToken).name;
    const isSwitchPath = window.location.pathname === "/lockedview";
    this.setState({ userName, switchOn: isSwitchPath ? true : false, sourceDropdownValues: sourceData, countryDropdownValues: countryData },
      ()=>{
        this.getDefaultData()
      }
    );
  }
  handleDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedDate: moment(value).format("YYYY-MM-DD"),
      });
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId === 'sourceName') {
      this.setState({ selectedSourceValues: selected });
    } else if(targetId === 'countryName') {
      this.setState({ selectedCountries: selected });

    }
  };
  handleChange = () => {
    this.setState(
      {
        switchOn: false,
      },
      () => {
        const urlPath = window.location.origin + "/lockedview";

        const newTab = window.open(urlPath, "_blank", "noopener,noreferrer");
        if (newTab) newTab.opener = null;
      }
    );
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 180 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 180",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 179);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  getDefaultData = () => {
    this.setState({
      selectedSourceValues: [
        { text: "CONCUR", value: "CONCUR" },
        { text: "CPS", value: "CPS" },
        { text: "SAP", value: "SAP" },
      ],

      selectedStartDate: moment(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD"),
      selectedEndDate: moment(new Date()).format("YYYY-MM-DD"),
      startDatePicker: moment(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD"),
      endDatePicker: moment(new Date()).format("YYYY-MM-DD"),
    });
  };
  render() {
    const {
      showmapDialog,
      switchOn,
      selectedSourceValues,
      selectedEndDate,
      selectedStartDate,
      startDatePicker,
      endDatePicker,
      sourceDropdownValues,
      countryDropdownValues,
      selectedCountries,
    } = this.state;
    return (
      <Box style={{ minHeight: "100vh" }}>
        <div className="MapContent">
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            mt={2}
            mb={2}
          >
            <Grid item xs={5}>
              <PageHeader
                label={`Welcome ${this.state.userName.split(" ")[0]}!`}
                style={{
                  fontSize: "22px",
                }}
              />
              <div
                className="DashboardSwitch"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <SwitchButton
                  isDisabled={window.location.pathname === "/lockedview"}
                  label="Switch"
                  checked={switchOn}
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
            <Grid container columnGap={1} justifyContent={"flex-end"}>
              <Grid item xs={3}>
                <InputLabel>Select Sources</InputLabel>
                <MultiAutoComplete
                  id="sourceName"
                  label="Select Sources"
                  selected={selectedSourceValues}
                  values={sourceDropdownValues}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel>Select Geography</InputLabel>
                <MultiAutoComplete
                  id="countryName"
                  label="Select Geography"
                  selected={selectedCountries}
                  values={countryDropdownValues}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={2.5} ml={1}>
                <InputLabel> Select Start Date</InputLabel>
                <DatePicker
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={
                    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                  }
                  value={startDatePicker}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={2.5} className="EndDate">
                <InputLabel> Select End Date</InputLabel>
                <DatePicker
                  name="endDate"
                  placeHolder="Select Date"
                  minDate={new Date(startDatePicker!)}
                  maxDate={this.calculateMaxDate(startDatePicker)}
                  value={endDatePicker}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid item xs={3} mt={3}>
                <GroupButton label={""} buttons={buttons} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <Grid
              item
              xs={4}
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 1px 4px #15223214",
                borderRadius: "10px",
                padding: "25px",
                zIndex: "9",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PageHeader
                  label="Reporting Deadlines"
                  style={{
                    fontSize: "16px",
                    lineHeight: "1",
                  }}
                />
                <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
                  <img
                    alt="ExpandOpen"
                    src={ExpandOpen}
                    width={"14px"}
                    height={"14px"}
                    style={{
                      cursor: "pointer",
                      zIndex: "9999",
                    }}
                    onClick={() => {
                      this.setState({ showmapDialog: true });
                    }}
                  />
                </Box>
              </Box>
              <ReportingDeadlines />
            </Grid>
            <Grid item xs={3.8} ml={0.5} mr={0.5}>
              <Grid
                container
                flexDirection={"column"}
                display="flex"
                alignItems="center"
                style={{ position: "relative" }}
              >
                <LastLoadDataGraph />
                <KOVolumeChart />
                <NoOfExceptionsChart />
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              ml={1}
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 1px 4px #15223214",
                borderRadius: "10px",
                padding: "25px",
                zIndex: "9",
              }}
              className="ExceptionReportingContent"
            >
              <ExceptionReportingChart />
            </Grid>
          </Grid>
          <Grid container mt={3} mb={3} columnGap={2}>
            <Grid
              item
              xs={5.9}
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 1px 4px #15223214",
                borderRadius: "10px",
                padding: "25px",
                zIndex: "9",
              }}
            >
              <KickoutMonitoringSources defaultSources={selectedSourceValues} defaultCountries={selectedCountries} defaultStartDate={startDatePicker} defaultEndDate={endDatePicker} sourceDropDownValues={sourceDropdownValues} countryDropDownValues={countryDropdownValues}/>
            </Grid>
            <Grid
              item
              xs={5.9}
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 1px 4px #15223214",
                borderRadius: "10px",
                padding: "25px",
                zIndex: "9",
              }}
            >
              <DataLoadingTrendingChart />
            </Grid>
          </Grid>

          <Box
            mt={3}
            mb={3}
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 1px 4px #15223214",
              borderRadius: "10px",
              padding: "25px",
              zIndex: "9",
            }}
          >
            <AODSChart />
          </Box>
          <ModalDialog
            isOpen={showmapDialog}
            title="Reporting Deadlines"
            onClose={() => this.setState({ showmapDialog: false })}
            dialogWidth="lg"
            scrollBar={showmapDialog}
          >
            <ReportingDeadlines />
          </ModalDialog>
        </div>
      </Box>
    );
  }
}

export default withRouter(Dashboard);
